/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { ListItemIcon, Fade } from '@material-ui/core';
import { useTheme, makeStyles, createStyles } from '@material-ui/core/styles';
import { Copyright } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import Tooltip from 'components/Tooltip/Tooltip';

const useStyles = makeStyles((theme: any) => createStyles({
  versionDisplay: {
    fontSize: '.6rem',
    padding: 5,
    display: 'flex',
    alignItems: 'center',
  },
  text: {
    color: theme.palette.text.primary,
    textDecoration: 'none',
    padding: '0.2rem',
    width: '100%',
    minWidth: 225,
    whiteSpace: 'normal',
  },
}));

export default function Copyrights({ open }) {
  const classes = useStyles();
  const isDarkTheme = useTheme().palette.type === 'dark';
  const { t } = useTranslation();
  const data = t('Copyright © {{year}} Michelin North America Inc. All rights reserved.', { year: String(new Date().getFullYear()) });
  return (
    <div className={classes.versionDisplay}>
      {open && (
      <Fade in={open}>
        <div className={classes.text}>
          {data}
        </div>
      </Fade>
      )}
      <Tooltip title={data}>
        <Fade in={!open}>
          <div>
            <ListItemIcon>
              <Copyright style={{ marginLeft: 10, marginTop: 5, opacity: isDarkTheme ? 0.5 : 0.8 }} />
            </ListItemIcon>
          </div>
        </Fade>
      </Tooltip>
    </div>
  );
}
