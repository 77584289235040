/* eslint-disable no-param-reassign */
// eslint-disable-next-line max-classes-per-file
import React, { useMemo, useState } from 'react';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import {
  ArrowBackIos, ArrowForwardIos, CalendarTodayRounded, Clear as ClearIcon,
} from '@material-ui/icons';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import IconButton from 'components/IconButton/IconButton';
import { useTranslation } from 'react-i18next';
import { usePhoneView } from 'hooks/usePhoneView';
import { addDays, format, parse } from 'date-fns';
import { makeStyles } from '@material-ui/styles';
import type { SetFilteredRowsFunction } from './SelectFilter';
import { ListingFilter } from './ListingFilter';
import { FilterGroup } from './ListingFiltersGroup';
import useURLHashParams, { type URLHashParamsManager } from './hashParameters';
import 'moment/locale/fr-ca';
import 'moment/locale/en-ca';

const useDateFilterStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'baseline',
  },
  iconButton: {
    backgroundColor: 'transparent',
  },
}));

export class DateFilter extends ListingFilter {
  disableFutureDates: boolean;
  lastInformedValue: string | undefined;
  clearable: boolean;
  inputEnabled: boolean;
  dateFormat: string;
  navigationEnabled: boolean;
  disabled: boolean;

  constructor({
    label,
    onFilter,
    disableFutureDates,
    defaultValue,
    onExecute,
    hashParam,
    getHashValue = () => '',
    clearable = true,
    inputEnabled = true,
    dateFormat = 'YYYY/MM/DD',
    navigationEnabled = false,
    disabled = false,
  }:{
    label:string,
    onFilter?:(elem:any, value:string)=>boolean,
    disableFutureDates:boolean,
    defaultValue?: string,
    onExecute?: (value: string|undefined) => boolean,
    hashParam?: string,
    getHashValue?: (key:string) => string|undefined,
    clearable?: boolean,
    inputEnabled?: boolean,
    dateFormat?: string,
    navigationEnabled?: boolean,
    disabled?: boolean,
  }) {
    super({
      label,
      options: [],
      onFilter,
      onExecute,
      hashParam,
      getHashValue,
      defaultValue,
    });
    this.disableFutureDates = disableFutureDates;
    if (this.hashParam && getHashValue(this.hashParam)) {
      this.defaultValue = getHashValue(this.hashParam);
    }
    this.clearable = clearable;
    this.inputEnabled = inputEnabled;
    this.dateFormat = dateFormat;
    this.navigationEnabled = navigationEnabled;
    this.disabled = disabled;

    this.setDisabled = this.setDisabled.bind(this);
  }

  setDisabled(d: boolean) {
    this.disabled = d;
  }

  execute(value:string, rows:any[], callback:(filteredRows:any[])=>void):void {
    if (!value) {
      callback(rows);
      return;
    }
    const date:string = value.split('/').join('-');
    callback(rows.filter((e) => this.onFilter(e, date)));
  }

  loadHashValue(filterGroup: FilterGroup, hashParamsManager: URLHashParamsManager, rows?: any[], setFilteredRows?: SetFilteredRowsFunction) {
    if (!this.hashParam) return;
    const newValue = hashParamsManager.getHashValue(this.hashParam) || this.defaultValue || '';
    if (this.lastInformedValue === newValue) return;
    this.lastInformedValue = newValue;
    if (!this.onExecute(newValue)) return;
    filterGroup.execute(this, newValue || '', rows, setFilteredRows);
  }

  render(filterGroup: FilterGroup, hashParamsManager: URLHashParamsManager, rows?: any[], setFilteredRows?: SetFilteredRowsFunction) {
    const value = this.defaultValue || '';
    const { t, i18n } = useTranslation();
    const phoneView = usePhoneView();
    const classes = useDateFilterStyles();
    const { setHashValue } = hashParamsManager;
    moment.locale(i18n?.language?.replace('_', '-').toLowerCase());

    const executeDateFilter = (newValue: string | null | undefined) => {
      if (this.hashParam) setHashValue(this.hashParam, newValue || undefined);
      this.defaultValue = newValue || undefined;
      if (!this.onExecute(this.defaultValue)) return;
      filterGroup.execute(
        this,
        newValue || '',
        rows,
        setFilteredRows,
      );
    };

    const modifyDate = (days: number) => {
      const date = new Date(`${this.defaultValue}T00:00:00`);
      const adjustedDate = addDays(date, days);
      const newValue = format(adjustedDate, 'yyyy-MM-dd');

      executeDateFilter(newValue);
    };

    const currentDateIsToday = useMemo(() => {
      const valueDate = new Date(`${value}T00:00:00`);
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return (
        valueDate.getFullYear() === today.getFullYear()
        && valueDate.getMonth() === today.getMonth()
        && valueDate.getDate() === today.getDate()
      );
    }, [value]);

    const onArrowBackClick = () => {
      modifyDate(-1);
    };

    const onArrowForwardClick = () => {
      modifyDate(1);
    };

    const showArrow = this.navigationEnabled && !phoneView;

    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <div className={classes.container}>
          {showArrow && (
          <IconButton size="small" onClick={onArrowBackClick} className={classes.iconButton} disabled={this.disabled}>
            <ArrowBackIos color="primary" fontSize="inherit" />
          </IconButton>
          )}
          <KeyboardDatePicker
            margin="normal"
            label={this.label}
            okLabel={t('Ok')}
            cancelLabel={t('Cancel')}
            format={this.dateFormat}
            value={this.defaultValue || null}
            onChange={(date:any, newValue) => {
              // eslint-disable-next-line no-underscore-dangle
              const isValid = Boolean(date && date._isValid);
              if (!isValid) return;

              executeDateFilter(newValue);
            }}
            keyboardIcon={<CalendarTodayRounded />}
            style={{
              width: phoneView ? '100%' : 150,
              marginTop: 0,
              marginBottom: 0,
            }}
            disableFuture={this.disableFutureDates}
            InputProps={{
              endAdornment: value && this.clearable
                ? (
                  <IconButton
                    size="small"
                    onClick={() => {
                      if (this.hashParam) setHashValue(this.hashParam, undefined);
                      this.defaultValue = undefined;
                      filterGroup.execute(this, '', rows, setFilteredRows);
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                ) : undefined,
              readOnly: !this.inputEnabled,
              disableUnderline: !this.inputEnabled,
            }}
            InputAdornmentProps={value ? {
              position: 'start',
            } : {}}
            disabled={this.disabled}
          />
          {showArrow
          && (
          <IconButton
            size="small"
            onClick={onArrowForwardClick}
            className={classes.iconButton}
            disabled={this.disabled || (this.disableFutureDates ? currentDateIsToday : false)}
          >
            <ArrowForwardIos color="primary" fontSize="inherit" />
          </IconButton>
          )}
        </div>

      </MuiPickersUtilsProvider>
    );
  }
}

interface UseListDateFilterProps {
  label: string,
  defaultValue?: string,
  hashParam?: string,
  disableFutureDates: boolean,
  clearable?: boolean,
  inputEnabled?: boolean,
  dateFormat?: string,
  navigationEnabled?: boolean,
  disabled?: boolean,
}

/**
Included here are examples on how to use the **useListDateFilter** hook.

## Usage

This hook is intended to be used with DevEx DataGrid Tables, and useMaestroDataSource from Central Provider.

See full documentation here:
https://servicesolutions.atlassian.net/wiki/spaces/GSDSIS/pages/2365849655/Maestro+Data+Source
 */
export function useListDateFilter({
  label,
  hashParam,
  defaultValue,
  disableFutureDates,
  clearable,
  inputEnabled,
  dateFormat,
  navigationEnabled,
  disabled,
}: UseListDateFilterProps) {
  const { t } = useTranslation();
  const { getHashValue } = useURLHashParams();
  const [value, setValue] = useState<string|undefined>();

  const filter = useMemo(() => {
    const onExecute = (newValue:string|undefined) => {
      setValue(newValue);
      return true;
    };
    const f = new DateFilter({
      label: t(label),
      onExecute,
      hashParam,
      defaultValue,
      getHashValue,
      disableFutureDates,
      clearable,
      inputEnabled,
      dateFormat,
      navigationEnabled,
      disabled,
    });
    if (f.defaultOption) onExecute(f.defaultOption.value);
    return f;
  }, [label, defaultValue, hashParam, disableFutureDates]);

  return {
    filter,
    value,
  };
}
