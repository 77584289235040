import { makeStyles } from '@material-ui/core/styles';

const useAltHeaderStyles = makeStyles((theme) => {
  const verticalPadding = theme.spacing(0.5);
  const horizontalPadding = theme.spacing(0.5);
  
  const dividerVerticalMargin = theme.spacing(0);
  const dividerHorizontalMargin = theme.spacing(1);

  return {
    root: {
      backgroundColor: theme.palette.background.default,
      padding: `${verticalPadding}px ${horizontalPadding}px`,
    },
    divider: {
      backgroundColor: theme.palette.primary.main,
      margin: `${dividerVerticalMargin}px ${dividerHorizontalMargin}px`,
    },
    subContainer: {
      marginTop: 'auto',
      marginBottom: 'auto',
      overflow: 'hidden',
    },
  };
});

export {
  useAltHeaderStyles,
};
