/* eslint-disable react/no-danger */
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box, Paper } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import clsx from 'clsx';
import type { ThemeType } from 'types/ThemeType';
import type { SpacingType } from 'types/Types';
import IconButton from 'components/IconButton/IconButton';

export type FeedBackNotificationType = {
  _id?: string,
  userId?: string,
  tenantId?: number,
  notifiableId?: string,
  notifiableType?: string,
  message: {
    subject: string,
    html?: string,
    text?: string,
  },
  createdAt?: string,
  updatedAt?: string,
  viewedAt?: string,
  deletedAt?: string,
};

export interface FeedBackNotificationProps {
  isDeleted?: boolean,
  /** ClassName to apply to the component. */
  className?: string,
  /** The notification to render inside the component. */
  notification: FeedBackNotificationType,
  /** What is the default internal padding of the component. */
  spacing?: SpacingType,
  /** The callback function for when the message has been updated */
  onUpdate?: (item: FeedBackNotificationType, action: string, value: boolean) => void,
  /** notification icon */
  icon: React.ReactElement,
  /** notification action */
  action?:React.ReactElement | React.ReactElement[] | null,
  /** notification color indicator */
  color: 'success'|'info'|'warning'|'danger',
}

const useStyles = makeStyles((theme: ThemeType) => ({
  root: (props: any) => ({
    padding: theme.spacing(props.spacing),
    display: props.isDeleted && 'none',
    marginBottom: theme.spacing(1),
  }),
  spacing: {
    marginRight: theme.spacing(1),
  },
  header: {
    display: 'flex',
    fontSize: '.8rem',
    alignItems: 'center',
  },
  timestamp: {
    flexGrow: 1,
    textAlign: 'right',
    color: theme.palette.text.disabled,
    lineHeight: 'normal',
  },
  action: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
  close: {
    cursor: 'pointer',
  },
  display: {
    padding: theme.spacing(0, 1, 1, 1),
    fontSize: 11,
  },
  title: {
    padding: theme.spacing(1, 1, 1, 1),
    paddingTop: 6,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: 12,
  },
  externalAction: {
    paddingRight: theme.spacing(1),
    '& > a': {
      color: theme.palette.primary.main,
      textDecoration: 'none',
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.primary.dark,
      },
    },
  },
}));

const badgeStyles = makeStyles((theme:ThemeType) => ({
  success: {
    backgroundColor: theme.palette.success.main,
    color: 'white',
  },
  info: {
    backgroundColor: theme.palette.info.main,
    color: 'white',
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
    color: 'white',
  },
  danger: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
  },
}));

export default function FeedBackNotification(props: FeedBackNotificationProps) {
  const {
    className,
    notification,
    spacing = 0,
    onUpdate,
    icon,
    action,
    color,
    ...otherProps
  } = props;
  const isDeleted = notification && notification.deletedAt;
  const isRead = notification && notification.viewedAt;
  const classes = useStyles({
    ...props, isDeleted, isRead, spacing,
  });
  const [showDetail, setShowDetail] = useState(false);
  const badge = badgeStyles(props);

  const handleDeleteClick = () => {
    const value = !isDeleted;

    if (onUpdate) {
      onUpdate(notification, 'deleted', value);
    }
  };

  return (
    // eslint-disable-next-line jsx-a11y/aria-role
    <Paper role="notificationPanel" className={clsx(className, classes.root)} {...otherProps}>
      <Box display="grid" gridTemplateColumns="40px 1fr">
        <Box p={1} className={badge[color]} display="grid" alignItems="center">
          {icon || null}
        </Box>
        <Box pt={1} display="grid" gridTemplateColumns="1fr auto">
          <Box onClick={() => setShowDetail((p) => !p)} style={{ cursor: 'pointer' }} pr={1}>
            <div className={classes.title}>
              <strong>
                {notification.message.subject}
              </strong>
            </div>
            {showDetail && (
              <div
                className={clsx(classes.display)}
                dangerouslySetInnerHTML={{ __html: notification.message.html || '' }}
              />
            )}
          </Box>
          <Box>
            <span className={clsx(classes.externalAction)}>
              {action || null}
            </span>
            <IconButton size="small" fontSize="small" color="primary" onClick={handleDeleteClick}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
}

FeedBackNotification.defaultProps = {
  spacing: 0,
};
