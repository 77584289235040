// eslint-disable-next-line max-classes-per-file
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useURLHashParams from './hashParameters';
import { ListingFilter } from './ListingFilter';
import { type SelectOption } from './ListingFilterSelect';

export type SetFilteredRowsFunction = (rows: any[]) => void;
const NO_SELECTION_VALUE = '<<ALL-ELEMENTS-SELECTED>>';

interface UseListSelectFilterProps {
  label:string,
  options:SelectOption[],
  unselectedLabel?:string,
  defaultValue?:string,
  hashParam?:string,
}

/**
Included here are examples on how to use the **useListSelectFilter** hook.

## Usage

This hook is intended to be used with DevEx DataGrid Tables, and useMaestroDataSource from Central Provider.

See full documentation here:
https://servicesolutions.atlassian.net/wiki/spaces/GSDSIS/pages/2365849655/Maestro+Data+Source
 */
export function useListSelectFilter({
  label, options, unselectedLabel, hashParam, defaultValue,
}:UseListSelectFilterProps) {
  const { t, i18n } = useTranslation();
  const { getHashValue, setHashValue } = useURLHashParams();
  const [value, setValue] = useState<string|undefined>();

  const fullOptions: SelectOption[] = [];
  if (unselectedLabel) fullOptions.push({ label: t(unselectedLabel), value: NO_SELECTION_VALUE });
  options.forEach((option) => {
    fullOptions.push({ label: t(option.label), value: option.value });
  });

  const filter = useMemo(() => {
    const onExecute = (newValue: string | undefined) => {
      setValue(newValue === NO_SELECTION_VALUE ? undefined : newValue);
      return true;
    };
    const f = new ListingFilter({
      label: t(label),
      options: fullOptions,
      onExecute,
      hashParam,
      defaultValue,
      getHashValue,
    });
    if (f.defaultOption) {
      const defaultOptionValue = f.defaultOption.value;
      onExecute(defaultOptionValue);
      if (hashParam) {
        setHashValue(
          hashParam,
          defaultValue || defaultOptionValue !== NO_SELECTION_VALUE
            ? defaultOptionValue
            : undefined,
        );
      }
    }
    return f;
  }, [label, unselectedLabel, JSON.stringify(options), hashParam, i18n?.language, defaultValue]);

  return {
    filter,
    value,
  };
}
