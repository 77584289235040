import React from 'react';
import type { URLHashParamsManager } from './hashParameters';
import type { SelectOption } from './ListingFilterSelect';
import type { SetFilteredRowsFunction } from './SelectFilter';
import Select from './ListingFilterSelect';
import type { FilterGroup } from './ListingFiltersGroup';

const NO_SELECTION_VALUE = '<<ALL-ELEMENTS-SELECTED>>';

function defaultOnExecute() {
  return true;
}

export class ListingFilter {
  label: string;
  options: SelectOption[];
  onFilter: (elem: any, value: string) => boolean;
  defaultOption?: SelectOption;
  onExecute: (value: string|undefined) => boolean;
  hashParam: string | undefined;
  defaultValue: string | undefined;

  constructor({
    label,
    options,
    onFilter,
    defaultValue,
    onExecute,
    hashParam,
    getHashValue = () => '',
  }:{
    label: string,
    options: SelectOption[],
    onFilter?: (elem: any, value: string) => boolean,
    defaultValue?: string,
    onExecute?: (value: string|undefined) => boolean,
    hashParam?: string,
    getHashValue?: (key:string) => string|undefined,
  }) {
    this.label = label;
    this.options = options;
    this.onFilter = onFilter || (() => true);
    this.onExecute = onExecute || defaultOnExecute;
    this.hashParam = hashParam;
    this.defaultValue = defaultValue;

    let initialValue = this.defaultValue;
    if (this.hashParam && getHashValue(this.hashParam)) {
      initialValue = getHashValue(this.hashParam);
    }
    if (initialValue) {
      for (let i = 0; i < options.length; i += 1) {
        if (options[i].value === initialValue) {
          this.defaultOption = options[i];
          break;
        }
      }
    }
    if (this.defaultOption == null && options.length > 0) {
      // eslint-disable-next-line prefer-destructuring
      this.defaultOption = options[0];
    }
  }

  loadHashValue(filterGroup: FilterGroup, hashParamsManager: URLHashParamsManager, rows?: any[], setFilteredRows?: SetFilteredRowsFunction) {
    if (!this.hashParam) return;
    const newValue = hashParamsManager.getHashValue(this.hashParam) || this.defaultValue || '';
    if (filterGroup.values.get(this) === newValue) return;
    let value: string | undefined;
    for (let i = 0; i < this.options.length; i += 1) {
      if (this.options[i].value !== newValue) continue;
      value = this.options[i].value || undefined;
      break;
    }
    if (!this.onExecute(value)) return;
    filterGroup.execute(this, value || '', rows, setFilteredRows);
  }

  execute(value: string, rows: any[], callback: (filteredRows: any[]) => void): void {
    callback(rows.filter((e) => this.onFilter(e, value)));
  }

  render(filterGroup: FilterGroup, hashParamsManager: URLHashParamsManager, rows?: any[], setFilteredRows?: SetFilteredRowsFunction) {
    const { setHashValue } = hashParamsManager;
    return (
      <Select
        value={filterGroup.values.get(this) || (this.defaultOption || { value: undefined }).value}
        variant="standard"
        unselectedValue="All"
        title={this.label}
        options={this.options}
        onChange={(event) => {
          if (this.hashParam) {
            setHashValue(
              this.hashParam,
              this.defaultValue || event.target.value !== NO_SELECTION_VALUE
                ? event.target.value
                : undefined,
            );
          }
          if (!this.onExecute(event.target.value)) return;
          filterGroup.execute(this, event.target.value, rows, setFilteredRows);
        }}
        fromList
      />
    );
  }
}
