import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Cancel, EventAvailableRounded } from '@material-ui/icons';
import Panel from 'components/Panel/Panel';
import moment from 'moment';
import clsx from 'clsx';
import { useAppointmentDisplayStyles } from './useAppointmentDisplayStyles';

interface AppointmentDisplayProps {
  date?: Date;
  /** The `time` string should be in the format hh:mm tt */
  time?: string;
  unselectedAppointmentText?: string;
}

const AppointmentDisplay = (props: AppointmentDisplayProps) => {
  const { date, time, unselectedAppointmentText } = props;
  const classes = useAppointmentDisplayStyles();

  return (
    <Grid item xs={12} className={classes.appointmentContainer}>
      <Panel
        dense
        variant="secondary"
        className={classes.appointmentPanel}
      >
        <Typography className={classes.appointment}>
          {date ? (
            <>
              <EventAvailableRounded className={clsx(classes.appointmentIcon, classes.appointmentIconSelected)} />
              <b className={classes.appointmentText}>
                {`${moment(date).format('dddd, MMMM Do').toString()} ${time
                  ? ` - ${time}`
                  : ''}`}
              </b>
            </>
          ) : (
            <>
              <Cancel className={clsx(classes.appointmentIcon, classes.appointmentIconUnselected)} />
              {!!unselectedAppointmentText && (
                <b className={classes.appointmentText}>
                  {unselectedAppointmentText}
                </b>
              )}
            </>
          )}
        </Typography>
      </Panel>
    </Grid>
  )
};

export { AppointmentDisplay, AppointmentDisplayProps };