import React from 'react';
import type { ThemeSwitchProps } from '../types/ThemeSwitchProps';

interface WithThemeOptionsParams<TThemeName extends string> {
  themeOptions: Readonly<ThemeSwitchProps<TThemeName>['themeOptions']>;
}

const withThemeOptions = <TThemeName extends string>(params: WithThemeOptionsParams<TThemeName>) => {
  return <WrappedComponentProps extends ThemeSwitchProps<TThemeName>>(WrappedComponent: React.FC<WrappedComponentProps>) => {
    return (props: Omit<WrappedComponentProps, 'themeOptions'>) => {
      return (
        <WrappedComponent
          {...(props as WrappedComponentProps)}
          themeOptions={params.themeOptions}
        />
      ) as JSX.Element;
    };
  };
};

export {
  withThemeOptions,
};
