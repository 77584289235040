import React from 'react';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { AccountDisplayProps } from 'components/AltHeader/types';
import { type LegacyCanvasHeaderProps } from '../LegacyCanvasHeader';
import { AltHeader } from '../../AltHeader';
import { prepareCanvasHeaderElements } from './utils/prepareCanvasHeaderElements';

type AccountNumber = string | number;

interface CanvasHeaderProps extends Omit<Partial<LegacyCanvasHeaderProps>, 'billTo' | 'shipTo'> {
  parentCompany?: Partial<AccountDisplayProps['account']> & { accountNumber?: AccountNumber };
  homeOffice?: Partial<AccountDisplayProps['account']> & { accountNumber?: AccountNumber };
  /**
   * For backwards compatibility with what is now the `LegacyCanvasHeader`,
   * this prop, by default, uses the `name` property as its account number.
   *
   * If you want to display both the accountNumber and the name of the account,
   * pass the account number as `accountNumber` and the name as `name`.
   */
  billTo?: Partial<LegacyCanvasHeaderProps['billTo']> & { accountNumber?: AccountNumber };
  /**
   * For backwards compatibility with what is now the `LegacyCanvasHeader`,
   * this prop, by default, uses the `name` property as its account number.
   *
   * If you want to display both the accountNumber and the name of the account,
   * pass the account number as `accountNumber` and the name as `name`.
   */
  shipTo?: Partial<LegacyCanvasHeaderProps['shipTo']> & { accountNumber?: AccountNumber };
}

/**
 * An alternative header for use within SalesForce-Canvas-embedded apps. For
 * additional flexibility, utilize the base component: `AltHeader`.
 *
 * For backwards compatibility with what is now the `LegacyCanvasHeader`, the
 * `billTo` and `shipTo` props, by default, use the `name` property as their
 * account number.
 *
 * If you want to display both the accountNumber and the name of the account,
 * pass the account number as `accountNumber` and the name as `name`.
 */
const CanvasHeader = (props: CanvasHeaderProps) => {
  const {
    roleName,
    parentCompany,
    homeOffice,
    billTo,
    shipTo,
    interactions,
  } = props;

  const theme = useTheme();
  const isMdScreen = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true });
  const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });

  const elements = React.useMemo(() => prepareCanvasHeaderElements({
    roleName,
    parentCompany,
    homeOffice,
    billTo,
    shipTo,
    interactions,
  }), [roleName, billTo, shipTo, interactions, theme, isMdScreen, isSmScreen]);

  return (
    <AltHeader items={elements} />
  );
};

export {
  CanvasHeader,
  type CanvasHeaderProps,
};
