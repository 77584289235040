/* eslint-disable no-param-reassign */
import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import uuid from 'uuid';
import LoadingBounce from 'components/LoadingBounce/LoadingBounce';
import DialogContext from './DialogContext';
import DialogItem from './DialogItem';
import { DialogType } from './DialogType';

export type DialogContent = React.ReactNode | React.ReactNode[];
export interface DialogProviderProps {
  key?: string | number,
  hideCloseIcon?: boolean,
  onClose?: (event?: React.MouseEvent) => void
  disableBackdropClick?: boolean,
  disableEscapeKeyDown?: boolean,
}

export interface DialogTypeProps {
  key?: any,
  dialogType?: DialogType,
  open?: boolean,
  title?: string|React.ReactNode,
  content?: DialogContent,
  okButton?: string,
  cancelButton?: string,
  onOkClick?: (event?: React.MouseEvent) => void,
  onCancelClick?: (event?: React.MouseEvent) => void,
  options?: DialogProviderProps,
  hideCloseIcon?: boolean,
  onClose?: (event?: React.MouseEvent) => void,
  children?: React.ReactNode,
}

interface DialogsState {
  dialogs: DialogTypeProps[],
  contextValue: any,
}

/**
## v1.3.14+

The **DialogProvider** has been extended to accept extra `settings` and pass those options along to the `<Dialog />`. This means that all
the properties available on the [Material UI **Dialog**](https://material-ui.com/api/dialog/) are available to be pass via `settings`.

> For example:
> `confirmDialog(content, title, okButton, cancelButton, onOkClick, onCancelClick, { disableBackdropClick: true, disableEscapeKeyDown: true })`

### Quick Reference
| Property | Type | Description |
| ----------- | ----------- | ----------- |
| `disableBackgroundClick` | boolean | Disable the click on the background to close the dialog |
| `disableEscapeKeyDown` | boolean | Disable the Escape key while the dialog is visable |
| `fullScreen` | boolean | The dialog will be displayed full-screen |
| `fullWidth` | boolean | The dialog will be displayed full-width |
| `maxWidth` | 'xs', 'sm', 'md', 'lg', 'xl', false | Set the max-width of the dialog. The dialog width grows with the size of the screen |
 */
class DialogProvider extends Component<DialogTypeProps, DialogsState> {
  constructor(props: DialogTypeProps) {
    super(props);

    this.state = {
      dialogs: [],
      contextValue: {
        handleMessageDialog: this.handleMessageDialog,
        handleConfirmDialog: this.handleConfirmDialog,
        handleAlertDialog: this.handleAlertDialog,
        handleErrorDialog: this.handleErrorDialog,
        handleLoadingDialog: this.handleLoadingDialog,
      },
    };
  }
  handleMessageDialog = (
    content: DialogContent,
    title: string|JSX.Element,
    okButton?: string,
    onOkClick?: (event?: React.MouseEvent) => void,
    options?: DialogProviderProps,
  ) => {
    const key = options && options.key ? options.key : uuid.v4();
    let res:Promise<boolean>|null = null;
    if (!onOkClick) {
      res = new Promise((resolve) => {
        onOkClick = () => resolve(true);
      });
    }
    this.addDialog(DialogType.NONE, title, content, okButton, undefined, onOkClick, undefined, { key, ...options });
    return res;
  };
  handleConfirmDialog = (
    content: DialogContent,
    title: string|JSX.Element,
    okButton?: string,
    cancelButton?: string,
    onOkClick?: (event?: React.MouseEvent) => void,
    onCancelClick?: (event?: React.MouseEvent) => void,
    options?: DialogProviderProps,
  ) => {
    const hideCloseIcon = (options && options.hideCloseIcon);
    const key = options && options.key ? options.key : uuid.v4();
    let res:Promise<boolean>|null = null;
    if (!onOkClick && !onCancelClick) {
      res = new Promise((resolve) => {
        onOkClick = () => resolve(true);
        onCancelClick = () => resolve(false);
      });
    }

    this.addDialog(
      DialogType.CONFIRM,
      title,
      content,
      okButton || 'OK',
      cancelButton || 'Cancel',
      onOkClick,
      onCancelClick,
      { key, hideCloseIcon, ...options },
    );
    return res;
  };

  handleAlertDialog = (
    content: DialogContent,
    title: string|JSX.Element,
    okButton?: string,
    onOkClick?: (event?: React.MouseEvent) => void,
    options?: DialogProviderProps,
  ) => {
    const key = options && options.key ? options.key : uuid.v4();
    let res:Promise<boolean>|null = null;
    if (!onOkClick) {
      res = new Promise((resolve) => {
        onOkClick = () => resolve(true);
      });
    }

    this.addDialog(
      DialogType.ALERT,
      title,
      content,
      okButton || 'OK',
      undefined,
      onOkClick,
      undefined,
      { key, ...options },
    );
    return res;
  };

  handleErrorDialog = (
    content: DialogContent,
    title: string|JSX.Element,
    okButton?: string,
    onOkClick?: (event?: React.MouseEvent) => void,
    options?: DialogProviderProps,
  ) => {
    const key = options && options.key ? options.key : uuid.v4();
    let res:Promise<boolean>|null = null;
    if (!onOkClick) {
      res = new Promise((resolve) => {
        onOkClick = () => resolve(true);
      });
    }

    this.addDialog(
      DialogType.ERROR,
      title,
      content,
      okButton || 'OK',
      undefined,
      onOkClick,
      undefined,
      { key, ...options },
    );
    return res;
  };

  handleLoadingDialog = (
    content?: DialogContent,
    title?: string,
    options?: DialogProviderProps,
  ) => {
    const key = options && options.key ? options.key : uuid.v4();
    this.addDialog(
      DialogType.LOADING,
      title || <Trans>Loading...</Trans>,
      content || <LoadingBounce />,
      undefined, undefined, undefined, undefined,
      { key, hideCloseIcon: true, ...options },
    );
    return () => this.handleClose(null, key);
  };

  addDialog = (
    dialogType: DialogType,
    title: string|JSX.Element,
    content: DialogContent,
    okButton?: string,
    cancelButton?: string|undefined,
    onOkClick?: (event?: React.MouseEvent) => void,
    onCancelClick?: ((event?: React.MouseEvent) => void)|undefined,
    options?: DialogProviderProps,
  ) => {
    const key = options?.key || uuid.v4();
    if (!content) {
      return;
    }

    const dialog:DialogTypeProps = {
      key,
      dialogType,
      open: true,
      title,
      content,
      okButton,
      cancelButton,
      onOkClick: onOkClick || undefined,
      onCancelClick: onCancelClick || undefined,
      ...options,
    };

    this.setState(({ dialogs }) => ({
      dialogs: [...dialogs, dialog],
    }));
  };

  handleOkClick = (event, key) => {
    const dialog = this.state.dialogs.find((d) => d.key === key);

    this.setState((prevState) => ({
      dialogs: prevState.dialogs.filter((d) => d.key !== key),
    }));

    if (dialog && dialog.onOkClick) {
      dialog.onOkClick(event);
    }
  };

  handleCancelClick = (event, key) => {
    const dialog = this.state.dialogs.find((d) => d.key === key);

    this.setState((prevState) => ({
      dialogs: prevState.dialogs.filter((d) => d.key !== key),
    }));

    if (dialog && dialog.onCancelClick) {
      dialog.onCancelClick(event);
    }
  };

  handleClose = (event, key) => {
    const dialog = this.state.dialogs.find((d) => d.key === key);

    this.setState((prevState) => ({
      dialogs: prevState.dialogs.filter((d) => d.key !== key),
    }));

    if (dialog && dialog.onClose) {
      dialog.onClose(event);
    }
  };

  render() {
    const { children, ...props } = this.props;
    const { dialogs, contextValue } = this.state;

    return (
      <DialogContext.Provider value={contextValue}>
        {children}
        {dialogs.filter((dialog) => dialog.open).map((dialog) => (
          <DialogItem
            {...props}
            key={dialog.key}
            settings={dialog}
            onOkClick={(event) => this.handleOkClick(event, dialog.key)}
            onCancelClick={(event) => this.handleCancelClick(event, dialog.key)}
            onClose={(event) => this.handleClose(event, dialog.key)}
          />
        ))}
      </DialogContext.Provider>
    );
  }
}

export default DialogProvider;
