import { Box } from '@material-ui/core';
import React from 'react';
import { useDescriptionStyles } from './styles';
import { TitleItemType } from './types';

export interface IDescriptionProps {
  description: TitleItemType | undefined;
  visible?: boolean;
}

export const Description = (props: IDescriptionProps) => {
  const {
    description,
    visible = true,
  } = props;
  const classes = useDescriptionStyles();
  if (!visible || !description) return null;
  if (typeof description !== 'string') return <>{description}</>;
  return (
    <Box className={classes.descriptionText}>
      {description}
    </Box>
  );
};
