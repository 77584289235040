/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Avatar as MuiAvatar,
  Paper as MuiPaper,
  Popper as MuiPopper,
  ClickAwayListener as MuiClickAwayListener,
  Icon,
} from '@material-ui/core';
import clsx from 'clsx';
import type { ThemeType } from 'types/ThemeType';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { Schedule } from '@material-ui/icons';

export interface UserAvatarProps {
  name: string,
  email?: string,
  onClickEmail?: () => void,
  description?: string,
  image?: string,
  color?: 'none' | 'default' | 'primary' | 'secondary' | 'success' | 'info' | 'warning' | 'danger',
  className?: string,
  children?: React.ReactNode,
  style?: React.CSSProperties,
  avatarPosition?: 'left' | 'right' | 'none',
  onClick?: (event: React.MouseEvent<HTMLElement>) => void,
  closeMenu?: () => void,
  avatarOnly?: boolean,
  pendingInvitation?: boolean,
  icon?: React.ReactElement<any, string | React.JSXElementConstructor<any>>,
  anchorEl?: undefined | null | HTMLElement,
}

const OPACITY = {
  dark: 0.2,
  light: 0.4,
};

const useStyles = makeStyles((theme: ThemeType) => ({
  root: (props: UserAvatarProps) => ({
    boxSizing: 'border-box',
    position: 'relative',
    display: 'flex',
    width: '100%',
    flex: '1 1 0 %',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: props.children ? 'pointer' : 'default',
  }),
  text: (props) => ({
    flex: 1,
    paddingLeft: props.avatarPosition === 'left' ? theme.spacing(1) : 'inherit',
    paddingRight: props.avatarPosition === 'right' ? theme.spacing(1) : 'inherit',
    lineHeight: '12px',
  }),
  name: (props) => ({
    fontWeight: 'bold',
    fontSize: '12px',
    textAlign: props.avatarPosition === 'right' ? 'right' : 'left',
    whiteSpace: 'break-spaces',
  }),
  description: (props) => ({
    fontSize: '11px',
    textAlign: props.avatarPosition === 'right' ? 'right' : 'left',
  }),
  avatar: {
    fontSize: '14px',
    fontWeight: 'bold',
  },
  popper: {
    zIndex: theme.zIndex.drawer,
  },
  paper: {
    borderRadius: '8px',
  },
  primary: {
    backgroundColor: fade(theme.palette.primary.main, OPACITY[theme.palette.type]),
    color: fade(theme.palette.primary.dark, 1),
  },
  secondary: {
    backgroundColor: fade(theme.palette.secondary.main, OPACITY[theme.palette.type]),
    color: fade(theme.palette.secondary.contrastText, OPACITY[theme.palette.type]),
  },
  success: {
    backgroundColor: fade(theme.palette.primary.main, OPACITY[theme.palette.type]),
    color: fade(theme.palette.primary.dark, 1),
  },
  info: {
    backgroundColor: fade(theme.palette.info.main, OPACITY[theme.palette.type]),
    color: fade(theme.palette.info.dark, 1),
  },
  warning: {
    backgroundColor: fade(theme.palette.warning.main, OPACITY[theme.palette.type]),
    color: fade(theme.palette.warning.dark, 1),
  },
  danger: {
    backgroundColor: fade(theme.palette.error.main, OPACITY[theme.palette.type]),
    color: fade(theme.palette.error.main, 1),
  },
  email: {
    color: theme.palette.primary.main,
  },
  clickableEmail: {
    cursor: 'pointer',
  },
}));

export default function UserAvatar(props: UserAvatarProps) {
  const classes = useStyles(props);
  const {
    avatarPosition = 'left',
    name,
    email,
    description,
    image,
    color = 'default',
    className,
    children,
    avatarOnly = false,
    anchorEl,
    closeMenu,
    onClickEmail,
    pendingInvitation = false,
    icon,
    ...otherProps
  } = props;

  const words = name ? name.split(' ')
    .filter((w) => w !== '') : [] as string[];
  const initials = [] as string[];
  if (words.length) initials.push((words.shift() || '-')[0].toLocaleUpperCase());
  if (words.length) initials.push((words.pop() || '-')[0].toLocaleUpperCase());

  function onMenuClose(event) {
    if (event.target && event.target.nodeName === 'LI' && event.target.getAttribute('role') === 'option') return;
    closeMenu && closeMenu();
  }

  function handleEmailClick() {
    if (onClickEmail) {
      onClickEmail();
    }
  }

  const avatar = useMemo(() => {
    if (image) return undefined;
    if (icon) return <Icon>{icon}</Icon>;
    if (pendingInvitation) return <Schedule />;
    return initials.join('');
  }, [image, pendingInvitation, icon, initials]);

  return (
    <>
      <div className={clsx(classes.root, className)} onClick={props.onClick} {...otherProps}>
        {avatarPosition === 'left'
        && <MuiAvatar className={clsx(classes.avatar, classes[color])} src={image}>{avatar}</MuiAvatar>}
        {!avatarOnly
          ? (
            <div className={classes.text}>
              <div className={classes.name}>{name}</div>
              {email
              && (
              <div
                className={clsx(classes.description, classes.email, {
                  [classes.clickableEmail]: onClickEmail,
                })}
                onClick={handleEmailClick}
              >
                {email}
              </div>
              )}
              {description && <div className={classes.description}>{description}</div>}
            </div>
          )
          : null}
        {avatarPosition === 'right'
        && <MuiAvatar className={classes[color]} src={image}>{avatar}</MuiAvatar>}
      </div>
      {children && (
        <MuiPopper
          anchorEl={anchorEl}
          placement={avatarPosition === 'right' ? 'bottom-end' : 'bottom-start'}
          open={Boolean(anchorEl)}
          className={classes.popper}
        >
          <MuiClickAwayListener mouseEvent="onMouseDown" onClickAway={onMenuClose}>
            <MuiPaper className={classes.paper} elevation={6}>
              {children}
            </MuiPaper>
          </MuiClickAwayListener>
        </MuiPopper>
      )}
    </>
  );
}

UserAvatar.defaultProps = {
  color: 'default',
  avatarPosition: 'left',
};
