import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Paper } from '@material-ui/core';
import clsx from 'clsx';
import type { ThemeType } from 'types/ThemeType';
import type { PaperProps as MuiPaperProps } from '@material-ui/core/Paper';
import type { SpacingType } from 'types/Types';
import { acidForwardRef } from '../../utilities/forwardRef';

interface TabPanelProps extends MuiPaperProps {
  spacing?: SpacingType,
  name?: string,
  value: number,
  index: number,
  className?: string,
  children?: React.ReactNode,
  square?: boolean,
  variant?: 'outlined'|'elevation',
}

const useStyles = makeStyles((theme: ThemeType) => ({
  root: (props: TabPanelProps) => {
    const { spacing = 2 } = props;
    return ({
      padding: theme.spacing(spacing),
    });
  },
}));

const TabPanel = acidForwardRef<HTMLElement, TabPanelProps>((props, ref) => {
  const classes = useStyles(props);
  const {
    children, className, name, value, index, square, variant, ...otherProps
  } = props;

  const id = name ? `${name}-tabpanel-${index}` : `tabpanel-${index}`;
  const label = name ? `${name}-tab-${index}` : `tab-${index}`;

  return (
    <Paper
      role="tabpanel"
      variant={variant || 'outlined'}
      square={square === undefined ? true : square}
      hidden={value !== index}
      id={id}
      aria-labelledby={label}
      className={clsx(className, classes.root)}
      {...otherProps}
      ref={ref as React.RefObject<HTMLElement>}
    >
      {value === index && children}
    </Paper>
  );
});

TabPanel.defaultProps = {
  spacing: 2,
};

TabPanel.defaultProps = {
  spacing: 2,
};

export default TabPanel;
