import type { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';
import type { PartialDeep } from 'type-fest';
import { lighten, darken } from '@material-ui/core/styles';
import { MICHELIN_DESIGN_SYSTEM_COLORS } from './michelinDesignSystemConstants';

declare module '@material-ui/core/styles/createPalette' {
  interface PaletteColor {
    tint?: string;
  }

  interface Palette {
    default?: PaletteColor;
    alternative?: PaletteColor;
    none?: PaletteColor;
    brand?: {
      blue: string;
      white: string;
      yellow: string;
      purple: string;
      green: string;
      grey: string;
    };
  }

  interface PaletteOptions {
    default?: PaletteColor;
    alternative?: PaletteColor;
    none?: PaletteColor;
    brand?: {
      blue: string;
      white: string;
      yellow: string;
      purple: string;
      green: string;
      grey: string;
    };
  }
}

const TONAL_OFFSET = 0.2;

const createTintFactory = (tonalOffset: number) => (color: string) => {
  return lighten(color, tonalOffset * 2);
};

const createFullyPopulatedPaletteColorFactory = (tonalOffset: number) => {
  const getTint = createTintFactory(tonalOffset);

  return (primaryColor: string) => {
    return {
      tint: getTint(primaryColor),
      light: lighten(primaryColor, tonalOffset),
      main: primaryColor,
      dark: darken(primaryColor, tonalOffset),
    };
  };
};

const createMichelinThemeOptions = (type: Exclude<Exclude<ThemeOptions['palette'], undefined>['type'], undefined>): PartialDeep<ThemeOptions> => {
  const designSystemColors = MICHELIN_DESIGN_SYSTEM_COLORS[type?.toLocaleUpperCase()];
  const createFullyPopulatedPaletteColor = createFullyPopulatedPaletteColorFactory(TONAL_OFFSET);

  return {
    typography: {
      fontFamily: ['OpenSans', 'Arial', 'sans-serif'].join(','),
      fontSize: 14,
    },
    palette: {
      primary: createFullyPopulatedPaletteColor(designSystemColors.MICHELIN_BLUE),
      secondary: createFullyPopulatedPaletteColor(designSystemColors.GREY_SECONDARY),
      error: createFullyPopulatedPaletteColor(designSystemColors.ERROR_RED),
      warning: createFullyPopulatedPaletteColor(designSystemColors.WARNING_ORANGE),
      info: createFullyPopulatedPaletteColor(designSystemColors.INFO_BLUE),
      success: createFullyPopulatedPaletteColor(designSystemColors.SUCCESS_GREEN),
      type,
      tonalOffset: TONAL_OFFSET,
      // for accessibility, as per https://mui.com/material-ui/customization/palette/#accessibility
      contrastThreshold: 4.5,
      common: {
        black: designSystemColors.TEXT_NORMAL,
        white: designSystemColors.PRIMARY_WHITE,
      },
      grey: {
        100: designSystemColors.LAYOUT_GREY_1,
        200: designSystemColors.LAYOUT_GREY_2,
        300: designSystemColors.LAYOUT_GREY_3,
        400: designSystemColors.LAYOUT_GREY_4,
      },
      text: {
        primary: designSystemColors.TEXT_NORMAL,
        hint: designSystemColors.TEXT_HELPER,
        disabled: designSystemColors.TEXT_DISABLED,
      },
      background: {
        default: designSystemColors.LAYOUT_GREY_1,
        paper: designSystemColors.LAYOUT_WHITE,
      },
      default: createFullyPopulatedPaletteColor(designSystemColors.MICHELIN_BLUE),
      none: createFullyPopulatedPaletteColor(designSystemColors.GREY_SECONDARY),
      alternative: createFullyPopulatedPaletteColor(designSystemColors.ACCENT_PURPLE),
      brand: {
        blue: designSystemColors.MICHELIN_BLUE,
        white: designSystemColors.MICHELIN_WHITE,
        yellow: designSystemColors.MICHELIN_YELLOW,
        purple: designSystemColors.MICHELIN_PURPLE,
        green: designSystemColors.MICHELIN_GREEN,
        grey: designSystemColors.MICHELIN_GREY,
      },
    },
  };
};

export {
  createMichelinThemeOptions,
};
