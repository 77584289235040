import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { PaperProps as MuiPaperProps } from '@material-ui/core/Paper';
import { ArrowDropDown } from '@material-ui/icons';

interface ArrowDropdownProps extends MuiPaperProps {
  open: boolean,
}

const useStyles = makeStyles(() => ({
  dropdownOpen: {
    transform: 'rotate(-180deg)',
    transition: 'all .3s ease',
  },
  dropdownClosed: {
    transform: 'rotate(0)',
    transition: 'all .3s ease',
  },
}));

export default function ArrowDropdown({ open = false }: ArrowDropdownProps) {
  const classes = useStyles();

  return (
    <ArrowDropDown className={open ? classes.dropdownOpen : classes.dropdownClosed} />
  );
}
