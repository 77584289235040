/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/no-access-state-in-setstate */
import React, { forwardRef } from 'react';
import PhoneNumberInput, {
  getCountryCallingCode as getCallingCode,
  formatPhoneNumber as formatLocal,
  formatPhoneNumberIntl as formatInternational,
  isValidPhoneNumber as isValid,
  isPossiblePhoneNumber as isPossible,
} from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import PhoneNumberTextFieldForwardRef from './PhoneNumberTextField';
import { CountrySelect } from './CountrySelect';

export interface PhoneInputProps {
  id?: string,
  label?: string,
  value?: string,
  defaultCountry?: string,
  readOnly?: boolean,
  required?: boolean,
  onChange?: (event: PhoneInputState) => void,
  hideCountryIcon?: boolean,
}
export interface PhoneInputState {
  id: string
  value: string,
  isValid: boolean,
  isPossible: boolean,
}

const PhoneNumberTextField = forwardRef((props, ref) => (
  <PhoneNumberTextFieldForwardRef innerRef={ref} {...props} />
));

class PhoneInput extends React.Component<PhoneInputProps, PhoneInputState> {
  constructor(props: PhoneInputProps) {
    super(props);
    const value = formatInternational(props.value);
    this.state = {
      id: props.id || '',
      value,
      isPossible: true,
      isValid: true,
    };
  }

  shouldComponentUpdate(nextProps: PhoneInputProps) {
    if (this.props.required !== nextProps.required || this.props.readOnly !== nextProps.readOnly) {
      this.setState({
        isPossible: this.checkPossiblePhone(this.state.value),
        isValid: this.checkValidPhone(this.state.value),
      });
    }

    if (this.props.value !== nextProps.value) {
      this.handleUpdate(nextProps.value || '');
    }

    return true;
  }

  handleUpdate = (value: string) => {
    const data = { ...this.state };
    data.value = value;
    data.isPossible = this.checkPossiblePhone(value);
    data.isValid = this.checkValidPhone(value);
    this.setState(data);
    if (this.props.onChange) this.props.onChange(data);
  };

  checkValidPhone = (value) => {
    if (!value) return !this.props.required;
    return isValidPhoneNumber(value);
  };

  checkPossiblePhone = (value) => {
    if (!value || !value.length) return true;
    return isPossiblePhoneNumber(value);
  };

  render() {
    const showError = !this.props.readOnly && (!this.state.isPossible || (this.props.required && !this.state.value));

    return (
      <PhoneNumberInput
        countrySelectProps={{ unicodeFlags: true }}
        defaultCountry={this.props.defaultCountry}
        value={this.state.value}
        onChange={this.handleUpdate}
        readOnly={this.props.readOnly}
        required={this.props.required}
        id={this.props.id}
        inputComponent={PhoneNumberTextField}
        numberInputProps={{
          label: this.props.label,
          error: showError,
        }}
        countrySelectComponent={this.props.hideCountryIcon ? () => null : CountrySelect}
      />
    );
  }
}

export const formatPhoneNumberIntl = formatInternational;
export const formatPhoneNumber = formatLocal;
export const isValidPhoneNumber = isValid;
export const isPossiblePhoneNumber = isPossible;
export const getCountryCallingCode = getCallingCode;

export default PhoneInput;
