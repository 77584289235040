/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable prefer-const */
import React from 'react';
import { makeStyles } from '@material-ui/styles';
import type { SwitchProps as MuiSwitchProps } from '@material-ui/core/Switch';
import { FormControl as MuiFormControl, Switch as MuiSwitch } from '@material-ui/core';
import clsx from 'clsx';
import type { ThemeType } from 'types/ThemeType';
import { useTranslation } from 'react-i18next';

export interface SwitchProps extends Omit<MuiSwitchProps, 'color'> {
  color?: 'default' | 'primary' | 'secondary' | 'success' | 'info' | 'warning' | 'danger',
  labelPosition?: 'top' | 'left' | 'bottom' | 'right',
  label?: string,
  checkedLabel?: string,
  uncheckedLabel?: string,
  readOnly?: boolean,
  fullWidth?: boolean,
  className?: string,
  hideLabel?: boolean,
  labelClassName?: string,
  formControlClassName?: string,
}

const baseStyles = makeStyles((theme: ThemeType) => ({
  root: (props: any) => ({
    alignItems: 'center',
    flexDirection: (props.labelPosition === 'left' || props.labelPosition === 'right') ? 'row' : undefined,
  }),
  fullWidth: {
    width: '100%',
  },
  switch: (props: any) => ({
    display: 'inline-flex',
    alignItems: 'center',
    marginLeft: !props.hideLabel && props.labelPosition === 'left' ? theme.spacing(3) : undefined,
    marginRight: !props.hideLabel && props.labelPosition === 'right' ? theme.spacing(3) : undefined,
  }),
  checked: (props: any) => ({
    flex: 1,
    textAlign: 'left',
    color: (props.disabled || (props.readOnly && !props.checked)) && theme.palette.text.disabled,
  }),
  unchecked: (props: any) => ({
    flex: 1,
    textAlign: 'right',
    color: (props.disabled || (props.readOnly && props.checked)) && theme.palette.text.disabled,
  }),
  label: (props: any) => ({
    color: (props.disabled || props.readOnly) && theme.palette.text.disabled,
    flex: (props.labelPosition === 'left' || props.labelPosition === 'right') ? 1 : undefined,
  }),
}));

const colorStyles = makeStyles((theme: ThemeType) => ({
  primary: (props: any) => ({
    backgroundColor: props.checked && theme.palette.primary.main,
    opacity: (props.readOnly || props.disabled) && '60%',
  }),
  secondary: (props: any) => ({
    backgroundColor: props.checked && theme.palette.secondary.main,
    opacity: (props.readOnly || props.disabled) && '60%',
  }),
  success: (props: any) => ({
    backgroundColor: props.checked && theme.palette.success.main,
    opacity: (props.readOnly || props.disabled) && '60%',
  }),
  info: (props: any) => ({
    backgroundColor: props.checked && theme.palette.info.main,
    opacity: (props.readOnly || props.disabled) && '60%',
  }),
  warning: (props: any) => ({
    backgroundColor: props.checked && theme.palette.warning.main,
    opacity: (props.readOnly || props.disabled) && '60%',
  }),
  danger: (props: any) => ({
    backgroundColor: props.checked && theme.palette.error.main,
    opacity: (props.readOnly || props.disabled) && '60%',
  }),
}));

export default function Switch(props: SwitchProps) {
  const classes = baseStyles({ ...props });
  const colors = colorStyles({ ...props });
  let {
    uncheckedLabel,
    checkedLabel,
    color = 'primary',
    labelPosition = 'left',
    label, className,
    readOnly,
    fullWidth = true,
    hideLabel = false,
    labelClassName,
    formControlClassName,
    ...otherProps
  } = props;

  const disabled = props.disabled || readOnly;
  const { t } = useTranslation();

  const newColor = (() => {
    switch (color) {
      case 'primary':
      case 'secondary':
        return color;
      default:
        return 'default';
    }
  })();

  if (!uncheckedLabel && !checkedLabel && !hideLabel) {
    uncheckedLabel = t('no');
    checkedLabel = t('yes');
  }

  return (
    <MuiFormControl disabled={disabled} className={clsx(classes.root, formControlClassName, fullWidth && classes.fullWidth)}>
      {(labelPosition === 'top' || labelPosition === 'left')
      && <label className={clsx(classes.label, labelClassName)}>{label}</label>}

      <div className={clsx(classes.switch)}>
        {uncheckedLabel && <span className={clsx(classes.unchecked, labelClassName)}>{uncheckedLabel}</span>}
        <MuiSwitch
          color={newColor}
          classes={{
            root: clsx(className),
            thumb: clsx(colors[color || 'default']),
            track: clsx(colors[color || 'default']),
          }}
          {...otherProps}
          disabled={disabled}
        />
        {checkedLabel && <span className={clsx(classes.checked, labelClassName)}>{checkedLabel}</span>}
      </div>

      {(labelPosition === 'bottom' || labelPosition === 'right')
       && <label className={clsx(classes.label, labelClassName)}>{label}</label>}
    </MuiFormControl>
  );
}

Switch.defaultProps = {
  color: 'primary',
  labelPosition: 'left',
  fullWidth: true,
};
