import React from 'react';
import { useTranslation } from 'react-i18next';
import { withConditionalRender } from 'hocs/withConditionalRender';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { AccountDisplayProps } from '../types';
import { useAccountDisplayStyles } from '../styles';

const AddressLine = (props: { addressLine: string }) => {
  const { addressLine } = props;
  const classes = useAccountDisplayStyles();

  return (
    <p className={classes.detailsLine}>{addressLine}</p>
  );
};

const AddressLineWithConditionalRender = withConditionalRender()(AddressLine);

const AccountDisplay = (props: AccountDisplayProps) => {
  const {
    label,
    account,
  } = props;
  const classes = useAccountDisplayStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMdScreenAtMost = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true });
  const isSmScreenAtMost = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });
  
  const accountNameLabel = isMdScreenAtMost ? '' : t('Account Name:');

  return (
    <>
      <div className={classes.boldFont}>
        {label}
      </div>
      <div className={classes.detailsContainer}>
        <p className={classes.detailsLine}>
          {accountNameLabel}
          <span className={classes.boldFont}>{account.name}</span>
        </p>
        <AddressLineWithConditionalRender
          addressLine={account.addressLine1}
          shouldRender={!isMdScreenAtMost}
        />
        <AddressLineWithConditionalRender
          addressLine={account.addressLine2}
          shouldRender={!isSmScreenAtMost}
        />
      </div>
    </>
  );
};

export {
  AccountDisplay,
};
