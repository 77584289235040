import React from 'react';
import { Container } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { checkMasks } from './checkMasks';
import TextField from '../TextField/TextField';

export interface PONumberInputProps {
  poNumber: string;
  setNumber: React.Dispatch<React.SetStateAction<PONumberInputProps['poNumber']>>
  label?: string;
  mask?: string;
  maskAlt?: string;
  readOnly?: boolean;
  id?: string;
  enterInfoLater?: boolean;
  max?: number;
}
export const PONumberInput = ({
  poNumber,
  setNumber,
  label,
  mask,
  maskAlt,
  readOnly,
  id,
  enterInfoLater,
  max,
}: PONumberInputProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const number = poNumber || '';
  const checkMaskResult = checkMasks({
    str: number || '', mask, maskAlt, max, name: label,
  });

  return (
    <Container style={{ height: theme.spacing(11), margin: 0, padding: 0 }}>
      <TextField
        id={id}
        style={{
          background: enterInfoLater ? theme.palette.grey[50] : undefined,
        }}
        readOnly={readOnly}
        disabled={enterInfoLater}
        statusIcon={(() => {
          if (enterInfoLater) return undefined;
          if (!number || number.length === 0) return undefined;
          if (!checkMaskResult.isOk && !checkMaskResult.message[0]) return undefined;
          if (!checkMaskResult.severity) return 'success';
          if (checkMaskResult.severity === 'error') return 'error';
          return 'warning';
        })()}
        onChange={(e) => setNumber(e.target.value)}
        value={enterInfoLater ? '' : number || ''}
        label={t(label || '')}
        error={(() => {
          if (enterInfoLater) return undefined;
          if (!number || number.length === 0) return undefined;
          if (!checkMaskResult.severity) return undefined;
          if (checkMaskResult.severity === 'error') return true;
          return undefined;
        })()}
        InputLabelProps={{
          shrink: true,
        }}
        required
      />
      {checkMaskResult.message[0] && !enterInfoLater && (
      <div style={{
        color: (checkMaskResult.severity === 'error') ? theme.palette.error.main : undefined,
        fontSize: 10,
      }}
      >
        {checkMaskResult.message.map((message) => (
          <div key={message}>
            {t(message)}
          </div>
        ))}
      </div>
      )}
    </Container>
  );
};
