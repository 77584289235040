/* eslint-disable max-len */
import React, { CSSProperties } from 'react';
import { makeStyles } from '@material-ui/styles';

const SIZES = {
  xs: '53%',
  md: '70%',
  l: '100%',
  xl: '150%',
};

const MEASURES = {
  xs: '24px',
  md: '35px',
  l: '48px',
  xl: '73px',
};

const useStyles = (props) => makeStyles(() => ({
  wrapper: {
    width: MEASURES[props.size],
    height: MEASURES[props.size],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dotSpinCont: {
    position: 'relative',
    width: '10px',
    zoom: SIZES[props.size],
    height: '10px',
    borderRadius: '5px',
    backgroundColor: 'transparent',
    color: 'transparent',
    boxShadow: `0 -18px 0 0 ${props.color}, 12.72984px -12.72984px 0 0 ${props.color}, 18px 0 0 0 ${props.color}, 12.72984px 12.72984px 0 0 rgba(152, 128, 255, 0), 0 18px 0 0 rgba(152, 128, 255, 0), -12.72984px 12.72984px 0 0 rgba(152, 128, 255, 0), -18px 0 0 0 rgba(152, 128, 255, 0), -12.72984px -12.72984px 0 0 rgba(152, 128, 255, 0)`,
    animation: '$myEffectExit 2s infinite linear',
  },
  '@keyframes myEffectExit': {
    '0%': {
      boxShadow: `0 -18px 0 0 ${props.color}, 12.72984px -12.72984px 0 0 ${props.color}, 18px 0 0 0 ${props.color}, 12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0), 0 18px 0 -5px rgba(152, 128, 255, 0), -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0), -18px 0 0 -5px rgba(152, 128, 255, 0), -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0)`,
    },
    '100%': {
      boxShadow: `0 -18px 0 0 ${props.color}, 12.72984px -12.72984px 0 0 ${props.color}, 18px 0 0 0 ${props.color}, 12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0), 0 18px 0 -5px rgba(152, 128, 255, 0), -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0), -18px 0 0 -5px rgba(152, 128, 255, 0), -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0)`,
    },
    '12.5%': {
      boxShadow: `0 -18px 0 -5px rgba(152, 128, 255, 0), 12.72984px -12.72984px 0 0 ${props.color}, 18px 0 0 0 ${props.color}, 12.72984px 12.72984px 0 0 ${props.color}, 0 18px 0 -5px rgba(152, 128, 255, 0), -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0), -18px 0 0 -5px rgba(152, 128, 255, 0), -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0)`,
    },
    '25%': {
      boxShadow: `0 -18px 0 -5px rgba(152, 128, 255, 0), 12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0), 18px 0 0 0 ${props.color}, 12.72984px 12.72984px 0 0 ${props.color}, 0 18px 0 0 ${props.color}, -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0), -18px 0 0 -5px rgba(152, 128, 255, 0), -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0)`,
    },
    '37.5%': {
      boxShadow: `0 -18px 0 -5px rgba(152, 128, 255, 0), 12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0), 18px 0 0 -5px rgba(152, 128, 255, 0), 12.72984px 12.72984px 0 0 ${props.color}, 0 18px 0 0 ${props.color}, -12.72984px 12.72984px 0 0 ${props.color}, -18px 0 0 -5px rgba(152, 128, 255, 0), -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0)`,
    },
    '50%': {
      boxShadow: `0 -18px 0 -5px rgba(152, 128, 255, 0), 12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0), 18px 0 0 -5px rgba(152, 128, 255, 0), 12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0), 0 18px 0 0 ${props.color}, -12.72984px 12.72984px 0 0 ${props.color}, -18px 0 0 0 ${props.color}, -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0)`,
    },
    '62.5%': {
      boxShadow: `0 -18px 0 -5px rgba(152, 128, 255, 0), 12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0), 18px 0 0 -5px rgba(152, 128, 255, 0), 12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0), 0 18px 0 -5px rgba(152, 128, 255, 0), -12.72984px 12.72984px 0 0 ${props.color}, -18px 0 0 0 ${props.color}, -12.72984px -12.72984px 0 0 ${props.color}`,
    },
    '75%': {
      boxShadow: `0 -18px 0 0 ${props.color}, 12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0), 18px 0 0 -5px rgba(152, 128, 255, 0), 12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0), 0 18px 0 -5px rgba(152, 128, 255, 0), -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0), -18px 0 0 0 ${props.color}, -12.72984px -12.72984px 0 0 ${props.color}`,
    },
    '87.5%': {
      boxShadow: `0 -18px 0 0 ${props.color}, 12.72984px -12.72984px 0 0 ${props.color}, 18px 0 0 -5px rgba(152, 128, 255, 0), 12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0), 0 18px 0 -5px rgba(152, 128, 255, 0), -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0), -18px 0 0 -5px rgba(152, 128, 255, 0), -12.72984px -12.72984px 0 0 ${props.color}`,
    },
  },
}));

export interface SpinnerProps {
  size?: 'xs' | 'md' | 'l' | 'xl',
  color?: string,
  style?: CSSProperties;
}

export default function Spinner(props: SpinnerProps) {
  const classes = useStyles(props)();
  return (
    <div className={classes.wrapper} style={{ ...props.style }}>
      <div className={classes.dotSpinCont} />
    </div>
  );
}

Spinner.defaultProps = {
  color: 'rgb(122, 217, 95)',
  size: 'md',
  style: {},
};
