import React from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions
} from '@material-ui/core';
import {
  makeStyles,
} from '@material-ui/core/styles';
import Button from 'components/Button/Button';
import { useTranslation } from 'react-i18next';
import { usePhoneView } from '../../hooks/usePhoneView';

interface Props {
  handleDiscard: () => void;
  handleCancel: () => void;
  handleClose: () => void;
  message: string;
  title: string;
  discardLabel?: string;
  cancelLabel?: string;
  open: boolean;
}

const useStyles = makeStyles(() => ({
  dialogTitle: {
    fontFamily: 'Montserrat,sans-serif',
    fontWeight: 500,
    lineHeight: 1.6,
  },
}));

export default function PreventTransitionDialog(props: Props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const phoneView = usePhoneView();
  const {
    handleCancel,
    handleClose,
    handleDiscard,
    open,
    message,
    title,
    discardLabel = t('Yes'),
    cancelLabel = t('No'),
  } = props;

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
       <div className={classes.dialogTitle} style={{fontSize: phoneView ? '1.1rem' : '1.25rem'}}>{title}</div>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDiscard} color='danger' size='small'>
          {discardLabel}
        </Button>
        <Button onClick={handleCancel} size='small'>
          {cancelLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
