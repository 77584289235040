import { makeStyles } from "@material-ui/styles";

export const useDescriptionStyles = makeStyles(() => {
  const DESCRIPTION_FONT_COLOR = '#999999';

  return {
    descriptionText: {
      color: DESCRIPTION_FONT_COLOR,
    },
  };
});