import React from 'react';
import { makeStyles, createStyles } from '@material-ui/styles';
import Fade from '@material-ui/core/Fade';
import { Grid, Link } from '@material-ui/core';

const useStyles = makeStyles(() => createStyles({
  versionDisplay: {
    fontSize: '.6rem',
    padding: 5,
  },
}));

interface VersionProps {
  versionsLinkClick?: () => void;
  ui: string|null,
  api: string|null,
  open: boolean
}

export default function Version(props: VersionProps) {
  const {
    ui, api, open, versionsLinkClick,
  } = props;
  const classes = useStyles();
  const versions = (
    <>
      <span style={{ paddingLeft: 5, paddingBottom: 5, whiteSpace: 'nowrap' }}>
        {`UI: ${ui || ''}`}
      </span>
      {api && (
        <span style={{ paddingLeft: 5, whiteSpace: 'nowrap' }}>
          {`API: ${api}`}
        </span>
      )}
    </>
  );

  const data = (
    <Grid container>
      <Grid item xs={12}>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        {versionsLinkClick ? <Link style={{ cursor: 'pointer' }} onClick={versionsLinkClick}>{versions}</Link> : versions}
      </Grid>
    </Grid>
  );
  return (
    <>
      {open && (
      <div className={classes.versionDisplay}>
        <Fade in={open}>{data}</Fade>
      </div>
      )}
    </>
  );
}
