/* eslint-disable no-param-reassign */
// eslint-disable-next-line max-classes-per-file
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TextField from 'components/TextField/TextField';
import _ from 'lodash';
import { Clear, Done } from '@material-ui/icons';
import IconButton from 'components/IconButton/IconButton';
import { makeStyles } from '@material-ui/styles';
import type { SetFilteredRowsFunction } from './SelectFilter';
import { ListingFilter } from './ListingFilter';
import { FilterGroup } from './ListingFiltersGroup';
import useURLHashParams, { type URLHashParamsManager } from './hashParameters';

const useListTextFilterStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    gap: '5px',
  },
  iconButton: {
    backgroundColor: 'transparent',
  },
}));

export class TextFilter extends ListingFilter {
  lastInformedValue: string | undefined;

  constructor({
    label,
    onFilter,
    defaultValue,
    onExecute,
    hashParam,
    getHashValue = () => '',
  }:{
    label:string,
    onFilter?:(elem:any, value:string)=>boolean,
    defaultValue?: string,
    onExecute?: (value: string|undefined) => boolean,
    hashParam: string,
    getHashValue?: (key:string) => string|undefined,
  }) {
    super({
      label,
      options: [],
      onFilter,
      onExecute,
      hashParam,
      getHashValue,
      defaultValue,
    });

    if (this.hashParam && getHashValue(this.hashParam)) {
      this.defaultValue = getHashValue(this.hashParam);
    }
  }

  loadHashValue(filterGroup: FilterGroup, hashParamsManager: URLHashParamsManager, rows?: any[], setFilteredRows?: SetFilteredRowsFunction) {
    if (!this.hashParam) return;
    const newValue = hashParamsManager.getHashValue(this.hashParam) || this.defaultValue || '';
    if (this.lastInformedValue === newValue) return;
    this.lastInformedValue = newValue;
    if (!this.onExecute(newValue)) return;
    filterGroup.execute(this, newValue || '', rows, setFilteredRows);
  }

  render(filterGroup: FilterGroup, hashParamsManager: URLHashParamsManager, rows?: any[], setFilteredRows?: SetFilteredRowsFunction) {
    const { t } = useTranslation();
    const [inputValue, setInputValue] = useState<string | undefined>(this.defaultValue);
    const { setHashValue, getHashValue } = hashParamsManager;
    const classes = useListTextFilterStyles();

    const executeFilter = () => {
      if (this.hashParam) setHashValue(this.hashParam, this.defaultValue || undefined);

      if (!this.onExecute(this.defaultValue)) return;
      filterGroup.execute(
        this,
        this.defaultValue || '',
        rows,
        setFilteredRows,
      );
    };

    const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      this.defaultValue = value || undefined;
      setInputValue(value);
    };

    const clearInput = () => {
      this.defaultValue = undefined;
      setInputValue('');
      executeFilter();
    };

    return (
      <div style={{ alignItems: 'end' }} className={classes.container}>
        <TextField
          label={t(this.label)}
          onChange={onChangeInput}
          style={{ width: '100%' }}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              executeFilter();
            }
          }}
          defaultValue={this.defaultValue ?? ''}
        />
        {
            inputValue
            && (
            <div className={classes.container}>
              <IconButton size="small" onClick={executeFilter} className={classes.iconButton}>
                <Done color="primary" fontSize="inherit" />
              </IconButton>
              {getHashValue(this.hashParam ?? '') && (
              <IconButton size="small" onClick={clearInput} className={classes.iconButton}>
                <Clear color="error" fontSize="inherit" />
              </IconButton>
              )}
            </div>
            )
        }
      </div>
    );
  }
}

interface useListTextFilterProps {
  label: string,
  defaultValue?: string,
  hashParam: string,
}

/**
Included here are examples on how to use the **useListTextFilter** hook.

## Usage

This hook is intended to be used with DevEx DataGrid Tables, and useMaestroDataSource from Central Provider.

See full documentation here:
https://servicesolutions.atlassian.net/wiki/spaces/GSDSIS/pages/2365849655/Maestro+Data+Source
 */
export function useListTextFilter({
  label,
  hashParam,
  defaultValue,
}: useListTextFilterProps) {
  const { t } = useTranslation();
  const { getHashValue } = useURLHashParams();
  const [value, setValue] = useState<string|undefined>();

  const filter = useMemo(() => {
    const onExecute = (newValue:string|undefined) => {
      setValue(newValue);
      return true;
    };
    const f = new TextFilter({
      label: t(label),
      onExecute,
      hashParam,
      defaultValue,
      getHashValue,
    });

    return f;
  }, [label, defaultValue, hashParam]);

  return {
    filter,
    value,
  };
}
