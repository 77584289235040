import React, { useMemo } from 'react';
import { ListItemIcon, ListItemText, MenuItem } from '@material-ui/core';
import type { ThemeSwitchProps } from './types/ThemeSwitchProps';
import { getThemeToSwitchToDetails } from './utils/getThemeToSwitchToDetails';
import { switchThemeHandler } from './utils/switchThemeHandler';

/**
 * Displays a toggle for switching between two pre-defined themes.
 */
export const ThemeSwitch = <TThemeName extends string>(props: ThemeSwitchProps<TThemeName>) => {
  const {
    themeOptions,
    selectedTheme = themeOptions.on.value,
    onSwitchTheme,
    ParentContainer = ThemeSwitch.defaultProps.ParentContainer,
    IconContainer = ThemeSwitch.defaultProps.IconContainer,
    LabelContainer = ThemeSwitch.defaultProps.LabelContainer,
  } = props;

  const themeToSwitchToDetails = useMemo(() => {
    return getThemeToSwitchToDetails(themeOptions, selectedTheme);
  }, [themeOptions, selectedTheme]);

  const onClick = React.useCallback(() => {
    return switchThemeHandler(themeOptions, selectedTheme, onSwitchTheme);
  }, [themeOptions, selectedTheme, onSwitchTheme]);

  return (
    <ParentContainer
      onClick={onClick}
    >
      <IconContainer
        style={{ minWidth: '33px' }}
      >
        {themeToSwitchToDetails.icon}
      </IconContainer>
      <LabelContainer
        primary={themeToSwitchToDetails.switchToLabel}
      />
    </ParentContainer>
  );
};

ThemeSwitch.defaultProps = {
  ParentContainer: MenuItem,
  IconContainer: ListItemIcon,
  LabelContainer: ListItemText,
};
