import React, { useMemo, useState } from 'react';
import { SelectOption } from './ListingFilterSelect';

interface UseListSortProps {
    sortOptions: SelectOption[],
    defaultSortOption: string,
    defaultSortDirection: string,
}

interface SortParams {
    sortBy: string, 
    sortDirection: string,
}

export interface Sort extends UseListSortProps {
    onExecute: (sortParams: SortParams) => void,
}
  
export function useListSort({
    sortOptions,
    defaultSortOption,
    defaultSortDirection,
}: UseListSortProps) {

    const [sortOption, setSortOption] = useState<string>();
    const [sortDirection, setSortDirection] = useState<string>();

    const sort = useMemo((): Sort => {
        const onExecute = ({sortBy, sortDirection}: SortParams) => {
            setSortOption(sortBy);
            setSortDirection(sortDirection);
        };
        const s: Sort = {
            onExecute: onExecute,
            sortOptions: sortOptions,
            defaultSortOption: defaultSortOption,
            defaultSortDirection: defaultSortDirection,
        }
        if (s.defaultSortOption && s.defaultSortDirection) onExecute({sortBy: s.defaultSortOption, sortDirection: s.defaultSortDirection});
        return s;
    }, [JSON.stringify(sortOptions), defaultSortOption, defaultSortDirection]);

    return {
        sortOption,
        sortDirection,
        sort
    };
}