import {
  useMediaQuery,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import type { Breakpoint } from '@material-ui/core/styles/createBreakpoints';

export function usePhoneView(bp = 'xs') {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down(bp as Breakpoint), { noSsr: true });
}
