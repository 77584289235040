import React from 'react';
import hoistNonReactStatic from 'hoist-non-react-statics';
import { acidForwardRef } from '../../utilities/forwardRef';
import getDisplayName from './utils/getDisplayName';
import DialogContext, { DialogContextProps } from './DialogContext';

export interface DialogProviderOptionsObject {
  key?: string | number,
  hideCloseIcon?: boolean,
  onClose?: (event?: React.MouseEvent) => void,
  disableBackdropClick?: boolean,
  disableEscapeKeyDown?: boolean,
}

export interface WithDialogsProps {
  messageDialog: DialogContextProps['handleMessageDialog']
  alertDialog:DialogContextProps['handleAlertDialog']
  errorDialog:DialogContextProps['handleErrorDialog']
  confirmDialog:DialogContextProps['handleConfirmDialog']
  loadingDialog:DialogContextProps['handleLoadingDialog']
}

function withDialogs<P extends WithDialogsProps>(Component: React.ComponentType<P>):
React.ComponentClass<Omit<P, keyof WithDialogsProps>> & { WrappedComponent: React.ComponentType<P> } {
  const WrappedComponent = acidForwardRef((props:P, ref) => (
    <DialogContext.Consumer>
      {(context: any) => (
        <Component
          {...props}
          ref={ref}
          messageDialog={context.handleMessageDialog}
          confirmDialog={context.handleConfirmDialog}
          alertDialog={context.handleAlertDialog}
          errorDialog={context.handleErrorDialog}
          loadingDialog={context.handleLoadingDialog}
        />
      )}
    </DialogContext.Consumer>
  ));

  if (process.env.NODE_ENV !== 'production') {
    WrappedComponent.displayName = `WithDialogs(${getDisplayName(Component)})`;
  }

  hoistNonReactStatic(WrappedComponent, Component);

  return WrappedComponent as any;
}

export default withDialogs;
