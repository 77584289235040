import type { ThemeSwitchProps } from "../types/ThemeSwitchProps";

export const switchThemeHandler = <TThemeName extends string>(
  options: ThemeSwitchProps<TThemeName>['themeOptions'],
  currentValue: ThemeSwitchProps<TThemeName>['selectedTheme'],
  onSwitchTheme: ThemeSwitchProps<TThemeName>['onSwitchTheme'],
) => {
  switch (currentValue) {
    case options.on.value:
      return onSwitchTheme(options.off.value);
    case options.off.value:
      return onSwitchTheme(options.on.value);
    default:
      throw new TypeError('Unexpected theme value');
  }
};
