import { DialogContent } from '@material-ui/core/';
import { Theme, withStyles } from '@material-ui/core/styles';

const ClosableDialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(DialogContent);

export default ClosableDialogContent;
