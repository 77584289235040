/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Tooltip from 'components/Tooltip/Tooltip';

interface GlobalActionsProps {
  children?: React.ReactNode,
  contained?: boolean;
  disabled?: boolean;
  hint?: string;
  horizontal?: boolean;
  margin?: number;
}

const useStyles = (horizontal: boolean) => makeStyles(() => ({
  globalActionsContainer: {
    '& button': {
      zIndex: 1300,
      display: 'flex',
      marginTop: horizontal ? undefined : 8,
      marginLeft: horizontal ? 8 : 'auto',
    },
  },
}))();

const root = document.documentElement;
if (!root.style.getPropertyValue('--bottom-spacing')) {
  root.style.setProperty('--bottom-spacing', '24px');
}

export default function GlobalActions({
  hint, children, contained, disabled, horizontal = false, margin = contained ? 16 : 24,
}: GlobalActionsProps) {
  const styles = useStyles(horizontal);
  if (!children || disabled) return null;

  let childrenCount = 1;
  const childrenArray = Array.isArray(children) ? children : [children];
  childrenCount = childrenArray.length;
  const position = contained ? 'absolute' : 'fixed';
  const bottom = contained ? margin : 'var(--bottom-spacing)';
  return (
    <>
      {contained && <div style={{ height: childrenCount * 64 }} />}
      <Tooltip title={hint || ''}>
        <div
          className={styles.globalActionsContainer}
          style={{
            position,
            bottom,
            right: margin,
            zIndex: 1300,
            flexDirection: horizontal ? 'row' : 'column',
            display: horizontal ? 'flex' : undefined,
          }}
        >
          {childrenArray}
        </div>
      </Tooltip>
    </>
  );
}
