import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
  Avatar as MuiAvatar, Badge as MuiBadge, Icon as MuiIcon, Popover,
} from '@material-ui/core';
import { Notifications as NotificationsIcon } from '@material-ui/icons';
import type { ThemeType } from 'types/ThemeType';
import type { NotificationType } from 'components/Notification/Notification';
import Tooltip from 'components/Tooltip/Tooltip';
import NotificationList from 'components/NotificationList/NotificationList';

const useStyles = makeStyles((theme: ThemeType) => ({
  avatar: {
    background: 'none',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  root: {
    alignSelf: 'center',
  },
  badge: {
    position: 'absolute',
    right: theme.spacing(1.5),
    top: theme.spacing(1.5),
    fontWeight: 'bold',
    fontSize: '11px',
    zoom: '88%',
  },
  default: {
    backgroundColor: 'transparent',
    color: theme.palette.grey[500],
    '&:hover': {
      color: theme.palette.grey[600],
    },
  },
  primary: {
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
  secondary: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  },
  success: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
  },
  info: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.info.contrastText,
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
  },
  danger: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
}));

export interface NotifyAvatarProps {
  badgeColor?: 'default' | 'primary' | 'secondary' | 'success' | 'info' | 'warning' | 'danger',
  badgeContent?: number,
  className?: string,
  color?: 'default' | 'primary' | 'secondary' | 'success' | 'info' | 'warning' | 'danger',
  icon?: string | React.ReactElement,
  name?: string,
  style?: React.CSSProperties,
  tooltip?: string,
  onClick?: (event?: React.MouseEvent) => void,
  notifications?: NotificationType[],
  onUpdate?: (item: NotificationType, action: string, value: boolean) => void,
  onReadAllClick?: (items: NotificationType[]) => void,
  onDeleteAllClick?: (items: NotificationType[]) => void,
  onIntervalChange?: (value: number) => void,
}

const NotifyAvatar = React.forwardRef<HTMLButtonElement, NotifyAvatarProps>((props, ref) => {
  const classes = useStyles(props);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const {
    name,
    icon,
    color = 'primary',
    badgeContent = 0,
    badgeColor = 'danger',
    tooltip,
    notifications,
    onUpdate,
    onReadAllClick,
    onDeleteAllClick,
    onIntervalChange,
    ...otherProps
  } = props;

  let initials:string[] = [];
  if (name && name.length > 0) {
    const words = name.split(' ');
    initials = words.map((word, index) => ((index === 0 || index === (words.length - 1)) ? word[0].toUpperCase() : ''));
  }

  const iconElement = (icon && typeof icon === 'string') ? <MuiIcon>{icon}</MuiIcon> : icon;
  if (notifications) {
    const handleOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'notification-popover' : undefined;

    const visibleCount = notifications.filter((notification) => notification.deletedAt === null).length;
    const unreadCount = notifications.filter((notification) => (
      notification.deletedAt === null && notification.viewedAt === null
    )).length;

    return (
      <>
        <MuiBadge
          badgeContent={visibleCount}
          color="secondary"
          classes={{ root: classes.root, badge: clsx(classes.badge, classes[unreadCount ? 'danger' : 'success']) }}
          {...otherProps}
          onClick={handleOpen}
        >
          <MuiAvatar className={clsx(classes.avatar, classes[visibleCount ? 'primary' : 'default'])}>
            <NotificationsIcon />
          </MuiAvatar>
        </MuiBadge>
        {open && (
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <NotificationList
              notifications={notifications}
              onUpdate={onUpdate}
              onReadAllClick={onReadAllClick}
              onDeleteAllClick={onDeleteAllClick}
              onIntervalChange={onIntervalChange}
            />
          </Popover>
        )}
      </>
    );
  }

  return (
    <>
      <Tooltip title={tooltip || ''}>
        <MuiBadge
          badgeContent={badgeContent}
          color="secondary"
          classes={{ root: classes.root, badge: clsx(classes.badge, classes[badgeColor]) }}
          ref={ref}
          {...otherProps}
        >
          <MuiAvatar className={clsx(classes.avatar, classes[color])}>
            {initials.length > 0 ? initials : iconElement}
          </MuiAvatar>
        </MuiBadge>
      </Tooltip>
    </>
  );
});

NotifyAvatar.defaultProps = {
  color: 'primary',
  badgeContent: 0,
  badgeColor: 'danger',
};

export default NotifyAvatar;
