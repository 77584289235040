/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
// ------------------------------------------------------------
// Country Select
// ------------------------------------------------------------
import React, { useCallback, useMemo } from 'react';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import { CountrySelectComponentProps } from 'react-phone-number-input';

interface CountrySelectProps {
  // A two-letter country code.
  // E.g. "US", "RU", etc.
  value?: string,
  onChange?: (value?: string) => void,
  // `<select/>` options.
  options?: CountrySelectComponentProps['options'];
  // Country flag component.
  iconComponent?: any,
  // Select arrow component.
  arrowComponent?: any,
  // Set to `true` to render Unicode flag icons instead of SVG images.
  unicodeFlags?: boolean,
}

export function CountrySelect(props: CountrySelectProps) {
  const {
    value,
    onChange = () => {},
    options = [], iconComponent: Icon,
    arrowComponent: Arrow = () => <div />,
    unicodeFlags,
    ...otherProps
  } = props;

  const onChange_ = useCallback((event) => {
    const { eventValue } = event.target;
    onChange(eventValue === 'ZZ' ? undefined : eventValue);
  }, [onChange]);

  const selectedOption = useMemo(() => {
    for (const option of options) {
      if (option.value === value) {
        return option;
      }
    }
    return null;
  }, [options, value]);

  // "ZZ" means "International".
  // (HTML requires each `<option/>` have some string `value`).
  return (
    <div className="PhoneInputCountry">
      <select
        {...otherProps}
        disabled
        value={value || 'ZZ'}
        onChange={onChange_}
        className="PhoneInputCountrySelect"
      >
        {options.map(({ value, label }) => (
          <option
            key={value || 'ZZ'}
            value={value || 'ZZ'}
          >
            {label}
          </option>
        ))}
      </select>

      {/* Either a Unicode flag icon. */}
      {(unicodeFlags && value)
          && (
          <div className="PhoneInputCountryIconUnicode">
            {getUnicodeFlagIcon(value)}
          </div>
          )}

      {/* Or an SVG flag icon. */}
      {!(unicodeFlags && value) && (
      <Icon
        country={value}
        label={selectedOption && selectedOption.label}
        aspectRatio={unicodeFlags ? 1 : undefined}
      />
      )}
      <Arrow />
    </div>
  );
}
