import React from 'react';
import {
  Box,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Link as LinkIcon } from '@material-ui/icons';
import IconButton from 'components/IconButton/IconButton';
import Tooltip from 'components/Tooltip/Tooltip';
import type { ItemType } from '../Sidebar/Sidebar';
import { DialogProvider, useDialogs } from '../DialogProvider';
import icon from '../../images/logos/bib2.jpg';

interface FooterExternalsProps {
  items?: ItemType[];
  open?: boolean;
  application?: string;
}

const FooterExternals = ({
  items = [],
  open = false,
  application,
}: FooterExternalsProps) => {
  const { confirmDialog } = useDialogs();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const theme = useTheme();
  const isDarkTheme = theme.palette.type === 'dark';

  const onClickHandler = (item, e) => {
    const { onClick } = item;
    if (onClick) onClick(e);
    if (item.to) {
      confirmDialog(
        <div>
          {t(
            'You are about to leave {{application}} and navigate to {{label}}.',
            {
              application: application || 'Central',
              label: t(item.label || ''),
            },
          )}
          <br />
          {t('Do you wish to proceed?')}
        </div>,
        t('You are about to leave {{application}}', {
          application: application || 'Central',
        }),
        t('BACK TO {{application}}', {
          application: application || 'Central',
        }),
        t('GO TO SITE'),
        () => {},
        () => window.open(item.to),
      );
    }
  };

  return (
    <div style={{ display: 'flex' }}>
      {open ? (
        items
        && items.map((item, i) => {
          const { label } = item;
          return (
            <ListItem
              style={{ padding: '12px' }}
              key={`${label}_${i}`}
              button
              onClick={(e) => onClickHandler(item, e)}
            >
              <Tooltip title={label || ''} placement="right" arrow>
                <ListItemIcon
                  style={{
                    minWidth: 16,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {item.icon ? item.icon : <img src={icon} alt={item.label} />}
                </ListItemIcon>
              </Tooltip>
            </ListItem>
          );
        })
      ) : (
        <>
          <IconButton
            square
            aria-label="menu"
            style={{ padding: '8px' }}
            onClick={(e) => setAnchorEl(e.currentTarget)}
          >
            <LinkIcon
              style={{
                color: isDarkTheme
                  ? theme.palette.grey[500]
                  : theme.palette.grey[600],
              }}
            />
          </IconButton>
          <Menu
            id="customized-menu"
            anchorEl={anchorEl}
            keepMounted
            open={!!anchorEl}
            onClose={() => setAnchorEl(null)}
          >
            <Box
              style={{
                gridTemplateColumns: 'auto auto',
                gridColumnGap: '10px',
                columnGap: '10px',
              }}
            >
              {items.map((item, i) => (
                <MenuItem
                  onClick={(e) => onClickHandler(item, e)}
                  key={`${item.label}_${i}`}
                >
                  <ListItemIcon
                    style={{
                      minWidth: 16,
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {item.icon ? (
                      item.icon
                    ) : (
                      <img src={icon} alt={item.label} />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary={t(item.label || '')}
                    style={{ marginLeft: 8 }}
                  />
                </MenuItem>
              ))}
            </Box>
          </Menu>
        </>
      )}
    </div>
  );
};

export default (props) => (
  <DialogProvider>
    <FooterExternals {...props} />
  </DialogProvider>
);
