import React from 'react';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

interface ReportProps {
  src: string,
  title?: string,
  height?: number | string,
  width?: number | string,
}

const useStyles = makeStyles(() => ({
  root: (props: ReportProps) => ({
    frameBorder: 0,
    border: 0,
    width: props.width || '100%',
    height: props.height || '100%',
  }),
}));

export default function Report(props) {
  const classes = useStyles(props);
  const { className, title = 'report', ...otherProps } = props;

  return (
    <iframe className={clsx(classes.root, className)} title={title} {...otherProps} />
  );
}
