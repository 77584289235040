import { type Theme, makeStyles } from "@material-ui/core/styles";
import type { MichelinLogoProps } from "./MichelinLogo";

type UseMichelinLogoStylesProps = Pick<MichelinLogoProps, 'direction'>;

const DEFAULT_LOGO_HEIGHT = 150;

const TRANSFORM_ROTATE_90_DEG = 'rotate(90deg) translate(0, -100%)';

const STACKED_STYLES = {
  height: DEFAULT_LOGO_HEIGHT,
  width: 'auto',
  display: 'inline-block',
} as const;

const HORIZONTAL_STYLES = {
  height: 'auto',
  width: DEFAULT_LOGO_HEIGHT,
  display: 'inline-block',
  transformOrigin: '0 0',
  transform: TRANSFORM_ROTATE_90_DEG,
} as const;

const useMichelinLogoStyles = makeStyles<Theme, MichelinLogoProps>(() => {
  return {
    logo: (props: UseMichelinLogoStylesProps) => {
      const { direction } = props;

      const styles = direction === 'stacked' ? STACKED_STYLES : HORIZONTAL_STYLES;

      return styles;
    },
  };
});

export {
  useMichelinLogoStyles,
};
