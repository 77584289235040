import React from 'react';
import { Dialog, type DialogProps } from '@material-ui/core';
import ClosableDialogTitle from './ClosableDialogTitle';
import ClosableDialogContent from './ClosableDialogContent';

interface WithDialogProps<DisplayComponentProps extends {}> {
  dialogProps: Omit<DialogProps, 'open' | 'onClose'> & Partial<Pick<DialogProps, 'open' | 'onClose'>>,
  displayComponentProps: DisplayComponentProps;
  dialogTitle?: string;
}

const withDialog = <DisplayComponentProps extends {}>(DisplayComponent: React.ComponentType<DisplayComponentProps>) => <WrappedComponentProps extends {}>(WrappedComponent: React.ComponentType<WrappedComponentProps>) => (props: WrappedComponentProps & WithDialogProps<DisplayComponentProps>) => {
  const {
    dialogProps,
    displayComponentProps,
    dialogTitle,
    ...wrappedComponentProps
  } = props;

  return (
    <>
      <WrappedComponent {...wrappedComponentProps as unknown as WrappedComponentProps} />
      <Dialog
        {...dialogProps}
        open={!!dialogProps.open}
      >
        <ClosableDialogTitle onClose={() => {
          if (dialogProps.onClose) {
            dialogProps.onClose({}, 'escapeKeyDown');
          }
        }}>
          {dialogTitle}
        </ClosableDialogTitle>
        <ClosableDialogContent>
          <DisplayComponent
            {...displayComponentProps}
          />
        </ClosableDialogContent>
      </Dialog>
    </>
  );
};

export {
  withDialog,
  type WithDialogProps,
};
