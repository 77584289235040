import React, { useEffect, useMemo, useState } from 'react';
import { SimpleMdeReact, type SimpleMDEReactProps } from 'react-simplemde-editor';
import EasyMDE from 'easymde';
import clsx from 'clsx';
import { useMarkdownEditorStyles } from './useMarkdownEditorStyles';
import 'easymde/dist/easymde.min.css';

const DEFAULT_MDE_OPTIONS: EasyMDE.Options = {
  hideIcons: ['fullscreen', 'image', 'quote'],
  sideBySideFullscreen: false,
  status: false,
  autofocus: true,
};

const HIDE_TOOLBAR_OPTIONS: EasyMDE.Options = {
  toolbar: false,
};

export interface MarkdownEditorProps extends SimpleMDEReactProps {
  /**
   * The options to pass directly to the underlying MarkdownEditor component.
   * For a full list and description of the available options, refer to [this
   * documentation](https://github.com/Ionaru/easy-markdown-editor#configuration)
   *
   * Ensure what you pass here is a constant reference to an object - that is to
   * say, either a memoized object, or an object that is instantiated independently
   * of the render of your component. Failure to do so will result in a significant
   * performance degradation.
   */
  options?: SimpleMDEReactProps['options'];
  /**
   * Set to true to only allow the display of the rendered markdown preview.
   *
   * When this prop is true, `hideToolbar` will be inferred to be true as well, unless
   * `hideToolbar` is otherwise defined by the consumer.
   *
   * @implies `hideToolbar === true` (unless explicitly set otherwise)
   */
  previewOnly?: boolean;
  /**
   * Set to true to turn off the display of the toolbar.
   */
  hideToolbar?: boolean;
}

/**
 * The MarkdownEditor component provides a simple utility for users to input, edit, and
 * view Markdown-formatted text and its rendered output.
 *
 * With the `previewOnly` prop, the MarkdownEditor component can be used simply to render
 * Markdown-formatted text passed in via `value`
 *
 * This component is a wrapper around
 * [react-simplemde-editor](https://github.com/RIP21/react-simplemde-editor); for details
 * on props outside of `previewOnly` and `hideToolbar`, consider the linked documentation
 */
export const MarkdownEditor = (props: MarkdownEditorProps) => {
  const {
    options: providedOptions,
    className: providedClassName,
    previewOnly,
    hideToolbar: providedHideToolbar,

    ...otherProps
  } = props;
  const hideToolbar = providedHideToolbar ?? previewOnly;

  const [mdeInstance, setMdeInstance] = useState<EasyMDE | null>(null);
  const classes = useMarkdownEditorStyles({ isToolbarVisible: !hideToolbar });

  const options: EasyMDE.Options = useMemo(() => {
    const additionalInternallyAddedOptions = hideToolbar ? HIDE_TOOLBAR_OPTIONS : {};

    return {
      ...DEFAULT_MDE_OPTIONS,
      ...additionalInternallyAddedOptions,
      ...providedOptions,
    };
  }, [hideToolbar]);

  useEffect(() => {
    if (!mdeInstance || !previewOnly || mdeInstance.isPreviewActive()) return;

    EasyMDE.togglePreview(mdeInstance);
  }, [mdeInstance]);
  
  return (
    <SimpleMdeReact
      className={clsx(classes.editor, providedClassName)}
      getMdeInstance={(instance) => {
        if (instance) {
          setMdeInstance(instance);
        }
      }}
      options={options}
      {...otherProps}
    />
  );
};
