import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ThemeType } from 'types/ThemeType';
import moment from 'moment';
import { DateRangeValue } from './Types';

interface Props {
  year: number;
  month: number;
  value: DateRangeValue;
  onChangeDate: React.Dispatch<React.SetStateAction<DateRangeValue>>;
  hoverCell: string;
  setHoverCell: React.Dispatch<React.SetStateAction<string>>;
}

const useStyles = makeStyles((theme: ThemeType) => ({
  header: {
    opacity: 0.6,
  },
  cell: {
    width: '36px',
    height: '36px',
    textAlign: 'center',
    cursor: 'pointer',
    '&:not(th):hover': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.contrastText,
    },
  },
  selectedCell: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
    textAlign: 'center',
    borderTop: '1px solid white',
    borderBottom: '1px solid white',
  },
  selectedFromCell_beta: {
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
  },
  selectedToCell_beta: {
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
  },

  selectedFromCell: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
    textAlign: 'center',
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    borderTop: '1px solid white',
    borderBottom: '1px solid white',
  },
  selectedToCell: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
    textAlign: 'center',
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    borderTop: '1px solid white',
    borderBottom: '1px solid white',
  },
  rangeCell: {
    backgroundColor: theme.palette.secondary.tint,
    borderTop: '1px solid white',
    borderBottom: '1px solid white',
  },
  rangeFromToCell: {
    opacity: 2,
  },
}));

const clickHandler = (value: DateRangeValue, date: string, onChangeDate: React.Dispatch<React.SetStateAction<DateRangeValue>>) => {
  const cloneValue = value;

  if (cloneValue.from === '') {
    cloneValue.from = date;
    return;
  }
  if (cloneValue.from !== '' && cloneValue.to === '') {
    cloneValue.to = date;
    return;
  }

  if (date < cloneValue.from) {
    cloneValue.from = date;
    onChangeDate(cloneValue);
  } else {
    cloneValue.to = date;
    onChangeDate(cloneValue);
  }
};

const getDaysInMonth = (month: number, year: number) => new Date(year, month, 0).getDate();

const getFirstDayOfMonth = (month: number, year: number) => new Date(year, month - 1, 1).getDay();

function Calendar({ year, month, value, onChangeDate, hoverCell, setHoverCell }: Props) {
  const styles = useStyles();

  const firstDayOfMonth = getFirstDayOfMonth(month, year);
  const daysInMonth = getDaysInMonth(month, year);
  const filas: React.ReactElement[] = [];
  let fila: React.ReactElement[] = [];

  for (let i = 0; i < firstDayOfMonth; i += 1) {
    fila.push(<td key={i} />);
  }

  for (let i = 1; i <= daysInMonth; i += 1) {
    const date: string = `${year}${String(month).padStart(2, '0')}${String(i).padStart(2, '0')}`;
    const cellStyles: string[] = [];
    let isInRange: boolean = false;

    // Active range after
    if (value.from !== '' && date > value.from && date < hoverCell && hoverCell !== '') {
      cellStyles.push(styles.rangeCell);
      isInRange = true;
    }

    // Active range before
    if (value.to !== '' && date < value.to && date > hoverCell && hoverCell !== '') {
      cellStyles.push(styles.rangeCell);
      isInRange = true;
    }

    if (value.from !== '' && value.to !== '' && value.from < date && date < value.to) {
      cellStyles.push(styles.rangeCell);
    }

    if (date === value?.from || date === value?.to) {
      cellStyles.push(styles.selectedCell);
      if (date === value?.from) cellStyles.push(styles.selectedFromCell_beta);
      if (date === value?.to) cellStyles.push(styles.selectedToCell_beta);
      if (isInRange) cellStyles.push(styles.rangeFromToCell);
    } else {
      cellStyles.push(styles.cell);
    }

    fila.push(
      <td key={i + firstDayOfMonth - 1} className={cellStyles.join(' ')}>
        <div
          role="button"
          tabIndex={0}
          onKeyDown={() => {
            clickHandler(value, date, onChangeDate);
            setHoverCell('');
          }}
          onClick={() => {
            clickHandler(value, date, onChangeDate);
            setHoverCell('');
          }}
          onMouseEnter={() => setHoverCell(date)}
          onMouseLeave={() => setHoverCell('')}
          style={{
            display: 'flex',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography variant="body1" style={{ fontSize: '14px' }}>
            {i}
          </Typography>
        </div>
      </td>
    );
    if ((i + firstDayOfMonth - 1) % 7 === 6) {
      filas.push(<tr key={i}>{fila}</tr>);
      fila = [];
    }
  }

  if (fila.length > 0) {
    for (let i = fila.length; i < 7; i += 1) {
      fila.push(<td key={i} />);
    }
    filas.push(<tr key={daysInMonth}>{fila}</tr>);
  }

  return (
    <table style={{ width: '100%', boxSizing: 'border-box' }} cellPadding={0} cellSpacing={0}>
      <thead>
        <tr>
          {moment
            .localeData()
            .weekdaysMin()
            .map((day, index) => (
              <th key={`${index.toString()}-${month}-${day}`} className={[styles.cell, styles.header].join(' ')}>
                <Typography variant="overline">{day}</Typography>
              </th>
            ))}
        </tr>
      </thead>
      <tbody>{filas}</tbody>
    </table>
  );
}

export default Calendar;
