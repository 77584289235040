import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Button as MuiButton } from '@material-ui/core';
import type { ButtonProps as MuiButtonProps } from '@material-ui/core/Button';
import clsx from 'clsx';
import type { ThemeType } from 'types/ThemeType';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { acidForwardRef } from '../../utilities/forwardRef';

const defaultStyles = makeStyles((theme: ThemeType) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    border: `1px solid ${theme.palette.divider}`,
    padding: '0px 1.25rem',
    borderRadius: '2px',

    '&:hover': {
      textDecoration: 'underline',
    },
  },
  primary: {
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  secondary: {
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
    },
  },
  default: {
    '&:hover': {
      backgroundColor: theme.palette.default?.main,
      color: theme.palette.default?.contrastText,
    },
  },
  success: {
    '&:hover': {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.success.contrastText,
    },
  },
  warning: {
    '&:hover': {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.warning.contrastText,
    },
  },
  info: {
    '&:hover': {
      backgroundColor: theme.palette.info.main,
      color: theme.palette.info.contrastText,
    },
  },
  danger: {
    '&:hover': {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText,
    },
  },
  inherit: {
    '&:hover': {
      backgroundColor: 'inherit',
      color: 'inherit',
    },
  },
  asLink: {
    background: 'none',
    border: 'none',
    padding: '0',
    font: 'inherit',
    cursor: 'pointer',
    outline: 'inherit',
    textTransform: 'none',
    color: theme.palette.primary.dark,
    '&:hover': {
      background: 'none !important',
      textDecoration: 'none',
      color: theme.palette.primary.main,
    },
  },
}));

const containedStyles = makeStyles((theme: ThemeType) => ({
  default: {
    backgroundColor: theme.palette.default?.main,
    color: theme.palette.default?.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.default?.dark,
    },
  },
  primary: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  secondary: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  success: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
    },
  },
  info: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.info.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.info.dark,
    },
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.warning.dark,
    },
  },
  danger: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
}));

const outlinedStyles = makeStyles((theme: ThemeType) => ({
  default: {
    color: theme.palette.default?.main,
    borderColor: theme.palette.default?.main,
    '&:hover': {
      backgroundColor: theme.palette.default?.tint,
      color: theme.palette.default?.dark,
    },
  },
  primary: {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: fade(theme.palette.primary.main, 0.2),
      color: theme.palette.primary.dark,
    },
  },
  secondary: {
    color: theme.palette.secondary.main,
    borderColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.tint,
      color: theme.palette.secondary.dark,
    },
  },
  success: {
    color: theme.palette.success.main,
    borderColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.tint,
      color: theme.palette.success.dark,
    },
  },
  info: {
    color: theme.palette.info.main,
    borderColor: theme.palette.info.main,
    '&:hover': {
      backgroundColor: theme.palette.info.tint,
      color: theme.palette.info.dark,
    },
  },
  warning: {
    color: theme.palette.warning.main,
    borderColor: theme.palette.warning.main,
    '&:hover': {
      backgroundColor: theme.palette.warning.tint,
      color: theme.palette.warning.dark,
    },
  },
  danger: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.tint,
      color: theme.palette.error.dark,
    },
  },
}));

export interface ButtonProps extends Omit<MuiButtonProps, 'color' | 'variant'> {
  variant?: 'default' | 'text' | 'contained' | 'outlined',
  color?: 'default' | 'inherit' | 'primary' | 'secondary' | 'success' | 'info' | 'warning' | 'danger',
  asLink?: boolean,
}

const Button = acidForwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const {
    variant = 'default',
    color = 'default',
    className,
    children,
    asLink,
  } = props;
  const defaultStyle = defaultStyles(props);
  const containedStyle = containedStyles(props);
  const outlinedStyle = outlinedStyles(props);

  if (variant !== 'default') {
    switch (color) {
      case 'default':
      case 'success':
      case 'info':
      case 'warning':
      case 'danger':
      case 'primary':
      case 'secondary':
        return (
          <MuiButton
            {...props}
            color={undefined}
            variant={variant}
            ref={ref as React.RefObject<HTMLButtonElement>}
            classes={{
              root: clsx(className),
              contained: clsx(containedStyle[String(color)
                .toLowerCase()]),
              outlined: clsx(outlinedStyle[String(color)
                .toLowerCase()]),
              text: clsx(outlinedStyle[String(color)
                .toLowerCase()]),
            }}
          >
            {children}
          </MuiButton>
        );

      default:
        return (
          <MuiButton
            {...props}
            color={color}
            variant={variant}
            ref={ref as React.RefObject<HTMLButtonElement>}
          >
            {children}
          </MuiButton>
        );
    }
  }

  return (
    <MuiButton
      {...props}
      color="inherit"
      variant="text"
      className={clsx(defaultStyle.root, defaultStyle[color || 'default'], className, { [defaultStyle.asLink]: asLink })}
      ref={ref as React.RefObject<HTMLButtonElement>}
    >
      {children}
    </MuiButton>
  );
});

export default Button;
Button.defaultProps = {
  variant: 'default',
};
