import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

export const usePageTitleStyles = makeStyles((theme: Theme) => {
  const DARK_BACKGROUND = theme.palette.grey[900];
  const LIGHT_BACKGROUND = theme.palette.grey[100];

  let backgroundColor = '';
  switch (theme.palette.type) {
    case 'dark':
      backgroundColor = DARK_BACKGROUND;
      break;
    case 'light':
    default:
      backgroundColor = LIGHT_BACKGROUND;
      break;
  }
  return {
    titleContainer: {
      position: 'sticky',
      top: 0,
      marginTop: 0,
      zIndex: 1200,
      padding: 10,
      borderBottom: `1px solid ${theme.palette.divider}`,
      backgroundColor,
    },
    titleContainingGrid: {
      padding: '0 8px',
    },
    titleTypography: {
      display: 'inline-block',
    },
    mainTitleItem: {
      textAlign: 'left',
    },
    mainTitleText: {
      marginRight: theme.spacing(1),
      [theme.breakpoints.down('xs')]: {
        fontSize: '1rem',
      },
    },
    leftItems: {
      display: 'inline-block',
      marginRight: theme.spacing(1),
    },
    rightItems: {
      textAlign: 'right',
      marginTop: 4,
    },
    titleTypographyMobile: {
      lineHeight: 1
    }
  };
});
