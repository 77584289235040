import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Chip as MuiChip } from '@material-ui/core';
import clsx from 'clsx';
import { ThemeType } from 'types/ThemeType';
import { ChipProps as MuiChipProps } from '@material-ui/core/Chip';

const baseStyles = makeStyles((theme: ThemeType) => ({
  square: {
    borderRadius: '2px',
  },
  miniRoot: {
    fontSize: '11px',
    height: '15px',
  },
  miniLabel: {
    fontSize: '11px',
  },
  miniIcon: {
    fontSize: '13px',
    width: '12px',
    marginLeft: '3px',
    marginRight: '-6px',
  },
  miniDeleteIcon: {
    fontSize: '14px',
    marginLeft: '-4px',
    marginRight: '0',
    height: '19px',
  },
  success: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
    '&:focus': {
      backgroundColor: theme.palette.success.dark,
    },
  },
  info: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.info.contrastText,
    '&:focus': {
      backgroundColor: theme.palette.info.dark,
    },
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
    '&:focus': {
      backgroundColor: theme.palette.warning.dark,
    },
  },
  danger: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    '&:focus': {
      backgroundColor: theme.palette.error.dark,
    },
  },
}));

const iconStyles = makeStyles((theme: ThemeType) => ({
  success: {
    color: theme.palette.success.contrastText,
  },
  info: {
    color: theme.palette.info.contrastText,
  },
  warning: {
    color: theme.palette.warning.contrastText,
  },
  danger: {
    color: theme.palette.error.contrastText,
  },
}));

const deleteIconStyles = makeStyles((theme: ThemeType) => ({
  success: {
    color: theme.palette.success.tint,
    '&:hover': {
      color: theme.palette.success.contrastText,
    },
  },
  info: {
    color: theme.palette.info.tint,
    '&:hover': {
      color: theme.palette.info.contrastText,
    },
  },
  warning: {
    color: 'rgba(0,0,0,.7)',
    '&:hover': {
      color: 'rgba(0,0,0,.87)',
    },
  },
  danger: {
    color: theme.palette.error.tint,
    '&:hover': {
      color: theme.palette.error.contrastText,
    },
  },
}));

const outlinedStyles = makeStyles((theme: ThemeType) => ({
  success: {
    backgroundColor: 'transparent',
    borderColor: theme.palette.success.main,
    color: theme.palette.success.main,
  },
  info: {
    backgroundColor: 'transparent',
    borderColor: theme.palette.info.main,
    color: theme.palette.info.main,
  },
  warning: {
    backgroundColor: 'transparent',
    borderColor: theme.palette.warning.main,
    color: theme.palette.warning.main,
  },
  danger: {
    backgroundColor: 'transparent',
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
  },
}));

const outlinedDeletableStyles = makeStyles((theme: ThemeType) => ({
  success: {
    '&:focus': {
      backgroundColor: `${theme.palette.success.tint} !important`,
    },
  },
  info: {
    '&:focus': {
      backgroundColor: `${theme.palette.info.tint} !important`,
    },
  },
  warning: {
    '&:focus': {
      backgroundColor: `${theme.palette.warning.tint} !important`,
    },
  },
  danger: {
    '&:focus': {
      backgroundColor: `${theme.palette.error.tint} !important`,
    },
  },
}));

const outlinedDeleteIconStyles = makeStyles((theme: ThemeType) => ({
  success: {
    color: theme.palette.success.main,
    '&:hover': {
      color: theme.palette.success.dark,
    },
  },
  info: {
    color: theme.palette.info.main,
    '&:hover': {
      color: theme.palette.info.dark,
    },
  },
  warning: {
    color: theme.palette.warning.main,
    '&:hover': {
      color: theme.palette.warning.dark,
    },
  },
  danger: {
    color: theme.palette.error.main,
    '&:hover': {
      color: theme.palette.error.dark,
    },
  },
}));

export interface ChipProps extends Omit<MuiChipProps, 'color' | 'size'> {
  color?: 'default'|'primary'|'secondary'|'success'|'info'|'warning'|'danger',
  size?: 'mini'|'small'|'medium',
  square?: boolean,
}

export default function Chip(props : ChipProps) {
  const classes = baseStyles(props);
  const icons = iconStyles(props);
  const deleteIcons = deleteIconStyles(props);
  const outlined = outlinedStyles(props);
  const outlinedDeletable = outlinedDeletableStyles(props);
  const outlinedDeleteIcons = outlinedDeleteIconStyles(props);

  const {
    color = 'default', size, square = false, className, ...otherProps
  } = props;
  const newColor = (() => {
    switch (color) {
      case 'primary':
      case 'secondary':
        return color;

      default:
        return 'default';
    }
  })();

  const newSize = (() => {
    switch (size) {
      case 'small':
      case 'mini':
        return 'small';

      default:
        return 'medium';
    }
  })();

  return (
    <>
      <MuiChip
        color={newColor}
        size={newSize}
        classes={{
          root: clsx(className, square && classes.square, size === 'mini' && classes.miniRoot, classes[color]),
          labelSmall: clsx(size === 'mini' && classes.miniLabel),
          icon: clsx(props.variant === 'outlined' ? outlined[color] : icons[color]),
          iconSmall: clsx(size === 'mini' && classes.miniIcon),
          deletable: clsx(props.variant === 'outlined' ? outlinedDeletable[color] : undefined),
          deleteIcon: clsx(props.variant === 'outlined' ? outlinedDeleteIcons[color] : deleteIcons[color]),
          deleteIconSmall: clsx(size === 'mini' && classes.miniDeleteIcon),
          outlined: clsx(outlined[color]),
        }}
        {...otherProps}
      />
    </>
  );
}
Chip.defaultProps = {
  square: false,
};
