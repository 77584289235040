import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { IconButton as MuiIconButton } from '@material-ui/core';
import { IconButtonProps as MuiIconButtonProps } from '@material-ui/core/IconButton';
import clsx from 'clsx';
import { upperFirst } from 'lodash';
import { ThemeType } from 'types/ThemeType';
import { acidForwardRef } from '../../utilities/forwardRef';

const getHoverOpacity = (color) => `rgba(${color.replace(/r|g|b|\(|\)/g, '')}, 0.2)`;

const useStyles = makeStyles((theme: ThemeType) => ({
  square: {
    borderRadius: theme.shape.borderRadius,
  },
  primary: {
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: getHoverOpacity(theme.palette.primary.main),
    },
  },
  secondary: {
    color: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: getHoverOpacity(theme.palette.secondary.main),
    },
  },
  default: {
    color: theme.palette.default?.main,
    '&:hover': {
      backgroundColor: getHoverOpacity(theme.palette.default?.main),
    },
  },
  success: {
    color: theme.palette.success.main,
    '&:hover': {
      backgroundColor: getHoverOpacity(theme.palette.success.main),
    },
  },
  info: {
    color: theme.palette.info.main,
    '&:hover': {
      backgroundColor: getHoverOpacity(theme.palette.info.main),
    },
  },
  warning: {
    color: theme.palette.warning.main,
    '&:hover': {
      backgroundColor: getHoverOpacity(theme.palette.warning.main),
    },
  },
  danger: {
    color: theme.palette.error.main,
    '&:hover': {
      backgroundColor: getHoverOpacity(theme.palette.error.main),
    },
  },
  none: {
    color: theme.palette.grey[500],
    '&:hover': {
      backgroundColor: getHoverOpacity(theme.palette.grey[500]),
    },
  },
  containedPrimary: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  containedSecondary: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  containedDefault: {
    backgroundColor: theme.palette.default?.main,
    color: theme.palette.default?.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.default?.dark,
    },
  },
  containedSuccess: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
    },
  },
  containedInfo: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.info.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.info.dark,
    },
  },
  containedWarning: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.warning.dark,
    },
  },
  containedDanger: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
  containedNone: {
    backgroundColor: theme.palette.grey[500],
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.grey[700],
    },
  },
}));

export interface IconButtonProps extends Omit<MuiIconButtonProps, 'color'|'variant'> {
  color?: 'inherit' | 'default' | 'primary' | 'secondary' | 'success' | 'info' | 'warning' | 'danger' | 'none',
  square?: boolean,
  variant?: 'default' | 'contained',
  fontSize?: 'small'|'default'|'large',
}

const IconButton = acidForwardRef<HTMLButtonElement, IconButtonProps>((props, ref) => {
  const {
    children, className, color = 'none', square = false, variant = 'default', ...otherProps
  } = props;
  const classes = useStyles(props);
  return (
    <MuiIconButton
      ref={ref as React.RefObject<HTMLButtonElement>}
      {...otherProps}
      color="default"
      className={clsx(
        className,
        square ? classes.square : undefined,
        variant === 'contained'
          ? classes[`contained${upperFirst(String(color).toLowerCase())}`]
          : classes[String(color).toLowerCase()],
      )}
    >
      {children}
    </MuiIconButton>
  );
});

IconButton.defaultProps = {
  color: 'none',
  variant: 'default',
  square: false,
};

export default IconButton;
