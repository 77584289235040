/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { Select, MenuItem, InputLabel } from '@material-ui/core';
import TextField from 'components/TextField/TextField';
import { usePhoneView } from '../../hooks/usePhoneView';

export interface SelectOption {
  label: string;
  value: string;
}

interface SelectEvent {
  id?: string;
  name?: string;
  value: unknown;
}

interface SelectProps {
  default?: SelectOption;
  value?: string;
  options: SelectOption[];
  id?: string;
  unselectedValue?: string;
  disabled?: boolean;
  title?: string;
  variant?: 'filled' | 'standard' | 'outlined';
  className?: string;
  readOnly?: boolean;
  required?: boolean;
  onChange?: (event: React.ChangeEvent<any>) => void;
  error?: boolean;
  disableUnderline?: boolean;
  fromList?: boolean;
}

export default (props: SelectProps) => {
  const defVal: SelectOption = props.default || { value: props.value || '', label: '' };
  const phoneView = usePhoneView();

  if (!props.value) {
    for (let i = 0; i < props.options.length; i++) {
      if (props.options[i].value !== defVal.value) continue;
      defVal.label = props.options[i].label;
      break;
    }
  }

  const [color, setColor] = useState<string | undefined>(props.error ? 'rgb(212, 42, 42)' : undefined);

  useEffect(() => {
    setColor(props.error ? 'rgb(212, 42, 42)' : color);
  }, [props.error, color]);

  const [values, setValues] = useState({ select1: defVal.value });
  const handleBlur = () => {
    setColor(undefined);
  };

  useEffect(() => {
    setValues({ select1: defVal.value });
  }, [defVal.value]);

  const handleChange = (event: React.ChangeEvent<SelectEvent>) => {
    setColor('rgb(102,175,78)');
    setValues((oldValues: any) => ({
      ...oldValues,
      [event.target.name as string]: event.target.value,
    }));
    // eslint-disable-next-line no-param-reassign
    event.target.id = props.id;
    if (props.onChange) props.onChange(event);
  };

  let counter: number = 0;

  if (props.readOnly) {
    const val: SelectOption = props.options.find((item: SelectOption) => item.value === props.value) || ({} as SelectOption);
    return (
      <TextField
        id={props.id}
        label={props.title}
        error={props.error}
        value={val.label}
        required
        variant="standard"
        style={{ display: 'flex', flexWrap: 'wrap' }}
        readOnly={props.readOnly}
      />
    );
  }

  const showSmallFont = props.fromList && phoneView;

  return (
    <div>
      {props.title ? (
        <InputLabel
          style={{
            fontSize: '0.75rem',
            lineHeight: '1rem',
            color,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          }}
        >
          {props.title}
          {props.required ? ' *' : ''}
        </InputLabel>
      ) : null}
      <Select
        id={props.id}
        value={values.select1}
        onChange={handleChange}
        onBlur={handleBlur}
        style={{ width: '100%' }}
        className={`${props.className || ''} ${props.readOnly ? 'customSelectReadOnly' : ''}`}
        error={props.error}
        variant={props.variant}
        readOnly={props.readOnly}
        disabled={props.disabled}
        disableUnderline={props.disableUnderline}
        inputProps={{ name: 'select1' }}
        SelectDisplayProps={{ ...(showSmallFont && { style: { fontSize: 'small', whiteSpace: 'break-spaces' } }) }}
      >
        {props.options.map((f: SelectOption) => (
          <MenuItem
            key={`menu-item-${(counter++).toString()}`}
            value={f.value}
            style={{ color: f.value === props.unselectedValue ? '#999' : undefined, ...(showSmallFont && { fontSize: 'small', minHeight: 0 }) }}
          >
            {f.label}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};
