import { maestroSharedThemeOptions } from "./shared";

const maestroDarkThemeDefaultColors = {
  ...maestroSharedThemeOptions.palette.default,
  light: 'rgb(151, 228, 127)',
  main: 'rgb(122, 217, 95)',
  dark: 'rgb(102, 175, 78)',
  contrastText: 'rgba(0,0,0,222)',
};

export const maestroDarkThemeOptions = {
  ...maestroSharedThemeOptions,
  palette: {
    ...maestroSharedThemeOptions.palette,
    type: 'dark',
    default: maestroDarkThemeDefaultColors,
    primary: maestroDarkThemeDefaultColors,
    success: {
      ...maestroSharedThemeOptions.palette.success,
      light: 'rgb(151, 228, 127)',
      main: 'rgb(122, 217, 95)',
      dark: 'rgb(102, 175, 78)',
      contrastText: 'rgba(0,0,0,222)',
    },
  } as const,
} as const;
