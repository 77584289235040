import React from 'react';
import { BrightnessHigh, Brightness4 } from '@material-ui/icons';
import type { ThemeSwitchProps } from '../types/ThemeSwitchProps';

const DEFAULT_OFF_ICON = <Brightness4 color="primary" />;
const DEFAULT_ON_ICON = <BrightnessHigh color="primary" />;

/**
 * @returns the details of the _unselected_ theme as defined by `options`; that is, the
 * theme to switch to
 */
export const getThemeToSwitchToDetails = <TThemeName extends string>(
  options: ThemeSwitchProps<TThemeName>['themeOptions'],
  currentValue: ThemeSwitchProps<TThemeName>['selectedTheme'],
) => {
  switch (currentValue) {
    case options.on.value:
      return {
        ...options.off,
        icon: options.off.icon ?? DEFAULT_OFF_ICON,
      };
    case options.off.value:
      return {
        ...options.on,
        icon: options.on.icon ?? DEFAULT_ON_ICON,
      };
    default:
      throw new TypeError('Unexpected theme value');
  }
};
