import React from 'react';
import { InputAdornment } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import TextField, { type TextFieldProps } from 'components/TextField/TextField';

export enum UOM {
  miles = 'Miles',
  km = 'KM'
}

type TextFieldUOMOverriddenProps = 'onChange';

export interface TextFieldUOMProps extends Omit<TextFieldProps, TextFieldUOMOverriddenProps> {
  uom: UOM;
  value?: string;

  style?: React.CSSProperties;
  onChange?: (newValue: string) => void;
  decimals?: number;
  error?: boolean;
  min?: number;
  max?: number;
  step?: number;
}

const roundUOM = (v: number, decimals: number) => {
  const mult = Math.pow(10, decimals);
  return Math.round(v * mult) / mult;
};

const MILES_PER_METER = 0.000621371;

const convertMetersToMiles = (meters: number, decimals: number) => {
  return roundUOM(meters * MILES_PER_METER, decimals);
};

const convertMilesToMeters = (miles: number, decimals: number) => {
  return roundUOM(miles / MILES_PER_METER, decimals);
};

const METERS_PER_KM = 1000;

const convertMetersToKM = (meters: number, decimals: number) => {
  return roundUOM(meters / METERS_PER_KM, decimals);
}

const convertKMToMeters = (km: number, decimals: number) => {
  return roundUOM(km * METERS_PER_KM, decimals);
}

const convertToUOM = (v: string, decimals: number, uom: UOM) => {
  if (!v) return '';

  const value = parseFloat(v);
  if (uom === UOM.miles) {
    return convertMetersToMiles(value, decimals);
  }

  return convertMetersToKM(value, decimals);
};

const convertFromUOM = (v: string | undefined, decimals: number, uom: UOM) => {
  if (!v) return '';

  const value = parseFloat(v);
  if (uom === UOM.miles) {
    return convertMilesToMeters(value, decimals);
  }

  return convertKMToMeters(value, decimals);
};


export default function TextFieldUOM(props: TextFieldUOMProps): JSX.Element {
  const {
    label,
    value: propValue,
    variant,
    readOnly,
    style,
    onChange,
    decimals = 2,
    error,
    min,
    max,
    step,
    uom,
    helperText,
    InputProps,
    inputProps,
    ...otherProps
  } = props;

  const { t } = useTranslation();

  const value = convertToUOM(propValue || '', decimals, uom).toString();

  return (
    <TextField
      label={label}
      variant={variant}
      style={style}
      error={error}
      onChange={(e) => {
        if (!onChange) return;

        const newValue = convertFromUOM(e.target.value, decimals, uom).toString();
        onChange(newValue);
      }}
      value={value}
      InputProps={{
        ...InputProps,
        type: 'number',
        endAdornment: <InputAdornment position="end">{t(uom)}</InputAdornment>,
        readOnly,
      }}
      // the use of the two "inputProps" props is intended -- allow duplicates
      // eslint-disable-next-line
      inputProps={{
        ...inputProps,
        min,
        max,
        step,
      }}
      helperText={helperText}
      {...otherProps}
    />
  );
}
