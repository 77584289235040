import {
  type PaletteType,
  type Theme,
} from '@material-ui/core';
import {
  createStyles,
  fade,
  lighten,
  makeStyles,
} from '@material-ui/core/styles'
import type { CreateCSSProperties } from '@material-ui/styles';

const DEFAULT_BASE_CODEMIRROR_HEIGHT = '100%';
const TOOLBAR_HEIGHT = '48px';

const getContainerHeight = (isToolbarVisible?: boolean) => {
  if (isToolbarVisible) {
    return `calc(${DEFAULT_BASE_CODEMIRROR_HEIGHT} - ${TOOLBAR_HEIGHT})`;
  }

  return DEFAULT_BASE_CODEMIRROR_HEIGHT;
};

export interface UseMarkdownEditorStylesProps {
  isToolbarVisible?: boolean;
}

export const useMarkdownEditorStyles = makeStyles<Theme, UseMarkdownEditorStylesProps>((theme: Theme) => {
  const foregroundColor: string = theme.palette.text.primary;
  const backgroundColor: string = theme.palette.background.default;

  const toolbarActiveHoverStyles: Record<PaletteType, CreateCSSProperties> = {
    dark: {
      backgroundColor: lighten(theme.palette.background.default, 0.2),
      borderColor: lighten(theme.palette.background.default, 0.3),
    },
    light: {},
  };

  const toolbarIconStyles: Record<PaletteType, CreateCSSProperties> = {
    dark: {
      color: fade(theme.palette.text.primary, 0.87),
    },
    light: {},
  };

  const toolbarSeparatorStyles: Record<PaletteType, CreateCSSProperties> = {
    dark: {
      borderRight: 'none',
    },
    light: {},
  };

  return createStyles({
    editor: (props: UseMarkdownEditorStylesProps) => {
      const {
        isToolbarVisible,
      } = props;

      return {
        height: '100%',

        '& .EasyMDEContainer': {
          height: getContainerHeight(isToolbarVisible),

          '& .CodeMirror': {
            border: 'none',
          },
          '& div.editor-preview': {
            backgroundColor,
          },
          '& div.CodeMirror-wrap': {
            color: foregroundColor,
            backgroundColor,
            borderRadius: 0,
            height: '100%',
          },
          '& button>i': toolbarIconStyles[theme.palette.type],
          '& div.CodeMirror-cursor': {
            borderColor: foregroundColor,
          },
          '& .editor-toolbar': {
            borderRadius: 0,
            border: 'none',

            '&.fullscreen': {
              backgroundColor,
            },

            '& button.active, button:hover': toolbarActiveHoverStyles[theme.palette.type],
            '&>i.separator': toolbarSeparatorStyles[theme.palette.type],
          },
        },
      };
    },
  });
});
