import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Autocomplete as MuiAutocomplete } from '@material-ui/lab';
import { Checkbox } from '@material-ui/core';
import { CheckBoxOutlineBlank as CheckboxOutlineBlankIcon, CheckBox as CheckBoxIcon } from '@material-ui/icons';
import clsx from 'clsx';
import type { ThemeType } from 'types/ThemeType';
import type { AutocompleteProps as MuiAutocompleteProps } from '@material-ui/lab/Autocomplete';
import type { UseAutocompleteMultipleProps, UseAutocompleteSingleProps } from '@material-ui/lab/useAutocomplete';
import type { OptionType } from 'types/Types';
import TextField from 'components/TextField/TextField';

const baseStyles = makeStyles((theme: ThemeType) => ({
  checkbox: {
    padding: theme.spacing(0.5),
    width: 16,
    height: 16,
    marginRight: theme.spacing(2),
  },
}));

export interface AutoCompleteCommonProps {
  variant?: 'default' | 'standard' | 'outlined' | 'filled',
  margin?: 'none' | 'dense' | 'normal',
  label?: string,
  helperText?: string,
  error?: boolean,
  readOnly?: boolean,
  required?: boolean,
  fullWidth?: boolean,
  disabled?: boolean,
  checkboxes?: boolean,
}
export interface AutocompleteSingleProps extends
  AutoCompleteCommonProps,
  Omit<MuiAutocompleteProps<OptionType|string>, 'renderInput' | 'renderOption' | 'value'>,
  UseAutocompleteSingleProps<OptionType | string>
{}

export interface AutocompleteMultipleProps extends
  AutoCompleteCommonProps,
  Omit<MuiAutocompleteProps<OptionType|string>, 'renderInput' | 'renderOption' | 'value'>,
  Omit<UseAutocompleteMultipleProps<OptionType | string>, 'multiple'>
{}

export type AutocompleteProps = AutocompleteMultipleProps;

/**
 * An acid-styled Autocomplete preset for common Autocomplete use-cases in MSP projects.
 *
 * This component depends upon `@material-ui/lab@4.0.0-alpha.43` (see peerDependencies).
 * Other versions of `@material-ui/lab` __may or may not__ function as expected.
 *
 * See the [Material-UI documentation](https://v4.mui.com/components/about-the-lab/#about-the-lab)
 * for more details about the volatility of `@material-ui/lab` versions.
 */
export default function Autocomplete(props: AutocompleteProps) {
  const styles = baseStyles(props);
  const {
    className,
    variant = 'standard',
    label,
    error,
    helperText,
    margin = 'dense',
    disabled,
    readOnly,
    fullWidth = true,
    required,
    checkboxes,
    ...otherProps
  } = props;

  return (
    <MuiAutocomplete
      {...otherProps}
      multiple
      className={clsx(className)}
      disabled={disabled || readOnly}
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.label)}
      renderInput={(params) => (
        <TextField
          {...params}
          variant={variant}
          label={label}
          helperText={helperText}
          error={error}
          margin={margin}
          disabled={disabled || readOnly}
          readOnly={readOnly}
          required={required}
          fullWidth={fullWidth}
        />
      )}
      renderOption={checkboxes ? (option, { selected }) => (
        <>
          <Checkbox
            className={clsx(styles.checkbox)}
            disableRipple
            color="primary"
            icon={<CheckboxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            checked={selected}
          />
          <>{typeof option === 'string' ? option : option.label}</>
        </>
      ) : undefined}
    />
  );
}
Autocomplete.defaultProps = {
  options: [],
  getOptionLabel: (option) => option.label,
  variant: 'standard',
  margin: 'dense',
  fullWidth: true,
};
