import React from 'react';
import { Link, LinkProps } from '@material-ui/core';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import ArrowDropdown from '../ArrowDropdown/ArrowDropdown';
import { useCollapseControlStyles } from './useCollapseControlStyles';

const DEFAULT_COLLAPSE_TEXT = 'Minimize';
const DEFAULT_EXPAND_TEXT = 'Expand';

export interface CollapseControlProps {
  collapsed: boolean | undefined;
  collapseText: string | undefined;
  expandText: string | undefined;
  onExpandCollapse: LinkProps['onClick'];
}

export const CollapseControl = (props: CollapseControlProps) => {
  const classes = useCollapseControlStyles();
  const { t } = useTranslation();
  const {
    collapsed,
    collapseText = t(DEFAULT_COLLAPSE_TEXT),
    expandText = t(DEFAULT_EXPAND_TEXT),
    onExpandCollapse,
  } = props;

  return (
  // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <Link className={classes.expandTextContainer} onClick={onExpandCollapse}>
      <div
        className={clsx(classes.expandText, 'hoverTransformer')}
      >
        {collapsed ? expandText : collapseText}
      </div>
      <ArrowDropdown open={!collapsed} />
    </Link>
  );
};
