/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { useTheme } from '@material-ui/core/styles';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { EventAvailableTwoTone } from '@material-ui/icons';
import {
  Button as MuiButton, Divider, Paper, useMediaQuery,
} from '@material-ui/core';
import clsx from 'clsx';
import type { ThemeType } from 'types/ThemeType';
import Notification, { type NotificationType } from 'components/Notification/Notification';
import { useTranslation } from 'react-i18next';

export interface NotificationListProps {
  /** ClassName to apply to the component. */
  className?: string,
  /** The notification to render inside the component. */
  notifications?: NotificationType[],
  /** The callback function for when the read all has been clicked */
  onUpdate?: (item: NotificationType, action: string, value: boolean) => void,
  /** The callback function for when the read all has been clicked */
  onReadAllClick?: (items: NotificationType[]) => void,
  /** The callback function for when the delete all button has been clicked */
  onDeleteAllClick?: (items: NotificationType[]) => void,
  /** The callback function for when the interval has changed */
  onIntervalChange?: (value: number) => void,
}

const useStyles = makeStyles((theme: ThemeType) => ({
  root: () => ({
    maxWidth: 400,
    backgroundColor: theme.palette.background.default,
    borderRadius: '8px',
  }),
  spacing: {
    marginRight: theme.spacing(1),
  },
  gap: {
    marginLeft: theme.spacing(3),
  },
  header: {
    display: 'flex',
    fontSize: '.8rem',
    alignItems: 'center',
    padding: theme.spacing(1),
  },
  count: {
    fontSize: '.8rem',
    color: theme.palette.text.disabled,
    lineHeight: 2,
  },
  slider: {
    position: 'relative',
    top: 1,
    width: 100,
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
  },
  grow: {
    flexGrow: 1,
  },
  scroll: {
    maxHeight: 300,
    overflow: 'auto',
  },
  empty: {
    textAlign: 'center',
    color: theme.palette.text.disabled,
  },
  info: {
    padding: '15px',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  bottom: {
    padding: '8px',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
    position: 'relative',
    top: '-2px',
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
  },
  sliderContainer: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '12px',
  },
  tooltip: {
    backgroundColor: theme.palette.grey[600],
    color: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    fontSize: 12,
  },
  link: {
    background: 'none',
    border: 'none',
    padding: '0',
    font: 'inherit',
    cursor: 'pointer',
    outline: 'inherit',
    textTransform: 'none',
    color: theme.palette.primary.dark,
    '&:hover': {
      background: 'none !important',
      textDecoration: 'none',
      color: theme.palette.primary.main,
    },
  },
  item: {
    '&-enter-active': {
      opacity: 1,
      transition: 'opacity 300ms ease-in, margin 300ms ease-out',
    },
    '&-exit-active': {
      opacity: 0,
      transition: 'opacity 300ms ease-out, margin 300ms ease-in',
    },
  },
}));

const duration = 300;

const onEnter = (node: any) => {
  node.style.marginTop = `-${node.offsetHeight}px`;
  node.style.marginBottom = '0px';
};
const onEntering = (node) => {
  node.style.marginTop = '';
  node.style.marginBottom = '';
};
const onExit = (node) => {
  node.style.marginTop = '';
  node.style.marginBottom = '';
};
const onExiting = (node) => {
  node.style.marginTop = `-${node.offsetHeight}px`;
  node.style.marginBottom = '0px';
};

export default function NotificationList(props: NotificationListProps) {
  const classes = useStyles(props);
  const { t } = useTranslation();
  const theme = useTheme<ThemeType>();
  const phoneView = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });

  const {
    className,
    notifications = [],
    onUpdate,
    onReadAllClick,
    onDeleteAllClick,
    onIntervalChange,
    ...otherProps
  } = props;

  const handleReadAllClick = () => {
    if (onReadAllClick) {
      onReadAllClick(notifications);
    }
  };

  const handleDeleteAllClick = () => {
    if (onDeleteAllClick) {
      onDeleteAllClick(notifications);
    }
  };

  const visibleCount = notifications.filter((notification) => !notification.deletedAt);

  return (
    // eslint-disable-next-line jsx-a11y/aria-role
    <Paper
      role="notificationList"
      className={clsx(className, classes.root)}
      style={{ width: phoneView ? 'calc(100vw + 10px)' : 'auto' }}
      elevation={0}
      {...otherProps}
    >
      <div className={classes.info}>
        <div>
          <b>Notifications</b>
        </div>
      </div>
      <Divider />
      <div className={clsx(classes.scroll)}>
        <TransitionGroup component={null}>
          {visibleCount.map((notification) => (
            <CSSTransition
              key={notification._id}
              timeout={duration}
              classNames={classes.item}
              onEnter={onEnter}
              onEntering={onEntering}
              onExit={onExit}
              onExiting={onExiting}
            >
              <Notification
                key={notification._id}
                notification={notification}
                onUpdate={onUpdate}
              />
            </CSSTransition>
          ))}
        </TransitionGroup>

        {visibleCount.length === 0 && (
          <div
            style={{ width: phoneView ? '100%' : '400px' }}
            className={clsx(classes.empty)}
          >
            <div>
              <EventAvailableTwoTone
                style={{
                  width: '3em',
                  height: '3em',
                  opacity: '.3',
                  padding: '8px',
                }}
              />
            </div>
            <div
              style={{
                padding: '0 10px 14px 10px',
                fontWeight: 'bold',
                color: '#b7b7b7',
                fontSize: '14px',
              }}
            >
              {t('No new notifications')}
            </div>
          </div>
        )}
      </div>
      {visibleCount.length > 0 && (
        <>
          <div className={clsx(classes.bottom)}>
            <MuiButton
              color="inherit"
              variant="text"
              className={classes.link}
              onClick={handleDeleteAllClick}
            >
              {t('Clear all')}
            </MuiButton>
            <MuiButton
              color="inherit"
              variant="text"
              className={classes.link}
              onClick={handleReadAllClick}
            >
              {t('Mark all as read')}
            </MuiButton>
          </div>
        </>
      )}
    </Paper>
  );
}
