import { ThemeSwitch } from '../ThemeSwitch';
import { withThemeOptions } from './withThemeOptions';

const MICHELIN_THEME_OPTIONS = {
  on: {
    value: 'michelinLight',
    switchToLabel: 'Switch to Light Theme',
  },
  off: {
    value: 'michelinDark',
    switchToLabel: 'Switch to Dark Theme',
  },
} as const;

/**
 * ThemeSwitch with Michelin Light and Dark themes preset as theme options
 */
const MichelinThemeSwitch = withThemeOptions({
  themeOptions: MICHELIN_THEME_OPTIONS,
})(ThemeSwitch);

type MichelinThemeSwitchProps = Parameters<typeof MichelinThemeSwitch>[0];

export {
  MichelinThemeSwitch,
  type MichelinThemeSwitchProps,
};
