import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Tabs as MuiTabs } from '@material-ui/core';
import clsx from 'clsx';
import type { ThemeType } from 'types/ThemeType';
import type { TabsProps as MuiTabsProps } from '@material-ui/core/Tabs';
import { acidForwardRef } from '../../utilities/forwardRef';

const colorStyles = makeStyles((theme: ThemeType) => ({
  default: () => ({
    color: theme.palette.text.primary,
  }),
  primary: () => ({
    color: theme.palette.primary.main,
  }),
  secondary: () => ({
    color: theme.palette.secondary.main,
  }),
  success: () => ({
    color: theme.palette.success.main,
  }),
  info: () => ({
    color: theme.palette.info.main,
  }),
  warning: () => ({
    color: theme.palette.warning.main,
  }),
  danger: () => ({
    color: theme.palette.error.main,
  }),
}));

const indicatorStyles = makeStyles((theme: ThemeType) => ({
  default: () => ({
    backgroundColor: theme.palette.text.primary,
  }),
  primary: () => ({
    backgroundColor: theme.palette.primary.main,
  }),
  secondary: () => ({
    backgroundColor: theme.palette.secondary.main,
  }),
  success: () => ({
    backgroundColor: theme.palette.success.main,
  }),
  info: () => ({
    backgroundColor: theme.palette.info.main,
  }),
  warning: () => ({
    backgroundColor: theme.palette.warning.main,
  }),
  danger: () => ({
    backgroundColor: theme.palette.error.main,
  }),
}));

export interface TabsProps extends Omit<MuiTabsProps, 'onChange' | 'indicatorColor' | 'textColor'> {
  indicatorColor?: 'default' | 'primary' | 'secondary' | 'success' | 'info' | 'warning' | 'danger',
  textColor?: 'inherit' | 'default' | 'primary' | 'secondary' | 'success' | 'info' | 'warning' | 'danger',
  onChange?: (event: React.ChangeEvent<{}>, value: any) => void,
  width?: string,
}

const Tabs = acidForwardRef<HTMLButtonElement, TabsProps>((props, ref) => {
  const colors = colorStyles();
  const indicators = indicatorStyles();
  const {
    children, className, indicatorColor = 'primary', textColor = 'inherit', ...otherProps
  } = props;

  return (
    <MuiTabs
      className={clsx(className, colors[textColor])}
      classes={{ indicator: indicators[indicatorColor] }}
      {...otherProps}
      ref={ref as React.RefObject<HTMLButtonElement>}
    >
      {children}
    </MuiTabs>
  );
});

Tabs.defaultProps = {
  indicatorColor: 'primary',
  textColor: 'inherit',
};

export default Tabs;
