import React from 'react';
import {
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  List,
  type Theme,
  Fade,
} from '@material-ui/core';
import {
  makeStyles,
} from '@material-ui/core/styles';
import { ExpandLess, ExpandMore, ArrowRight } from '@material-ui/icons';
import Tooltip from 'components/Tooltip/Tooltip';
import { noop } from 'lodash';
import clsx from 'clsx';
import type { ItemType } from './Sidebar';

export interface ICollapsibleItemProps {
  item: ItemType;
  sidebarOpen: boolean;
  classes: Record<string, string>;
  nestedDepth?: number;
  className?: string;
  disabled?: boolean;
}

interface ICollapsibleItemStylesProps {
  nestedDepth: number;
}

const useCollapsibleItemStyles = makeStyles<Theme, ICollapsibleItemStylesProps>((theme: Theme) => ({
  nested: ({ nestedDepth }) => ({
    paddingLeft: theme.spacing(4 + 2 * nestedDepth),
    transition:
      'padding 225ms cubic-bezier(0.4, 0, 0.6, 1) 0s',
  }),
  arrow: {
    color: theme.palette.grey[400],
    transition: 'opacity 225ms linear, width 225ms cubic-bezier(0.4, 0, 0.6, 1)',
  },
  closed: {
    transform: 'rotate(90deg)',
  },
  nestedGroupOpen: {
    boxShadow: `inset 2px 0 1px ${theme.palette.primary.main}`,
  },
}));

export const CollapsibleItem = (props: ICollapsibleItemProps) => {
  const {
    item, classes, sidebarOpen, nestedDepth, className, disabled,
  } = props;
  const {
    label,
    icon,
    onClick,
    selected,
    subitems,
    open,
  } = item;

  const collapsibleItemClasses = useCollapsibleItemStyles({ nestedDepth: sidebarOpen ? nestedDepth || 0 : -1 });

  const handleCollapsibleItemClick = (event?: React.MouseEvent<Element, MouseEvent>) => {
    if (onClick) {
      onClick(event);
    }
  };

  return (
    <>
      <ListItem
        selected={selected}
        button
        onClick={handleCollapsibleItemClick}
        style={{ height: 40 }}
        className={className}
        disabled={disabled}
      >
        <Tooltip title={!open && !!label ? label : ''} placement="right" arrow>
          <>
            <Fade in={!sidebarOpen} timeout={500}>
              <ArrowRight
                className={clsx(collapsibleItemClasses.arrow, { [collapsibleItemClasses.closed]: open })}
                style={{
                  position: 'absolute',
                  left: 2,
                  fontSize: 16,
                }}
              />
            </Fade>
            <ListItemIcon style={{ minWidth: 30 }}>
              <>{icon}</>
            </ListItemIcon>
          </>
        </Tooltip>
        <ListItemText
          className={classes.label}
          primary={label}
          style={{ marginLeft: 8 }}
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding dense className={clsx({ [collapsibleItemClasses.nestedGroupOpen]: open && !sidebarOpen })}>
          {subitems?.map((item, i) => (
            <Item
              key={`${item.label}_${i}`}
              item={item}
              open={sidebarOpen}
              classes={classes}
              className={collapsibleItemClasses.nested}
              nestedDepth={nestedDepth ? nestedDepth + 1 : 1}
            />
          ))}
        </List>
      </Collapse>
    </>
  );
};

export interface IItemProps {
  item: ItemType;
  classes: Record<string, string>;
  open: boolean;
  closeSidebar?: () => void;
  className?: string;
  nestedDepth?: number;
  disabled?: boolean;
}

export function Item(props: IItemProps) {
  const {
    item, classes, className, nestedDepth, open, disabled, closeSidebar = noop,
  } = props;
  const {
    label, icon, onClick = noop, selected, divider, heading, subitems,
  } = item;

  if (divider) return <Divider />;
  if (heading) {
    return (
      <ListItem disabled={disabled} className={classes.heading}>
        <ListItemText
          primary={label}
          style={{
            opacity: open ? 1 : 0,
            transition:
              'opacity 225ms linear, width 225ms cubic-bezier(0.4, 0, 0.6, 1)',
          }}
        />
      </ListItem>
    );
  }

  if (subitems) {
    return (
      <CollapsibleItem
        item={item}
        sidebarOpen={open}
        classes={classes}
        nestedDepth={nestedDepth}
        className={className}
        disabled={disabled}
      />
    );
  }

  const onItemClick = () => {
    onClick();
    closeSidebar();
  };

  return (
    <ListItem
      button
      onClick={onItemClick}
      className={`${className} ${clsx(classes.itemContainer, { [`${classes.itemContainer}--selected`]: selected })}`}
      disabled={disabled}
    >
      <Tooltip title={!open && !!label ? label : ''} placement="right" arrow>
        <ListItemIcon className={clsx(classes.icon, { [`${classes.icon}--selected`]: selected })} style={{ minWidth: 30 }}>
          <>{icon}</>
        </ListItemIcon>
      </Tooltip>
      <ListItemText
        className={clsx(classes.label, { [`${classes.label}--selected`]: selected })}
        primary={label}
        style={{ marginLeft: 8, whiteSpace: 'normal', minWidth: 178 - 16 * (nestedDepth || 0) }}
      />
    </ListItem>
  );
}
