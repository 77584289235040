/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  TextField as MuiTextField,
  type TextFieldProps as MuiTextFieldProps,
  FormControl as MuiFormControl,
  FormHelperText as MuiFormHelperText,
  Input as MuiInput,
  InputAdornment as MuiInputAdornment,
} from '@material-ui/core';
import { CheckCircle as CheckCircleIcon, WarningRounded as WarningRoundedIcon, Error as ErrorIcon } from '@material-ui/icons';
import clsx from 'clsx';
import type { InputProps as MuiInputProps } from '@material-ui/core/Input';
import type { ThemeType } from 'types/ThemeType';
import type { OptionType } from 'types/Types';
import Select from 'components/Select/Select';

interface LocalTextFieldProps {
  fullWidth?: boolean,
  helperText?: string | null | false,
  InputProps?: Partial<MuiInputProps>,
  inputProps?: MuiInputProps['inputProps'],
  label?: string,
  margin?: 'none'|'dense'|'normal',
  maxLength?: number,
  required?: boolean,
  readOnly?: boolean,
  select?: boolean,
  SelectProps?: {
    options?: OptionType[],
  },
  variant?: 'default'|'standard'|'outlined'|'filled',
  statusIcon?: 'none'|'success'|'warning'|'error',
  disabled?: boolean,
  value?: OptionType|string,
  flex?: number,
  id?: string,
  error?: boolean,
  className?: string,
  children?: React.ReactNode,
  shrinkLabel?: boolean,
}

export type TextFieldProps = LocalTextFieldProps & Omit<MuiTextFieldProps, 'variant'|'SelectProps'|'helperText'>;

const useStyles = makeStyles((theme: ThemeType) => ({
  root: {
    margin: theme.spacing(1),
  },
  fullWidth: {
    width: '100%',
  },
  formControl: (props: TextFieldProps) => ({
    width: '100%',
    borderRadius: 0,
    border: `1px solid ${props.error ? theme.palette.error.main : theme.palette.grey[400]}`,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(0.5),
    '&:hover': {
      border: `1px solid ${props.error ? theme.palette.error.dark : theme.palette.grey[600]}`,
      zIndex: 1,
    },
  }),
  label: (props: TextFieldProps) => ({
    fontSize: '.8em',
    color: props.error ? theme.palette.error.main : theme.palette.text.secondary,
  }),
  labelWrap: () => ({
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
  }),
  input: {
    fontSize: '.8rem',
    marginTop: '0 !important',
    '&>input': {
      padding: 0,
    },
    '&>div>div': {
      paddingTop: '0',
      paddingBottom: '0',
    },
    '&>div>select': {
      paddingTop: '0',
      paddingBottom: '0',
    },
  },
  inputAutofill: {
    '&:-webkit-autofill': {
      WebkitBoxShadow: `0 0 0 1000px ${theme.palette.background.default} inset`,
      WebkitTextFillColor: theme.palette.text.primary
    }
  },
  multiline: {
    padding: '0 !important',
  },
  helperText: {
    color: theme.palette.text.hint,
    marginTop: theme.spacing(0.25),
    marginLeft: theme.spacing(0.5),
    marginBottom: 0,
    marginRight: theme.spacing(0.5),
  },
  readOnly: {
    color: theme.palette.text.primary,
  },
  success: (props: any) => ({
    color: theme.palette.success.main,
    opacity: (props.disabled || props.readOnly) && '50%',
  }),
  warning: (props: any) => ({
    color: theme.palette.warning.main,
    opacity: (props.disabled || props.readOnly) && '50%',
  }),
  error: (props: any) => ({
    color: theme.palette.error.main,
    opacity: (props.disabled || props.readOnly) && '50%',
  }),
  notchedOutline: {
    borderColor: 'transparent !important',
  },
  defaultAdornment: {
    position: 'relative',
    top: '-.5rem',
  },
}));

/**
## EndAdornments and StatusIcons
The **TextField** supports the standard `endAdornment` property that is passed in the `InputProps`. **TextFields** also have the ability to display a `statusIcon` which is displayed as an end adorment. If both are present, the `statusIcon` will override the `endAdornment`.
 */
export default function TextField(props: TextFieldProps) {
  const {
    variant = 'standard',
    label,
    helperText,
    required = false,
    disabled = false,
    fullWidth = true,
    margin,
    inputProps,
    InputProps,
    statusIcon = 'none',
    select,
    SelectProps,
    className,
    maxLength = -1,
    shrinkLabel,
    ...otherProps
  } = props;
  const { readOnly } = InputProps || { readOnly: props.readOnly || false };
  const classes = useStyles(props);
  const [focused, setFocused] = React.useState(false);

  const onFocus = () => {
    setFocused(true);
  };

  const onBlur = () => {
    setFocused(false);
  };

  const getStatusIcon = (statusIcon) => {
    switch (statusIcon) {
      case 'success': return <CheckCircleIcon className={classes.success} />;
      case 'warning': return <WarningRoundedIcon className={classes.warning} />;
      case 'error': return <ErrorIcon className={classes.error} />;

      default: return undefined;
    }
  };

  const icon = getStatusIcon(statusIcon);

  if (select) {
    const {
      // eslint-disable-next-line no-shadow
      SelectProps, select, statusIcon, margin, helperText, shrinkLabel, onChange, onKeyUp, onKeyDown, ...otherProps
    } = props;

    return (
      <Select
        {...SelectProps}
        {...otherProps}
        helperText={helperText || undefined}
        onChange={onChange as any}
        onKeyUp={onKeyUp as any}
        onKeyDown={onKeyDown as any}
        disabled={disabled || readOnly}
        readOnly={readOnly}
      />
    );
  }
  if (variant !== 'default') {
    // This is a patch for a MUI bug
    // See: https://github.com/mui-org/material-ui/issues/15697
    const variantFixedProps = (() => {
      let tsVariant;
      switch (variant) {
        case 'outlined': {
          tsVariant = { variant: variant as 'outlined' };
          break;
        }
        case 'filled': {
          tsVariant = { variant: 'filled' as 'filled' };
          break;
        }
        case 'standard':
        default: {
          tsVariant = { variant: 'standard' as 'standard' };
          break;
        }
      }
      const p = { ...props };
      delete p.variant;
      return { ...p, ...tsVariant };
    })();
    const {
      // eslint-disable-next-line no-shadow
      statusIcon = 'none', inputProps, InputProps, maxLength, shrinkLabel, ...otherFixedProps
    } = variantFixedProps;
    return (
      <MuiTextField
        {...otherFixedProps}
        inputProps={{
          className: classes.inputAutofill,
          maxLength,
          ...inputProps,
        }}
        disabled={disabled || readOnly}
        InputLabelProps={{ classes: { root: clsx(classes.labelWrap) }, shrink: shrinkLabel }}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        InputProps={{
          ...InputProps,
          classes: { disabled: clsx(readOnly && classes.readOnly) },
          readOnly,
          endAdornment: (statusIcon && statusIcon !== 'none')
            ? <MuiInputAdornment position="end"><>{icon}</></MuiInputAdornment>
            : (InputProps && InputProps.endAdornment),
        }}
      />
    );
  }

  return (
    <div className={clsx(classes.root, className, fullWidth && classes.fullWidth)}>
      <MuiFormControl
        disabled={disabled || readOnly}
        className={clsx(classes.formControl)}
      >
        {label && (
        <label htmlFor={props.id} className={classes.label}>
          {label}
          {required && ' *'}
        </label>
        )}
        <MuiInput
          {...otherProps}
          {...InputProps}
          onKeyDown={props.onKeyDown as any}
          onKeyUp={props.onKeyUp as any}
          inputProps={{
            className: classes.inputAutofill,
            maxLength,
            ...inputProps,
          }}
          disableUnderline
          disabled={disabled || readOnly}
          endAdornment={(statusIcon && statusIcon !== 'none')
            ? <MuiInputAdornment position="end"><>{icon}</></MuiInputAdornment>
            : (InputProps && InputProps.endAdornment)}
          classes={{
            root: clsx(classes.input),
            multiline: clsx(classes.multiline),
            disabled: clsx(readOnly && classes.readOnly),
          }}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      </MuiFormControl>
      {helperText && (
      <MuiFormHelperText
        className={clsx(classes.helperText, props.error && classes.error)}
      >
        {helperText}
      </MuiFormHelperText>
      )}
    </div>
  );
}

TextField.defaultProps = {
  fullWidth: true,
  maxLength: -1,
  required: false,
  readOnly: false,
  variant: 'standard',
  statusIcon: 'none',
};
