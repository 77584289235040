import { LOGOS } from './logoPaths';
import type { MichelinLogoProps } from './MichelinLogo';

const getMichelinLogoPath = (props: MichelinLogoProps) => {
  const logo = LOGOS.commercial[props.direction].whiteBg;

  return logo;
};

export {
  getMichelinLogoPath,
};
