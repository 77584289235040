import { makeStyles } from '@material-ui/core/styles';

const useUserRoleDisplayStyles = makeStyles(() => {
  return {
    text: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  };
});

export {
  useUserRoleDisplayStyles,
};
