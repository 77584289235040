import React, { ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { LegalDocs } from 'types/Types';
import type { ItemType, VersionType } from '../Sidebar/Sidebar';
import Legal from '../Sidebar/Legals';
import Copyrights from '../Sidebar/Copyrights';
import FooterExternals from './FooterExternals';

export interface FooterProps {
  application?: string;
  className?: string;
  versions?: VersionType[];
  versionsLinkClick?: () => void;
  showLegalInformation?: boolean;
  showCopyright?: boolean;
  footerExternals?: ItemType[];
  termsOfUsePdf?: string;
  privacyPolicyPdf?: string;
  termsOfServicePdf?: string;
  onLegalLinkClick?: (link: LegalDocs) => void;
}

export const FOOTER_HEIGHT = 40;

function Footer({
  application,
  className,
  versions = [],
  showLegalInformation,
  showCopyright,
  footerExternals,
  termsOfUsePdf,
  termsOfServicePdf,
  privacyPolicyPdf,
  onLegalLinkClick,
  versionsLinkClick,
}: FooterProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const phoneView = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const root = document.documentElement;
    root.style.setProperty('--bottom-spacing', '48px');

    return () => {
      root.style.setProperty('--bottom-spacing', '24px');
    };
  }, []);

  return (
    <>
      <div style={{ height: 40 }} />
      <div
        className={className}
        style={{
          bottom: 0,
          left: 0,
          position: 'fixed',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          backgroundColor: theme.palette.background.paper,
          width: '100%',
          minHeight: 40,
          height: 40,
          borderTop: 'solid 1px #99999930',
          padding: '0px 16px',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {versions.length > 0 && (
            <div
              style={{
                fontSize: '0.7em',
                marginLeft: '16px',
              }}
            >
              {versions.map((v, idx) => {
                const versions = (
                  <span key={idx} style={{ marginLeft: idx > 0 ? '16px' : 0 }}>
                    {t(`${v.application}: {{version}}`, {
                      version: (v.version || '').split('-').pop() || '',
                    })}
                  </span>
                );
                if (versionsLinkClick) {
                  return (
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <Link style={{ cursor: 'pointer' }} onClick={versionsLinkClick}>{versions}</Link>
                  );
                }
                return versions;
              })}
            </div>
          )}
          {showLegalInformation && (
            <div
              style={{
                fontSize: '0.7em',
                marginLeft: '16px',
              }}
            >
              <Legal
                open
                termsOfUsePdf={termsOfUsePdf}
                termsOfServicePdf={termsOfServicePdf}
                privacyPolicyPdf={privacyPolicyPdf}
                onLegalLinkClick={(
                  link: 'termsOfUse' | 'termsOfService' | 'privacyPolicy',
                ) => {
                  if (onLegalLinkClick) onLegalLinkClick(link);
                }}
              />
            </div>
          )}
          {showCopyright && (
            <div
              style={{
                fontSize: '0.7em',
                marginLeft: '16px',
              }}
            >
              <Copyrights open={!phoneView} />
            </div>
          )}
        </div>
        <div>
          {footerExternals && (
            <div>
              <FooterExternals
                open={!phoneView}
                items={footerExternals}
                application={application}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Footer;
