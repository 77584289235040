import type { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export const useCollapseControlStyles = makeStyles((theme: Theme) => ({
  expandTextContainer: {
    paddingTop: theme.spacing(0.5),
    cursor: 'pointer',
    '&:hover .hoverTransformer': {
      color: theme.palette.primary.main,
      transform: 'translateX(0)',
    },
  },
  expandText: {
    display: 'inline-block',
    width: 0,
    whiteSpace: 'nowrap',
    direction: 'rtl',
    transition: '.5s',
    transform: 'translateX(20px)',
    color: 'rgba(255,255,255,0)',
    pointerEvents: 'none',
    position: 'relative',
    top: theme.spacing(-1),
  },
}));
