import React, { CSSProperties } from 'react';
import {
  Icon, Typography, Paper, Divider, LinkProps,
} from '@material-ui/core';
import clsx from 'clsx';
import { ElevationType, SpacingType } from 'types/Types';
import { PaperProps as MuiPaperProps } from '@material-ui/core/Paper';
import { acidForwardRef } from '../../utilities/forwardRef';
import { usePhoneView } from '../../hooks/usePhoneView';
import { CollapseControl } from './CollapseControl';
import { usePanelStyles } from './usePanelStyles';

export interface PanelProps extends Omit<MuiPaperProps, 'variant' | 'title'> {
  bordered?: boolean,
  control?: React.ReactElement,
  dense?: boolean,
  icon?: string | React.ReactElement,
  spacing?: SpacingType,
  subtitle?: string | JSX.Element,
  title?: string | JSX.Element,
  variant?: 'none' | 'default' | 'primary' | 'secondary' | 'success' | 'success' | 'info' | 'warning' | 'danger' | 'disabled',
  className?: string,
  elevation?: ElevationType,
  children?: React.ReactNode,
  headingHeight?: CSSProperties['height'],
  bodyHeight?: CSSProperties['height'],
  hideControlsOnPhoneView?: boolean;
  collapsed?: boolean;
  collapseText?: string;
  expandText?: string;
  onExpandCollapse?: LinkProps['onClick'];
}

const Panel = acidForwardRef<HTMLDivElement, PanelProps>((props, ref) => {
  const classes = usePanelStyles(props);
  const phoneView = usePhoneView();
  const {
    title,
    subtitle,
    icon,
    control,
    hideControlsOnPhoneView,
    collapsed,
    collapseText,
    expandText,
    onExpandCollapse,
    className,
    children,
    ...otherProps
  } = props;
  delete otherProps.bordered;
  delete otherProps.dense;
  const iconElement = (icon && typeof icon === 'string') ? <Icon className={classes.icon}>{icon}</Icon> : icon;

  /**
   * if user has not provided a value for `collapsed` prop, (type is undefined)
   * it is uncontrolled and unused. Do not display.
   * */
  const showCollapseControl = typeof collapsed === 'boolean';

  return (
    <Paper square className={clsx(classes.root, className)} {...otherProps} ref={ref} variant={undefined}>
      {title && (
        <>
          {
            phoneView
              ? (
                <>
                  <div className={classes.mobileHeading}>
                    <div className={classes.title}>
                      <Typography variant="h6">{title}</Typography>
                    </div>
                    {!hideControlsOnPhoneView && (
                      <>
                        {showCollapseControl && (
                        <CollapseControl
                          collapsed={collapsed}
                          collapseText={collapseText}
                          expandText={expandText}
                          onExpandCollapse={onExpandCollapse}
                        />
                        )}
                        <div className={classes.control}>
                          {control}
                        </div>
                      </>
                    )}
                  </div>
                  <Divider className={classes.mobileDivider} />
                </>
              )
              : (
                <div className={classes.heading}>
                  <div className={classes.title}>
                    {iconElement}
                    <Typography variant="h6" component="span">{title}</Typography>
                    {subtitle
                      ? (
                        <Typography variant="body1" component="span">
                          {typeof subtitle === 'string' ? ' • ' : ''}
                          {subtitle}
                        </Typography>
                      )
                      : null}
                  </div>
                  {showCollapseControl && (
                  <CollapseControl
                    collapsed={collapsed}
                    collapseText={collapseText}
                    expandText={expandText}
                    onExpandCollapse={onExpandCollapse}
                  />
                  )}
                  <div className={classes.control}>
                    {control}
                  </div>
                </div>
              )
          }
        </>
      )}
      {!collapsed && (
        <div className={classes.body}>
          {children}
        </div>
      )}
    </Paper>
  );
});

Panel.defaultProps = {
  bordered: true,
  elevation: 0,
  spacing: 2,
  variant: 'secondary',
};

export default Panel as React.FunctionComponent<PanelProps>;
