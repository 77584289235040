import type { PaletteType } from '@material-ui/core';
import type { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';
import { DEFAULT_THEME } from 'themes/defaultTheme';
import merge from 'lodash/merge';
import type { AcidPaletteOptions } from '../types/AcidPaletteOptions';
import type { AcidThemeOptions } from '../types/AcidThemeOptions';
import { getBaseThemeOptions } from './getBaseThemeOptions';

export const buildThemeOptions = (options: AcidThemeOptions | undefined): ThemeOptions => {
  const palette = options?.palette ?? {} as AcidPaletteOptions;
  const acidTheme = palette?.type ?? DEFAULT_THEME;
  const baseThemeOptions = getBaseThemeOptions(acidTheme);

  const muiThemeType: PaletteType = baseThemeOptions?.palette?.type ?? 'light';
  const muiThemeOptions: ThemeOptions = merge(baseThemeOptions, options ?? {});
  if (!muiThemeOptions.palette) muiThemeOptions.palette = {};
  muiThemeOptions.palette.type = muiThemeType;

  return muiThemeOptions;
};
