import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { Subtitle } from './Subtitle';
import { Description } from './Description';
import { usePageTitleStyles } from './styles';
import { TitleItemType } from './types';
import { PageTitleAddon } from './PageTitleAddon';
import { DEFAULT_LONG_SUBTITLE_LENGTH } from './consts';
import { GoHomeBtn } from './GoHomeBtn';
import { usePhoneView } from '../../hooks/usePhoneView';

export interface IPageTitleProps {
  /** The title portion of the component to be displayed at the left side of the bar. */
  title: TitleItemType;
  /** The subtitle portion of the component.<br/><br/> _NOTE:_ When the subtitle prop is provided a string, there are two possible positions for the rendering of the subtitle.<br/>If the subtitle is a string of length less than or equal to `longSubtitleLength`, the subtitle is rendered _beside_ the title; otherwise, if the subtitle is a string of length greater than `longSubtitleLength` the subtitle is rendered _below_ the title.<br/>If the subtitle is not a string, the element(s) of which it is comprised are rendered as siblings to the title. */
  subtitle?: TitleItemType;
  /** The item(s) to be displayed to the left of the title on the PageTitle bar. The size of this portion of the title is dependent on its contents. */
  leftItems?: TitleItemType;
  /** The item(s) to be displayed right-aligned on the PageTitle bar. */
  rightItems?: TitleItemType;
  /** The additional description for the page, rendered below the title and subtitle. */
  description?: TitleItemType;
  /** The minimum length of a `subtitle` string to be considered long. */
  longSubtitleLength?: number;
  /** Displays a button to go back to the main page */
  goHomeBtn?: boolean;
  /** Height of the container */
  height?: number;
}

export { DEFAULT_LONG_SUBTITLE_LENGTH } from './consts';

export const isSubtitleLong = (subtitle: string | undefined, longLength: number) => {
  if (!subtitle) return false;

  return subtitle.length >= longLength;
};

/**
## v2.0.6+

The **PageTitle** component is intended to provide a consistent title bar UX across the Maestro app suite.
 */
export const PageTitle = (props: IPageTitleProps) => {
  const {
    title,
    subtitle,
    rightItems,
    leftItems,
    description,
    goHomeBtn,
    longSubtitleLength = DEFAULT_LONG_SUBTITLE_LENGTH,
    height
  } = props;

  const fullLeftItems:React.ReactNode[] = [];
  if (goHomeBtn) fullLeftItems.push(<GoHomeBtn />);
  if (leftItems) {
    if (!Array.isArray(leftItems)) {
      fullLeftItems.push(leftItems);
    } else {
      leftItems.forEach(fullLeftItems.push);
    }
  }

  const classes = usePageTitleStyles();
  const phoneView = usePhoneView();

  const subtitleIsLong = (typeof subtitle === 'string') && (phoneView || isSubtitleLong(subtitle, longSubtitleLength));
  const shouldRenderShortSubtitle = !!subtitle && !subtitleIsLong;
  const shouldRenderLongSubtitle = (!!subtitle && subtitleIsLong);

  return (
    <Box
      display="inline-flex"
      alignItems="center"
      justifyContent="center"
      className={classes.titleContainer}
      width="100%"
      height={height}
    >
      {fullLeftItems.length > 0 && <Box display="inline-block">
        <PageTitleAddon
          items={fullLeftItems}
          className={classes.leftItems}
        />
      </Box>}
      <Box
        display="inline-block"
        alignItems="flex-start"
        className={classes.mainTitleItem}
        flexDirection="row"
        width="100%"
      >
        <Box
          flexGrow={1}
        >
          <Typography
            variant="h6"
            component="div"
            className={`${classes.titleTypography} ${phoneView && shouldRenderLongSubtitle ? classes.titleTypographyMobile : ''}`}
          >
            <Box
              className={classes.mainTitleText}
              display="inline"
            >
              { title }
            </Box>
            <Box
              display="inline"
            >
              <Subtitle
                subtitle={subtitle as TitleItemType}
                visible={shouldRenderShortSubtitle}
              />
            </Box>
          </Typography>
          {(shouldRenderLongSubtitle || !!description) 
            && <Box
              width="100%"
              display="inline-block"
              alignItems="flex-start"
            >
              <Subtitle
                subtitle={subtitle as TitleItemType}
                visible={shouldRenderLongSubtitle}
              />
              <Description
                description={description}
                visible={!!description}
              />
            </Box>}
        </Box>
        
      </Box>
      <Box display="inline">
          <PageTitleAddon
            visible={!!rightItems}
            items={rightItems}
            className={classes.rightItems}
          />
        </Box>
    </Box>
  );
};
