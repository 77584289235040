import { createMuiTheme } from '@material-ui/core/styles';
import {
  amber, cyan, green, grey, red,
} from '@material-ui/core/colors';
import type { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';
import type { ThemeType } from 'types/ThemeType';

/**
 * intentional dangle -- deprecated theme
 */
// eslint-disable-next-line no-underscore-dangle
export const _michelinOld = (options: ThemeOptions) => {
  const theme = createMuiTheme({
    typography: {
      fontFamily: ['Montserrat', 'sans-serif'].join(','),
      fontSize: 14,
    },
    ...options,
  }) as ThemeType;

  theme.palette.primary = theme.palette.augmentColor({ main: 'rgba(39, 80, 155, 1)' });
  theme.palette.secondary = theme.palette.augmentColor({ main: 'rgba(252, 229, 0, 1)' });
  theme.palette.default = theme.palette.augmentColor({ main: 'rgba(4, 145, 210, .8)' });
  theme.palette.success = theme.palette.augmentColor({ main: green[500] });
  theme.palette.warning = theme.palette.augmentColor({ main: amber[500] });
  theme.palette.info = theme.palette.augmentColor({ main: cyan[500] });
  theme.palette.error = theme.palette.augmentColor({ main: red[500] });
  theme.palette.disabled = theme.palette.augmentColor({ main: grey[500] });
  theme.palette.none = theme.palette.augmentColor({ main: grey[300] });

  theme.palette.success.contrastText = 'rgba(255, 255, 255, 1)';
  theme.palette.info.contrastText = 'rgba(255, 255, 255, 1)';
  theme.palette.warning.contrastText = theme.palette.text.primary;
  theme.palette.none.contrastText = theme.palette.text.primary;

  theme.palette.primary.tint = 'rgba(39, 80, 155, .1)';
  theme.palette.secondary.tint = 'rgba(252, 229, 0, .3)';
  theme.palette.default.tint = 'rgba(4, 145, 210, .1)';
  /* eslint-disable prefer-destructuring */
  theme.palette.success.tint = green[50];
  theme.palette.warning.tint = amber[50];
  theme.palette.info.tint = cyan[50];
  theme.palette.error.tint = red[50];
  theme.palette.disabled.tint = grey[50];
  theme.palette.none.tint = grey[50];
  /* eslint-enable prefer-destructuring */

  return theme;
};
