import React from 'react';
import type { UserInteractionsDisplayProps } from '../types';
import { useSwitchAccountDisplayStyles } from '../styles';

const USER_INTERACTIONS_DISPLAY_TEST_ID = 'user-interactions-display';

const UserInteractionsDisplay = (props: UserInteractionsDisplayProps) => {
  const { children } = props;
  const classes = useSwitchAccountDisplayStyles();

  return (
    <div className={classes.root} data-testid={USER_INTERACTIONS_DISPLAY_TEST_ID}>
      {children}
    </div>
  );
};

export {
  UserInteractionsDisplay,
  USER_INTERACTIONS_DISPLAY_TEST_ID,
};
