import React from 'react';
import { Tooltip } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Info, InfoOutlined } from '@material-ui/icons';
import clsx from 'clsx';

interface InformationTooltipProps {
  title: JSX.Element,
  id?: string,
  iconVariant?: 'filled' | 'outlined',
  tooltipVariant?: 'light' | 'dark',
  tooltipPlacement?: 'bottom' | 'left' | 'right' | 'top',
}

const useTooltipStyles = makeStyles((theme: Theme) => ({
  tooltipCommon: {
    boxShadow: '0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)',
  },
  tooltipLight: {
    color: theme.palette.common.black,
    backgroundColor: theme.palette.common.white,
  },
  tooltipDark: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.black,
  },
}));

export const InformationTooltip = ({
  title,
  id,
  iconVariant = 'outlined',
  tooltipVariant = 'light',
  tooltipPlacement = 'right',
}: InformationTooltipProps) => {
  const classes = useTooltipStyles();

  return (
    <Tooltip
      placement={tooltipPlacement}
      classes={{
        tooltip: clsx(
          classes.tooltipCommon, classes[tooltipVariant === 'light' ? 'tooltipLight' : 'tooltipDark'],
        ),
      }}
      title={title}
      id={id}
    >
      {
        iconVariant === 'outlined'
          ? <InfoOutlined fontSize="small" />
          : <Info fontSize="small" />
      }
    </Tooltip>
  );
};

export default InformationTooltip;
