import React from 'react';
import Button from 'components/Button/Button';
import moment from 'moment';
import type { ISelectDateTimeProps } from './SelectDateTime';

export type AMPMType = 'am' | 'pm';

export interface IHourButtonProps {
  date: ISelectDateTimeProps['date'];
  setDate: ISelectDateTimeProps['setDate'];
  time: number;
  isSelected: boolean;
  ampm: AMPMType;
  className?: string;
  disabled?: boolean;
}

export const HourButton = (props: IHourButtonProps) => {
  const {
    date,
    time,
    isSelected,
    ampm,
    setDate,
    className,
    disabled,
  } = props;

  const twentyFourHourTime = ampm === 'pm' && time < 12 ? time + 12 : time;

  return (
    <Button
      variant="contained"
      color={isSelected ? 'primary' : 'secondary'}
      onClick={() => {
        const m = moment(`${moment(date || '').format('YYYY, MM D').toString()} ${twentyFourHourTime}:00`);
        setDate(new Date(m.toISOString()));
      }}
      className={className}
      disabled={disabled}
    >
      {`${time}:00`}
    </Button>
  );
}