import React from 'react';
import { Grid, Divider } from '@material-ui/core';
import { useAltHeaderStyles } from '../styles';
import { AltHeaderItemProps } from '../types';

const AltHeaderItem = (props: AltHeaderItemProps) => {
  const { noDivider, children } = props;
  const classes = useAltHeaderStyles();

  if (noDivider) {
    return (
      <Grid item xs className={classes.subContainer}>
        {children}
      </Grid>
    );
  }

  return (
    <>
      <Grid item xs className={classes.subContainer}>
        {children}
      </Grid>
      <Divider className={classes.divider} orientation="vertical" flexItem />
    </>
  );
};

export {
  AltHeaderItem,
};
