import type { AcidTheme } from 'themes/ThemeDefinitions';
import { getLogoPrimary } from './getLogoPrimary';
import { getLogoMaestro } from './getLogoMaestro';
import { getLogoPoweredBy } from './getLogoPoweredBy';
import { getLogoMichelin } from './getLogoMichelin';

export const buildThemeLogos = (type: AcidTheme) => ({
  primary: getLogoPrimary(type),
  /**
   * @deprecated prefer Michelin branding
   */
  maestro: getLogoMaestro(type),
  /**
   * @deprecated prefer Michelin branding
   */
  poweredBy: getLogoPoweredBy(type),
  michelin: getLogoMichelin(),
});
