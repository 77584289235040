import { makeStyles } from '@material-ui/core/styles';
import type { ThemeType } from 'types/ThemeType';
import type { PanelProps } from './Panel';

export const usePanelStyles = makeStyles((theme: ThemeType) => ({
  root: (props: PanelProps) => {
    const { bordered = true } = props;
    return ({
      boxSizing: 'border-box',
      position: 'relative',
      width: '100%',
      flex: '1 1 0%',
      backgroundColor: theme.palette.background.paper,
      border: `1px solid ${bordered ? theme.palette.divider : 'transparent'}`,
      [theme.breakpoints.down('xs')]: {
        border: 'none',
      },
    });
  },
  heading: (props: PanelProps) => {
    const { variant = 'secondary' } = props;
    return ({
      display: 'flex',
      padding: props.dense ? '2px 4px 2px 4px' : '.25rem .75rem .25rem .75rem',
      backgroundColor: theme.palette[variant] ? theme.palette[variant]?.main : theme.palette.background.paper,
      color: theme.palette[variant] ? theme.palette[variant]?.contrastText : theme.palette.text.primary,
      height: props.headingHeight,
    });
  },
  mobileHeading: (props: PanelProps) => ({
    display: 'flex',
    padding: props.dense ? '2px 4px 2px 4px' : '.25rem .75rem .25rem .75rem',
    height: props.headingHeight,
  }),
  mobileDivider: {
    width: '100%',
    marginBottom: '10px',
    backgroundColor: theme.palette.primary.main,
  },
  title: {
    flex: '1 1 0%',
    margin: 'auto',
    '& svg': {
      position: 'relative',
      top: theme.spacing(0.5),
      marginRight: theme.spacing(1),
    },
  },
  control: {
    fontSize: '.5em',
  },
  icon: {
    lineHeight: '1.33',
    marginRight: theme.spacing(1),
  },
  body: (props: PanelProps) => ({
    padding: theme.spacing(props.spacing || 0),
    height: props.bodyHeight,
  }),
}));
