import React from 'react';
import type { DialogContent, DialogProviderProps } from './DialogProvider';

export interface DialogContextProps {
  handleMessageDialog: (
    content: DialogContent,
    title: string|JSX.Element,
    okButton?: string,
    onOkClick?: (event?: React.MouseEvent) => void,
    options?: DialogProviderProps,
  ) => null | Promise<boolean>,
  handleConfirmDialog: (
    content: DialogContent,
    title: string|JSX.Element,
    okButton?: string,
    cancelButton?: string,
    onOkClick?: (event?: React.MouseEvent) => void,
    onCancelClick?: (event?: React.MouseEvent) => void,
    options?: DialogProviderProps,
  ) => null | Promise<boolean>,
  handleAlertDialog: (
    content: DialogContent,
    title: string|JSX.Element,
    okButton?: string,
    onOkClick?: (event?: React.MouseEvent) => void,
    options?: DialogProviderProps,
  ) => null | Promise<boolean>,
  handleErrorDialog: (
    content: DialogContent,
    title: string|JSX.Element,
    okButton?: string,
    onOkClick?: (event?: React.MouseEvent) => void,
    options?: DialogProviderProps,
  ) => null | Promise<boolean>,
  handleLoadingDialog: (
    content?: DialogContent,
    title?: string|JSX.Element,
    options?: DialogProviderProps,
  ) => (()=>void),
}
export default React.createContext({} as DialogContextProps);
