import React from 'react';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { Avatar as MuiAvatar, Chip } from '@material-ui/core';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { ThemeType } from '../../types/ThemeType';

const OPACITY = {
  dark: 0.2,
  light: 0.4,
};

export interface UserChipProps {
  name: string;
  variant?: 'default' | 'outlined';
  size?: 'small' | 'medium';
  color?: 'none' | 'default' | 'primary' | 'secondary' | 'success' | 'info' | 'warning' | 'danger',
  image?: any;
}

const useStyles = makeStyles((theme: ThemeType) => ({
  avatar: {
    fontSize: '10px !important',
    fontWeight: 'bold',
  },
  primary: {
    backgroundColor: fade(theme.palette.primary.main, OPACITY[theme.palette.type]),
    color: fade(theme.palette.primary.dark, 1),
  },
  secondary: {
    backgroundColor: fade(theme.palette.secondary.main, OPACITY[theme.palette.type]),
    color: fade(theme.palette.secondary.contrastText, OPACITY[theme.palette.type]),
  },
  success: {
    backgroundColor: fade(theme.palette.primary.main, OPACITY[theme.palette.type]),
    color: fade(theme.palette.primary.dark, 1),
  },
  info: {
    backgroundColor: fade(theme.palette.info.main, OPACITY[theme.palette.type]),
    color: fade(theme.palette.info.dark, 1),
  },
  warning: {
    backgroundColor: fade(theme.palette.warning.main, OPACITY[theme.palette.type]),
    color: fade(theme.palette.warning.dark, 1),
  },
  danger: {
    backgroundColor: fade(theme.palette.error.main, OPACITY[theme.palette.type]),
    color: fade(theme.palette.error.main, 1),
  },
}));

export const UserChip = (props: UserChipProps) => {
  const {
    name,
    image,
    color = 'default',
    size = 'small',
    variant = 'outlined',
  } = props;
  const classes = useStyles(props);
  const words = name.split(' ')
    .filter((w) => w !== '');
  const initials = [] as string[];
  if (words.length) initials.push((words.shift() || '-')[0].toLocaleUpperCase());
  if (words.length) initials.push((words.pop() || '-')[0].toLocaleUpperCase());
  const avatar = <MuiAvatar className={clsx(classes.avatar, classes[color])} src={image}>{image ? undefined : initials}</MuiAvatar>;
  return (
    <>
      <Chip variant={variant} size={size} label={name} avatar={avatar} />
    </>
  );
};
