import NotificationList from 'components/NotificationList/NotificationList';
import { withPopper } from '../withPopper';

const withNotificationListPopper = <WrappedComponentProps extends {}>(WrappedComponent: React.ComponentType<WrappedComponentProps>) => {
  return withPopper(NotificationList)(WrappedComponent);
};

export {
  withNotificationListPopper,
};
