/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import {
  Checkbox,
  Container,
  FormControlLabel,
  Grid, Typography
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Add, Remove } from '@material-ui/icons';
import hash from 'object-hash';
import { useTranslation } from 'react-i18next';
import type { AxleType, TiresSelectionType } from 'types/Types';
import Button from 'components/Button/Button';
import { CarDraw } from './CarDraw';

interface TiresSelector {
  onSelectTires: (tires:TiresSelectionType) => void;
  tires?: TiresSelectionType;
  allowSelection?: boolean;
  maxAxles?: number;
}

const emptyTires = {
  axles: [
    {
      leftTires: [{ tire: false }],
      rightTires: [{ tire: false }],
    },
    {
      leftTires: [{ tire: false }],
      rightTires: [{ tire: false }],
    },
  ],
};

export default function TiresSelector(props: TiresSelector) {
  const { t } = useTranslation();
  const [tires, setTires] = useState<TiresSelectionType>(props.tires || emptyTires);

  const { onSelectTires, allowSelection = true, maxAxles = 3 } = props;
  const theme = useTheme();
  useEffect(() => {
    onSelectTires(tires);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tires]);

  const addAxle = (axle:AxleType, n:number) => (
    <Container key={hash(axle, n)}>
      {axle.rightTires.map((_, tirePosition) => (
        <>
          <div
            id={`Axle${n + 1}tirePosition${tirePosition}Right`}
            style={{
              cursor: allowSelection ? 'pointer' : undefined,
              borderRadius: '25%',
              border: 'solid',
              height: 20,
              width: 12,
              position: 'absolute',
              top: n > 0 ? ((n + 1) * 24) + 20 : 8,
              right: n > 0 ? -11 - (11 * tirePosition) : -13,
              backgroundColor: tires.axles[n].rightTires[0].tire ? theme.palette.primary.main : undefined,
            }}
            onClick={() => {
              if (!allowSelection) return;
              setTires((prevValue) => {
                const editedSelectedTires = { ...prevValue };
                editedSelectedTires.axles[n].rightTires[tirePosition].tire = !editedSelectedTires.axles[n].rightTires[tirePosition].tire;
                return editedSelectedTires;
              });
            }}
          />
          <div
            id={`Axle${n + 1}tirePosition${tirePosition}Left`}
            style={{
              cursor: allowSelection ? 'pointer' : undefined,
              borderRadius: '25%',
              border: 'solid',
              height: 20,
              width: 12,
              position: 'absolute',
              top: n > 0 ? ((n + 1) * 24) + 20 : 8,
              left: n > 0 ? -11 - (11 * tirePosition) : -13,
              backgroundColor: tires.axles[n].leftTires[0].tire ? theme.palette.primary.main : undefined,
            }}
            onClick={() => {
              if (!allowSelection) return;
              setTires((prevValue) => {
                const editedSelectedTires = { ...prevValue };
                editedSelectedTires.axles[n].leftTires[tirePosition].tire = !editedSelectedTires.axles[n].leftTires[tirePosition].tire;
                return editedSelectedTires;
              });
            }}
          />
        </>
      ))}

      {n !== 0 && (
      <Container
        style={{
          position: 'absolute',
          top: (((n + 1) * 24) + 20) - 4,
          right: -70,
          padding: 0,
        }}
      >
        <FormControlLabel
          style={{ transform: 'scale(0.8)' }}
          control={(
            <Checkbox
              id={`Axle${n + 1}DualCheckBox`}
              color="primary"
              size="small"
              checked={tires.axles[n].leftTires.length === 2}
              onChange={() => {
                setTires((prevValue) => {
                  const editedSelectedTires = { ...prevValue };
                  if (tires.axles[n].leftTires.length === 2) {
                    editedSelectedTires.axles[n].leftTires.pop();
                    editedSelectedTires.axles[n].rightTires.pop();
                  } else if (tires.axles[n].leftTires.length === 1) {
                    editedSelectedTires.axles[n].leftTires.push({ tire: false });
                    editedSelectedTires.axles[n].rightTires.push({ tire: false });
                  }
                  return editedSelectedTires;
                });
              }}
            />
              )}
          label={t('Dual')}
        />
      </Container>
      )}
    </Container>
  );

  return (
    <Grid container>
      <Grid item xs={6}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Typography color="primary" style={{ fontWeight: 'bold', fontSize: 16, marginRight: 8 }} noWrap>
            {t('Axles: ')}
          </Typography>
          <Button
            style={{ minWidth: 10, padding: 0 }}
            color="primary"
            variant="text"
            onClick={() => {
              if (tires.axles.length < maxAxles) {
                setTires((prevValue) => {
                  const editedSelectedTires = { ...prevValue };
                  editedSelectedTires.axles.push({
                    leftTires: [{ tire: false }],
                    rightTires: [{ tire: false }],
                  });
                  return editedSelectedTires;
                });
              }
            }}
          >
            <Add />
          </Button>
          <Button
            style={{ minWidth: 10, padding: 0 }}
            color="primary"
            variant="text"
            onClick={() => {
              if (tires.axles.length > 2) {
                setTires((prevValue) => {
                  const editedSelectedTires = { ...prevValue };
                  editedSelectedTires.axles.pop();
                  return editedSelectedTires;
                });
              }
            }}
          >
            <Remove />
          </Button>
        </div>
      </Grid>
      <Grid item xs={6}>
        <div style={{
          height: (tires.axles.length * 24) + 54,
          width: 40,
          margin: 16,
          position: 'relative',
        }}
        >
          <div style={{
            borderRadius: '0 0 25% 25%',
            border: 'solid',
            borderWidth: '1.5px',
            borderTop: 'none',
            height: (tires.axles.length * 24),
            width: '100%',
            position: 'absolute',
            bottom: 0,
          }}
          />
          <CarDraw style={{
            position: 'absolute', top: -23, right: -12, width: 62, height: 132,
          }}
          />
          {tires && tires.axles.map((tire, n) => addAxle(tire, n))}
        </div>
      </Grid>
    </Grid>
  );
}
