import React from 'react';
import Select from 'components/Select/Select';
import type { SelectProps } from 'components/Select/Select';
import type { OptionType } from 'types/Types';
import { upperCase } from 'lodash';

type OverriddenSelectProps = 'options' | 'onChange' | 'value';
type ThemeInputProp<TTheme extends string> = TTheme | OptionType<TTheme>;
type ThemeSelectorOnChange = <TTheme extends string>(newlySelectedTheme: TTheme) => void;
type GetThemeLabel = <TTheme extends string>(theme: TTheme) => string;

export interface ThemeSelectorProps<TTheme extends string> extends Omit<SelectProps, OverriddenSelectProps> {
  themes: ThemeInputProp<TTheme>[];
  selectedTheme: TTheme;
  onChange: ThemeSelectorOnChange;
  getThemeLabel?: GetThemeLabel;
}

const handleChange = <TTheme extends string>(onChange: ThemeSelectorProps<TTheme>['onChange']) => (event) => {
  const { value } = event.target;

  if (typeof value !== 'string') {
    throw new TypeError(`Unexpected value received in ThemeSelector handleChange: ${JSON.stringify(value)}`);
  }

  onChange(value as TTheme);
};

const buildOptionFrom = <TTheme extends string>(theme: ThemeInputProp<TTheme>, getThemeLabel: GetThemeLabel): OptionType<TTheme> => {
  if (typeof theme === 'object') {
    return theme;
  }

  return {
    label: getThemeLabel(theme),
    value: theme,
  };
};

export const ThemeSelector = <TTheme extends string>(props: ThemeSelectorProps<TTheme>) => {
  const {
    themes,
    selectedTheme,
    onChange,
    getThemeLabel = upperCase,
    ...selectProps
  } = props;

  const availableThemeOptions = themes.map((theme) => buildOptionFrom(theme, getThemeLabel));

  return (
    <Select
      {...selectProps}
      options={availableThemeOptions}
      onChange={handleChange(onChange)}
      value={selectedTheme}
    />
  );
};
