import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Tooltip as MuiTooltip } from '@material-ui/core';
import clsx from 'clsx';
import type { ThemeType } from 'types/ThemeType';
import type { TooltipProps as MuiTooltipProps } from '@material-ui/core/Tooltip';

const classStyles = makeStyles((theme: ThemeType) => ({
  tooltip: {
    backgroundColor: theme.palette.grey[900],
    color: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
  arrow: {
    color: theme.palette.grey[900],
  },
}));

interface TooltipProps extends MuiTooltipProps { }

export default function Tooltip(props: TooltipProps) {
  const classes = classStyles(props);

  const {
    children, className, arrow, ...otherProps
  } = props;

  return (
    <MuiTooltip
      className={clsx(className)}
      classes={classes}
      arrow={arrow === undefined ? true : arrow}
      {...otherProps}
    >
      {children}
    </MuiTooltip>
  );
}
