import type { AcidTheme } from 'themes/ThemeDefinitions';
import { MaestroLogo } from 'utilities/createTheme/logos/MaestroLogo';

/**
 * @deprecated prefer Michelin branding
 */
export const getLogoMaestro = (type: AcidTheme) => {
  const baseLogoDefinition = {
    light: MaestroLogo.LIGHT,
    dark: MaestroLogo.DARK,
  };

  switch (type) {
    case 'dark':
      return {
        ...baseLogoDefinition,
        primary: MaestroLogo.DARK,
      };
    case 'light':
    default:
      return {
        ...baseLogoDefinition,
        primary: MaestroLogo.LIGHT,
      };
  }
};
