import React, { type CSSProperties } from 'react';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import type { ThemeType } from 'types/ThemeType';
import type { SpacingType } from 'types/Types';
import { acidForwardRef } from '../../utilities/forwardRef';

export interface ContainerProps {
  spacing?: SpacingType;
  className?: string;
  children?: React.ReactNode;
  style?: CSSProperties;
  height?: CSSProperties['height'];
  flex?: CSSProperties['flex'];
}

const useStyles = makeStyles((theme: ThemeType) => ({
  root: (props: ContainerProps) => ({
    boxSizing: 'border-box',
    position: 'relative',
    display: 'flex',
    width: '100%',
    flex: '1 1 0 %',
    flexDirection: 'column',
    padding: theme.spacing(props.spacing || 0),
  }),
}));

const Container = acidForwardRef<HTMLElement, ContainerProps>((props, ref) => {
  const {
    children, className, style, height, flex, ...otherProps
  } = props;
  const classes = useStyles(props);

  return (
    <section className={clsx(classes.root, className)} style={{...style, height, flex}} {...otherProps} ref={ref as React.RefObject<HTMLElement>}>
      {children}
    </section>
  );
});

Container.defaultProps = {
  spacing: 2,
};

export default Container;
