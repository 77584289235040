/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { fade, darken, lighten } from '@material-ui/core/styles/colorManipulator';
import {
  Avatar as MuiAvatar,
  Badge as MuiBadge,
  Paper as MuiPaper,
  Popper as MuiPopper,
  ClickAwayListener as MuiClickAwayListener,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import type { ThemeType } from 'types/ThemeType';
import type { Address } from 'types/Types';
import Tooltip from 'components/Tooltip/Tooltip';

const escapeRegExp = (regex: string) => regex.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
function highlight(string = '', query = '', color) {
  if (!string) return null;
  if (query === '') return <span key={`empty-${string}`}>{string}</span>;
  const output: any[] = [];
  const regEx = new RegExp(`(${escapeRegExp(query)})`, 'ig');
  const stringOutput = string.replace(regEx, '#$1#').split('#');
  stringOutput.forEach((e, idx) => {
    if (e.toLowerCase() === query.toLowerCase()) {
      output.push(<span style={{ background: color }} key={`highlight-${idx}-${e}`}>{e}</span>);
    } else {
      output.push(<span key={`normal-${idx}`}>{e}</span>);
    }
  });
  return output;
}
export interface AccountAvatarProps {
  name: string|JSX.Element,
  badgeColor?: 'default' | 'primary' | 'secondary' | 'success' | 'info' | 'warning' | 'danger',
  badgeContent?: number,
  badgeTooltip?: string,
  terminalId?: string,
  accountDisplay?: 'full' | 'short',
  size?: 'small' | 'normal',
  accountType: 'PC' | 'HO' | 'BT' | 'ST' | 'MS' | 'MB' | 'parentCompany' | 'homeOffice' | 'billTo' | 'shipTo' | 'multiShipTo' | 'multiBillTo',
  addressDisplay?: 'full' | 'cityState' | 'none',
  className?: string,
  children?: React.ReactNode,
  style?: React.CSSProperties,
  avatarPosition?: 'left' | 'right' | 'none',
  onClick?: (event?: React.MouseEvent) => void,
  locationType?: 'fleet'|'dealer',
  location?: string;
  accountNumbers?: {
    parentCompany?: string,
    homeOffice?: string,
    billTo?: string,
    shipTo?: string,
    mdm?: string,
  },
  address?:
  string|Address,
  [index: string]: any,
  avatarOnly?: boolean,
  useHighlight?: boolean,
  highlightColor?: string,
  searchTerm?: string,
  loading?: boolean,
}

const OPACITY = {
  dark: 0.2,
  light: 0.4,
};

const createAccountTypeStyles = (theme: ThemeType, sourceColor: string) => {
  const themeType = theme.palette.type;
  const backgroundColor = fade(sourceColor, themeType === 'dark' ? 1 - OPACITY[themeType] : OPACITY[themeType]);
  const color = themeType === 'dark' ? lighten(sourceColor, 0.65) : darken(sourceColor, 0.65);
  return {
    backgroundColor,
    color,
  };
};

const useStyles = makeStyles((theme:ThemeType) => ({
  root: (props) => ({
    boxSizing: 'border-box',
    position: 'relative',
    display: 'flex',
    width: '100%',
    flex: '1 1 0 %',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: props.children ? 'pointer' : 'default',
  }),
  text: (props:any) => ({
    flex: 1,
    paddingLeft: props.avatarPosition === 'left' ? theme.spacing(1) : 'inherit',
    paddingRight: props.avatarPosition === 'right' ? theme.spacing(1) : 'inherit',
    lineHeight: '12px',
  }),
  name: (props:any) => ({
    fontWeight: 'bold',
    fontSize: props.size === 'small' ? '9px' : '11px',
    textAlign: props.avatarPosition === 'right' ? 'right' : 'left',
  }),
  description: (props:any) => ({
    fontSize: props.size === 'small' ? '9px' : '10px',
    lineHeight: '12px',
    textAlign: props.avatarPosition === 'right' ? 'right' : 'left',
  }),
  bullet: {
    '&:not(:last-child):after': {
      content: "' • '",
    },
  },
  bold: {
    fontWeight: 'bold',
  },
  popper: {
    zIndex: theme.zIndex.drawer,
  },
  paper: {
    borderRadius: '8px',
  },
  accountAvatar: (props) => {
    return {
      width: props.size === 'small' ? theme.spacing(4) : theme.spacing(5),
      height: props.size === 'small' ? theme.spacing(4) : theme.spacing(5),
      fontSize: '14px',
      fontWeight: 'bold',
    };
  },
  PC: createAccountTypeStyles(theme, theme.palette.secondary.main),
  HO: createAccountTypeStyles(theme, theme.palette.primary.main),
  BT: createAccountTypeStyles(theme, theme.palette.info.main),
  MB: createAccountTypeStyles(theme, theme.palette.info.dark),
  ST: createAccountTypeStyles(theme, theme.palette.warning.main),
  MS: createAccountTypeStyles(theme, theme.palette.alternative.main),
  locationType: {
    color: theme.palette.text.disabled,
    whiteSpace: 'nowrap',
    fontSize: '0.8em',
    marginLeft: '0.2rem',
  },
}));

const badgeStyles = makeStyles((theme:ThemeType) => ({
  root: {
    alignSelf: 'center',
  },
  primary: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  secondary: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  },
  success: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
  },
  info: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.info.contrastText,
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
  },
  danger: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
}));

interface AccountNumberProps {
  label: string,
  accountNumber: React.ReactNode,
}

function AccountNumber(props:AccountNumberProps) {
  const classes = useStyles(props);
  const { label, accountNumber } = props;

  return (
    <span className={classes.bullet}>
      {label}
      {': '}
      <span className={classes.bold}>{accountNumber}</span>
    </span>
  );
}

interface AvatarBadgeProps {
  badgeContent?: number,
  badgeColor?: 'default'|'primary'|'secondary'|'success'|'info'|'warning'|'danger',
  badgeTooltip?: string,
  value?: any,
  size?: 'small' | 'normal',
  loading?: boolean,
}

function AvatarBadge(props:AvatarBadgeProps) {
  const {
    value, badgeContent, badgeColor = 'default', badgeTooltip, loading,
  } = props;

  const classes = useStyles(props);
  const badge = badgeStyles(props);

  if (loading) {
    return (
      <Skeleton variant="circle" className={classes.accountAvatar} />
    );
  }

  const valueClass = value ? classes[value] : '';
  if (badgeContent) {
    return (
      <Tooltip title={badgeTooltip || ''}>
        <MuiBadge
          badgeContent={badgeContent}
          color="secondary"
          classes={{ root: badge.root, badge: badge[badgeColor] }}
        >
          <MuiAvatar className={clsx(classes.accountAvatar, valueClass)}>{value}</MuiAvatar>
        </MuiBadge>
      </Tooltip>
    );
  }
  return (
    <MuiAvatar className={clsx(classes.accountAvatar, valueClass)}>{value}</MuiAvatar>
  );
}

function getAccountType(accountType) {
  switch (accountType) {
    case 'parentCompany': return 'PC';
    case 'homeOffice': return 'HO';
    case 'billTo': return 'BT';
    case 'shipTo': return 'ST';
    case 'multiBillTo': return 'MB';
    case 'mutliShipTo': return 'MS';

    default: return accountType;
  }
}

export function getAccountTypeDesc(accountType) {
  switch (getAccountType(accountType)) {
    case 'PC': return 'Parent Company';
    case 'HO': return 'Home Office';
    case 'BT': return 'Bill To';
    case 'MB': return 'Multi Bill To';
    case 'ST': return 'Ship To';
    case 'MS': return 'Multi Ship To';
    default: return accountType;
  }
}

function getAccounts(accountNumbers:AccountAvatarProps['accountNumbers'], thisAccountType, accountDisplay) {
  const accounts:any[] = [];

  for (const account in accountNumbers) {
    const accountType = getAccountType(account);
    const accountTypeDesc = getAccountTypeDesc(accountType);
    const accountNumber = accountNumbers.mdm || accountNumbers[account];

    if (accountDisplay === 'full'
    || (accountType === thisAccountType)
    || (['BT', 'ST'].includes(thisAccountType)
    && ['BT', 'ST'].includes(accountType))) {
      if (accountNumbers[account]) {
        accounts.push({ label: accountTypeDesc, accountType, accountNumber });
      }
    }
  }

  return accounts;
}

const getAddress = (address: AccountAvatarProps['address'], addressDisplay: AccountAvatarProps['addressDisplay']) => {
  if (!address) return undefined;
  if (typeof address === 'string') return address;

  const {
    address1, address2, city, state, zip,
  } = address;
  const words = [] as string[];

  if (addressDisplay === 'cityState') {
    if (city) {
      words.push(city);
      if (state) words.push(state);
    } else if (address1) {
      words.push(address1);
    }
  } else {
    if (address1) words.push(address1);
    if (address2) words.push(address2);
    if (city) words.push(city);
    if (state) words.push(state);
    if (zip) words.push(zip);
  }

  return words.join(', ');
};

/**
 * Account Avatars are used to display information about an account in a compact and unified format.
 *
 * This component depends upon `@material-ui/lab@4.0.0-alpha.43` (see peerDependencies).
 * Other versions of `@material-ui/lab` __may or may not__ function as expected.
 *
 * See the [Material-UI documentation](https://v4.mui.com/components/about-the-lab/#about-the-lab)
 * for more details about the volatility of `@material-ui/lab` versions.
 */
export default function AccountAvatar(props:AccountAvatarProps) {
  const classes = useStyles(props);
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const {
    avatarPosition = 'left',
    name,
    accountType = 'ST',
    badgeColor = 'primary',
    badgeContent,
    badgeTooltip,
    accountDisplay = 'short',
    address,
    terminalId,
    addressDisplay = 'cityState',
    accountNumbers,
    className,
    children,
    size,
    location,
    avatarOnly,
    useHighlight = false,
    searchTerm = '',
    highlightColor = 'yellow',
    loading,
    ...otherProps
  } = props;

  const thisAccountType = getAccountType(accountType);
  const accounts = getAccounts(accountNumbers, thisAccountType, accountDisplay);
  const thisAddress = getAddress(address, addressDisplay);

  function onMenuOpen(event) {
    if (children) {
      setAnchorEl(event.currentTarget);
    }
  }

  function onMenuClose() {
    setAnchorEl(null);
  }

  return (
    <>
      <div className={clsx(classes.root, className)} onClick={onMenuOpen} {...otherProps}>
        {avatarPosition === 'left' && (
        <AvatarBadge
          badgeContent={badgeContent}
          badgeColor={badgeColor}
          badgeTooltip={badgeTooltip}
          value={thisAccountType}
          size={size}
          loading={loading}
        />
        )}
        {!avatarOnly
          ? (
            <div className={classes.text}>
              <div className={classes.name}>
                {loading && <Skeleton variant="text" />}
                {!loading && (useHighlight ? highlight(String(name), searchTerm, highlightColor) : name)}
                {' '}
                {loading && <Skeleton variant="text" />}
                {!loading && location && (
                <span className={classes.locationType}>
                  {useHighlight ? highlight(String(location), searchTerm, highlightColor) : location}
                </span>
                )}
              </div>
              {loading && <Skeleton variant="text" />}
              {(!loading && thisAddress && addressDisplay !== 'none') && <div className={classes.description}>{thisAddress}</div>}
              {!loading && accounts.length
                ? (
                  <div className={classes.description}>
                    {accounts.map((item) => (
                      <AccountNumber
                        key={item.accountNumber}
                        label={t(accounts.length > 2 ? item.accountType : item.label)}
                        accountNumber={useHighlight ? highlight(String(item.accountNumber), searchTerm, highlightColor) : item.accountNumber}
                      />
                    ))}
                    {terminalId && <AccountNumber key="terminalId" label={t('Terminal')} accountNumber={terminalId} />}
                  </div>
                )
                : <></>}
            </div>
          ) : null}
        {avatarPosition === 'right'
          && (
          <AvatarBadge
            badgeContent={badgeContent}
            badgeColor={badgeColor}
            badgeTooltip={badgeTooltip}
            value={thisAccountType}
            size={size}
            loading={loading}
          />
          )}
      </div>
      {children
        && (
        <MuiPopper
          className={classes.popper}
          anchorEl={anchorEl}
          placement={avatarPosition === 'right' ? 'bottom-end' : 'bottom-start'}
          open={Boolean(anchorEl)}
        >
          <MuiClickAwayListener onClickAway={onMenuClose}>
            <MuiPaper className={classes.paper} elevation={6}>
              {children}
            </MuiPaper>
          </MuiClickAwayListener>
        </MuiPopper>
        )}
    </>
  );
}
AccountAvatar.defaultProps = {
  accountDisplay: 'short',
  addressDisplay: 'cityState',
  avatarPosition: 'left',
  badgeColor: 'primary',
  size: 'normal',
  useHighlight: false,
  searchTerm: '',
  highlightColor: 'yellow',
} as const;
