import React from 'react';

export const CarDraw = (props: {style: React.CSSProperties}) => (
  <svg
    id="svg"
    preserveAspectRatio="none"
    viewBox="0, 0, 400,710.5882352941177"
    style={props.style}
  >
    <g id="svgg">
      <path
        id="path0"
        d="M 186 50 C 64 59 37.512 127.636 57.962 295.761 L 59.45 307.992 L 48.549 313.288 C 37.563 318.626 36.058 320.908 39.717 326.681 C 41.338 329.239 43.495 328.925 51.154 325.017 C 56.346 322.369 59 322 61 321 L 63 332 L 67 436 L 322 436 L 323.484 330.071 L 324.987 320.048 L 334.684 324.995 C 343.881 329.687 344.538 329.728 347.409 325.801 C 351.412 320.327 349.647 318.007 337.343 312.564 C 331.687 310.062 327.068 307.8 327.078 307.537 C 327.089 307.274 328.685 295.412 330.626 281.176 C 353.647 112.262 310 53 199 50 M 228.517 58.664 C 249.339 62.075 261.176 65.814 261.176 68.981 C 261.176 85.112 286.457 117.375 310 115 C 324 133 334 158 327 246 C 319.057 290.682 322 305 307 280 C 257 206 132 201 75.392 282.057 C 68.597 295.17 66.693 284.906 61 250 C 54 167 65 132 76 115 C 102.723 116.656 111.78 107.875 121.198 81.722 C 125.947 68.532 127.378 66.755 135.44 64.036 C 156.419 56.958 202.033 54.326 228.517 58.664 M 112 88 C 107 102 102 105 91 109 C 84 110 78 110 77 109 C 80 99.219 107.226 73.5 109.998 76.273 C 110.503 76.778 115 80 111 88 M 300.049 94.782 L 310 108 L 301 107 C 294.835 106.384 277 95 272 77 C 273.187 71.448 282.676 74.549 300.049 94.782 M 198 228 C 264 235 291.346 267.64 302.124 287.98 L 306.601 296.429 L 289 326 L 98 325 L 75 294 L 82 284 C 93 267 130 230 198 228 M 71 302 L 93 333 L 75.375 400.269 L 71 302 M 313 302 L 312 401 L 294 333 L 313 302 M 284 333 L 313 436 L 73 436 L 104 333 L 284 333"
        stroke="none"
      />
    </g>
  </svg>
);
