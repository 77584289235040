import React from 'react';
import DataGrid, {
  SearchPanel,
  Column,
  Scrolling,
  IDataGridOptions,
} from 'devextreme-react/data-grid';
import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import AccountAvatar, { type AccountAvatarProps } from 'components/AccountAvatar/AccountAvatar';
import LoadingBounce from 'components/LoadingBounce/LoadingBounce';
import { type PropsTransformer, withPropsTransformer } from '../../hocs/withPropsTransformer';

const useAccountSelectorStyles = makeStyles((theme) => ({
  root: {
    '& tr.dx-header-row': {
      display: 'none',
    },
  },
  accountRow: {
    cursor: 'pointer',
    zoom: 1.1,
    margin: theme.spacing(0.5),
  },
  loadingAccount: {
    justifyContent: 'center',
    '& div:nth-child(2)': {
      flex: 'none',
    },
  },
}));

const propsTransformer: PropsTransformer<
{
  data: {
    data: AccountAvatarProps,
  },
},
AccountAvatarProps
> = (props) => {
  const transformedProps = props.data.data;

  return transformedProps;
};

const AccountSelectorColumn = withPropsTransformer(propsTransformer)((props) => {
  const classes = useAccountSelectorStyles();
  return (
    <AccountAvatar
      {...props}
      className={clsx(props.className, classes.accountRow)}
    />
  );
});

type AccountDetails = Pick<AccountAvatarProps, 'accountNumbers' | 'accountType' | 'name'>;

const createAccountSelectorColumnWithClickHandler = (
  onClick: (accountDetails: AccountDetails) => void,
) => (props: Parameters<typeof AccountSelectorColumn>[0]) => (
  <AccountSelectorColumn
    {...{
      ...props,
      data: {
        data: {
          ...props.data.data,
          onClick: () => onClick(props.data.data),
        } as unknown as AccountAvatarProps,
      },
    }}
  />
);

export interface AccountSelectorProps {
  dataSource: IDataGridOptions<AccountAvatarProps>['dataSource']
  onSelectAccount: (accountDetails: Pick<AccountAvatarProps, 'accountNumbers' | 'accountType' | 'name'>) => void;
  loadingAccount?: Pick<AccountAvatarProps, 'accountNumbers' | 'accountType' | 'name'> | false;
  className?: string;
  style?: React.CSSProperties;
  loadingDisplay?: JSX.Element;
}

export const AccountSelector = (props: AccountSelectorProps) => {
  const {
    dataSource, onSelectAccount, loadingAccount, className, style, loadingDisplay,
  } = props;
  const classes = useAccountSelectorStyles();
  const { t } = useTranslation();
  const AccountSelectorColumnWithClickHandler = React.useMemo(() => createAccountSelectorColumnWithClickHandler(onSelectAccount), [onSelectAccount]);

  if (loadingAccount) {
    if (loadingDisplay) return loadingDisplay;

    return (
      <>
        <div
          className={classes.loadingAccount}
        >
          <AccountAvatar
            {...loadingAccount}
            style={{
              justifyContent: 'center',
            }}
          />
        </div>
        <LoadingBounce />
      </>
    );
  }

  return (
    <Paper className={clsx(classes.root, className)} style={style}>
      <DataGrid<AccountAvatarProps>
        dataSource={dataSource}
        rowAlternationEnabled
      >
        <Scrolling mode="virtual" />
        <SearchPanel visible highlightSearchText={false} placeholder={t('Search...')} />
        <Column
          cellComponent={AccountSelectorColumnWithClickHandler}
        />
      </DataGrid>
    </Paper>
  );
};
